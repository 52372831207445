<template>
    <BaseModal :open="isModalOpen" @close="isModalOpen = !isModalOpen">
        <CreateRoleModal @close="isModalOpen = !isModalOpen" :is_edit="isEdit"></CreateRoleModal>
    </BaseModal>
    <div class=" m-3 py-2">
        <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-row w-75">

                <div class="mx-2">
                    <select v-model="selected_sort_by_options" class="form-control" id="familyFilter" @change="getData">
                        <option v-for="option in sort_by_options" :value="option.value" :key="option.value">{{
                            t(option.label)
                        }}
                        </option>
                    </select>
                </div>

                <div class="mx-2">
                    <select v-model="selected_sort_column" class="form-control" id="familyFilter" @change="getData">
                        <option v-for="option in sort_column_options" :value="option.value" :key="option.value">{{
                            t(option.label) }}
                        </option>
                    </select>
                </div>
            </div>

            <div class=" mx-1 w-25">
                <button class="edit-buttons btn btn-primary w-100" @click="openModal(false)">{{
                    t('create_role')
                }}
                </button>
            </div>

            <br />



        </div>
        <br />
        <div class="row">
        </div>
        <div class="table-responsive">
            <br />
            <h2 v-if="roles != undefined && roles.data.length == 0">Not Found</h2>
            <table v-if="roles != undefined && roles.data.length > 0" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th data-field="id" data-sortable="true">Id</th>
                        <th data-field="name" data-sortable="true">{{ t('role') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{ t('permissions') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{
                            t('orphan_table_column_registration_date') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_update_date') }}
                        </th>
                        <th width="8%" data-field="registration_date" data-sortable="true">{{
                            t('orphan_table_column_actions') }}</th>
                    </tr>
                </thead>
                <tbody id="myTable" v-if="roles != undefined">
                    <tr v-for="role in roles.data" :key="role.id">
                        <td>{{ role.id }}</td>
                        <td>{{ role.name }}</td>
                        <td>
                            <button class="btn btn-outline-dark  m-1 p-1 btn-sm"
                                v-for="(permission, index) in role.permissions" :key="index">{{ permission.name
                                }}</button>
                            <!-- <button @click="openModal(event, true, {

                                id: user.id
                            })" class="btn edit-button btn-sm btn-outline-primary"><i class="fa fa-pencil"></i>
                            </button> -->
                            <!-- <button class="btn btn-sm btn-outline-danger" @click="deleteUser(user)"><i
                                    class="fa fa-trash"></i>
                            </button> -->
                        </td>
                        <td>{{ generateDate(role.created_at) }}</td>
                        <td>{{ generateDate(role.updated_at) }}</td>
                        <td>
                            <button @click="openModal(role.id)" class="btn edit-button btn-sm btn-outline-primary m-1"><i
                                    class="fa fa-pencil"></i>
                            </button>
                            <button class="btn btn-sm btn-outline-danger" @click="deleteRole(role)"><i
                                    class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-sm-1">
                <select v-model="selected_per_page" id="pageSize" class="form-control d-inline-block w-auto"
                    @change="perPageChange">
                    <option v-for="option in per_page_options" :value="option.value" :key="option.value">{{ (option.label)
                    }}
                    </option>
                </select>
            </div>
            <div class="col-sm">
                <div class="float-right">
                    <button class="btn btn-outline-secondary" :disabled="page.value == 1" @click="changePage(false)">
                        <i class="fas fa-angle-left"></i> {{ t('orphan_prev_button') }}
                    </button>
                    <button class="btn btn-outline-secondary" :disabled="page.value + 1 > page.count"
                        @click="changePage(true)">
                        {{ t('orphan_next_button') }} <i class="fas fa-angle-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="float-right mr-3">
            <span>{{ t('orphan_table_footer_page') }} {{ page.value }} </span>
            <span>{{ t('orphan_table_footer_total') }} {{ page.count }}</span>
        </div>
    </div>
</template>
    
<script>

import { LoadingStore } from '@/stores/loading';
import { RoleStore } from "@/stores/roles";
import { OrphanEditStates } from "@/stores/orphanEditStates";
import { storeToRefs } from 'pinia';
import { NotificationStore } from '@/stores/notification';
import { useI18n } from 'vue-i18n';
import BaseModal from '../BaseModal.vue';
import CreateRoleModal from './Modal/CreateRoleModal.vue';

export default {
    name: 'RolesCard',

    components: {
        BaseModal, CreateRoleModal

    },


    setup() {
        const loadingStore = LoadingStore();
        const roleStore = RoleStore();
        const orphanEditStates = OrphanEditStates();
        const { roles, page } = storeToRefs(roleStore);
        const { states } = storeToRefs(orphanEditStates);
        const notificationStore = NotificationStore();
        const { is_loading } = storeToRefs(loadingStore);
        const { t } = useI18n({});
        return {
            loadingStore,
            roleStore,
            roles,
            page,
            orphanEditStates,
            states,
            notificationStore,
            is_loading,
            t
        }

    },

    watch: {

        roles: {
            handler() {
                this.page.value = this.roles.page_number;
                this.page.count = this.roles.number_of_page;
            },
            deep: true
        },
        page: {
            handler() {

                console.log(this.page)
            },
            deep: true
        },
        isModalOpen: {
            handler() {
                if (!this.isModalOpen) {
                    this.getData()
                    this.isEdit = undefined
                }
            }
        }

    },
    data() {
        return {
            sort_column_options: [
                { label: 'orphan_filter_created_at', value: 'created_at' },
                { label: 'orphan_filter_updated_at', value: 'updated_at' },
            ],
            selected_sort_column: 'created_at',
            sort_by_options: [
                { label: 'orphan_filter_order_by_desc', value: '3' },
                { label: 'orphan_filter_order_by_asc', value: '2' },
            ],
            per_page_options: [
                { label: '5', value: '5' },
                { label: '10', value: '10' },
                { label: '15', value: '15' },
            ],
            selected_per_page: 5,
            selected_sort_by_options: '3',
            isModalOpen: false,
            isEdit: undefined,
            timeout: undefined,
            search: '',

        };
    },

    async created() {
        let params = this.parseURLParamaters();
        this.page.value = params.page;
        this.selected_per_page = params.per_page;
        this.selected_sort_column = params.sort_column;
        this.selected_sort_by_options = params.sort_by;
        await this.getData();
    },

    methods: {
        parseURLParamaters() {
            const url_params = new URLSearchParams(window.location.search);
            let params = {
                page: url_params.get('page') ? url_params.get('page') : 1,
                per_page: url_params.get('per_page') ? url_params.get('per_page') : 5,
                sort_column: url_params.get('sort_column') ? url_params.get('sort_column') : 'created_at',
                sort_by: url_params.get('sort_by') ? url_params.get('sort_by') : '3',
            }
            return params;
        },

        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.SearchUser, 100);
        },


        changePage(next) {
            this.page.value = next ? this.page.value + 1 : this.page.value - 1;

            this.getData();
        },
        fetch(status = false, is_update = false) {
            if (status) {
                let status = false;
                if (this.selected_sort_column == 'updated_at' && !is_update)
                    status = true;
                this.getData(undefined, status);
            }
        },
        deleteRole(role) {
            this.notificationStore.popupDialog(this.t('orphan_modal_delete_button'), this.t('orphan_modal_cancel_button'), this.t('role_delete_title'), this.t('role_delete_text', { name: role.name, id: role.id }), true, role, 'center', this.deleteCall, undefined);
        },

        async deleteCall(role) {
            this.loadingStore.setLoading(true);
            //TODO: DELETE FOR USERS
            let response = await this.roleStore.deleteRole(role.id);
            if (response.status) {
                if (this.roles.number_of_data % this.roles.per_page == 1 && this.page.value > 1)
                    this.page.value -= 1;
                await this.getData();
                let delete_text = role.name + '(' + role.id + ') ' + this.t('orphan_delete_success');
                this.notificationStore.popupMessage(delete_text, "", true, "bottom", true);
            }
            else {

                let delete_text = role.name + '(' + role.id + ') ' + this.t('user_delete_error');
                this.notificationStore.popupMessage(delete_text, "", false, "center", false);
            }
            this.loadingStore.setLoading(false);
        },
        perPageChange() {
            this.page.value = 1;
            this.getData();
        },

        generateDate(date, include_date = false) {
            let d = new Date(date);
            const options = { weekday: 'short', month: 'short', day: 'numeric' };
            if (include_date) {
                delete options.weekday;
                options.year = 'numeric';
            }
            const formattedDate = d.toLocaleDateString('en-US', options);
            return formattedDate;
        },
        async getData() {
            this.loadingStore.setLoading(true);
            await this.roleStore.getRoles(this.page.value, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
            this.loadingStore.setLoading(false);

        },


        openModal(id) {
            if (id) {
                this.isEdit = id
            }
            this.isModalOpen = true
        },
        closeModal() {
            this.isModalOpen = false;
            this.isEdit = undefined;
        },




    }

}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 767px) {}

/* .edit-button {
    margin-right: 2%;
} */

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>
    