import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const EnrolledSponsorshipsStore = defineStore(
  "enrolledSponsorshipsStore",
  {
    state: () => ({
      states: {
        id: "",
        name: "",
        is_active: -1,
        is_family: -1,
        orphan_id: "",
        donator_id: "",
        sponsorship_id: "",
        checked: false,
      },
      orphans: undefined,
      donators: undefined,
      field_states: {
        is_exist: false,
        enable_force: false,
      },
      page: {
        value: 0,
        count: 0,
      },
      original_states: {},
      sponsorships: undefined,
      enrolled_sponsorships: undefined,
    }),

    getters: {},

    actions: {
      cleanStates() {
        this.states = {
          id: "",
          name: "",
          is_active: -1,
          is_family: -1,
          orphan_id: "",
          donator_id: "",
          sponsorship_id: "",
          checked: false,
        };
        this.field_states = {
          is_exist: false,
          enable_force: false,
        };
        this.original_states = {};
        this.families = undefined;
        this.orphans = undefined;
      },

      async userIsValid() {
        if (localStorage.getItem("ngo_access_token") == null) return false;
        return true;
      },

      async update(id, data) {
        const url = "api/enrolled/sponsorship/" + id;
        return axios
          .put(url, data)
          .then((response) => {
            return { status: true, data: response };
          })
          .catch((err) => {
            return { status: false, data: err.response };
          });
      },

      async add(data) {
        const url = "api/enrolled/sponsorship";
        if (data.selected_type == "-1") data.selected_type = null;
        return axios
          .post(url, data)
          .then((response) => {
            return { status: true, data: response };
          })
          .catch((err) => {
            return { status: false, data: err.response };
          });
      },

      async destroy(id) {
        let access = await this.checkAccess();
        if (!access.status == -1) {
          return access;
        }
        const url = "api/enrolled/sponsorship/" + id;
        return axios
          .delete(url)
          .then((response) => {
            return { status: true, data: response };
          })
          .catch((err) => {
            return { status: false, data: err.response };
          });
      },

      async donate(data) {
        let access = await this.checkAccess();
        if (!access.status == -1) {
          return access;
        }
        const url = "api/enrolled/sponsorship/donations";
        return axios
          .post(url, data)
          .then((response) => {
            return { status: true, data: response };
          })
          .catch((err) => {
            return { status: false, data: err.response };
          });
      },

      async get(page_number, per_page, sort_column, sort_by) {
        let access = await this.checkAccess();
        if (!access.status == -1) {
          return access;
        }

        let url = "api/enrolled/sponsorship?";

        if (this.states.donator_id != undefined)
          url += "&donator_id=" + this.states.donator_id;
        if (this.states.orphan_id != undefined)
          url += "&orphan_id=" + this.states.orphan_id;
        if (this.states.sponsorship_id != undefined)
          url += "&sponsorship_id=" + this.states.sponsorship_id;
        if (this.states.is_family != -1)
          url += "&is_family=" + this.states.is_family;
        if (this.states.is_active != -1)
          url += "&is_active=" + this.states.is_active;

        return axios
          .get(url, {
            params: {
              page: page_number,
              per_page: per_page,
              sort_by: sort_by,
              sort_column: sort_column,
            },
          })
          .then((response) => {
            this.enrolled_sponsorships = response.data.data;
            this.page.count = response.data.number_of_page;
            this.page.value =
              this.page.count > 0 ? response.data.page_number : 0;
            this.page.number_of_data = response.data.number_of_data;
            this.page.per_page = response.data.per_page;
            return { status: true };
          })
          .catch((exception) => {
            checkResponse(exception.response.status);
            return { status: false, message: "Not Found!" };
          });
      },

      //For create sponsorships
      async getFamilies() {
        let access = await this.checkAccess();
        if (!access.status == -1) {
          return access;
        }
        return axios
          .get("api/family")
          .then((response) => {
            this.orphans = response.data.data;
            return { status: true };
          })
          .catch((exception) => {
            checkResponse(exception.response.status);
            return { status: false, message: "Not Found!" };
          });
      },

      //For create sponsorships to orphans
      async getOrphans() {
        let access = await this.checkAccess();
        if (!access.status == -1) {
          return access;
        }
        return axios
          .get("api/orphans", {})
          .then((response) => {
            this.orphans = response.data.data;
            return { status: true };
          })
          .catch((exception) => {
            checkResponse(exception.response.status);
            return { status: false, message: "Not Found!" };
          });
      },

      async getDonators() {
        let access = await this.checkAccess();
        if (!access.status == -1) {
          return access;
        }

        const url = "api/donators";
        return axios
          .get(url, {})
          .then((response) => {
            let data = response.data.data;
            this.donators = data;
            return { status: true, data: this.states };
          })
          .catch((err) => {
            return { status: false, data: err.response };
          });
      },

      async getSponsorships() {
        let access = await this.checkAccess();
        if (!access.status == -1) {
          return access;
        }
        const url = "api/sponsorships";
        return axios
          .get(url, {})
          .then((response) => {
            let data = response.data.data;
            this.sponsorships = data;
            return { status: true, data: this.states };
          })
          .catch((err) => {
            return { status: false, data: err.response };
          });
      },

      async getEnrollment(id) {
        let access = await this.checkAccess();
        if (!access.status == -1) {
          return access;
        }

        const url = "api/enrolled/sponsorship/" + id;
        return axios
          .get(url)
          .then((response) => {
            let data = response.data;
            this.states = {
              id: data.id,
              checked: data.checked,
              is_active: data.is_active,
              is_family: data.orphan.is_family,
              orphan_id: data.orphan.id,
              donator_id: data.donator.id,
              sponsorship_id: data.sponsorships.id,
            };
            this.original_states = Object.assign({}, this.states);

            return { status: true, data: this.states };
          })
          .catch((err) => {
            return { status: false, data: err.response };
          });
      },

      async isExist(data) {
        let access = await this.checkAccess();
        if (!access.status == -1) {
          return access;
        }
        const url = "api/enrolled/sponsorship/exist";
        return axios
          .get(url, {
            params: data,
          })
          .then((response) => {
            this.field_states = {
              is_exist: true,
            };
            return { status: true, data: response };
          })
          .catch((err) => {
            this.field_states = {
              is_exist: false,
            };
            return { status: false, data: err };
          });
      },

      async checkAccess() {
        if (axios.defaults.headers.common["Authorization"] == null) {
          if (localStorage.getItem("ngo_access_token") == null) {
            window.location.replace("/login");
            return { status: -1, message: "Must login to continue" };
          }
        }
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("ngo_access_token");
        return { status: true, message: "True" };
      },
    },
  }
);
