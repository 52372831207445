export default {
  "navbar_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
  "navbar_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails"])},
  "navbar_donation_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des dons"])},
  "navbar_orphans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphelins"])},
  "navbar_orphans_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphelin"])},
  "navbar_orphans_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille d'orphelins"])},
  "navbar_orphans_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dons des orphelins"])},
  "navbar_sponsorships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parrainages"])},
  "navbar_sponsorships_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parrainage"])},
  "navbar_sponsorships_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "navbar_campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagnes"])},
  "navbar_campaigns_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne"])},
  "navbar_campaigns_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "navbar_campaigns_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dons"])},
  "navbar_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donateurs"])},
  "navbar_donators_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
  "navbar_donators_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privé"])},
  "navgar_donators_donates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu fiscal"])}
}