import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const DonatorStore = defineStore("donatorStore", {
  state: () => ({
    states: {
      personal: {
        name: "",
        last_name: "",
        email: "",
        email2: "",
        phone2: "",
        phone: "",
        civility: "0",
        type: "0",
        checked: false,
      },
      address: {
        country: "CH",
        county: "",
        address: "",
        zipcode: "",
      },
    },
    original_states: {},
    type_options: [
      { label: "donator_regular", value: 0 },
      { label: "donator_vip", value: 1 },
    ],
    civility_options: [
      { label: "orphan_filter_civility_male", value: 0 },
      { label: "orphan_filter_civility_female", value: 1 },
      { label: "orphan_filter_civility_undefined", value: -2 },
    ],
    field_states: {
      is_exist: false,
    },

    donators: undefined,
    page: {
      value: 0,
      count: 0,
    },
  }),

  getters: {},

  actions: {
    cleanStates() {
      this.original_states = {};
      this.states = {
        personal: {
          name: "",
          last_name: "",
          email: "",
          email2: "",
          phone: "",
          phone2: "",
          civility: "0",
          type: "0",
          checked: false,
        },
        address: {
          country: "CH",
          county: "",
          address: "",
          zipcode: "",
        },
      };
      this.field_states = {
        is_exist: false,
      };
    },
    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async update(data, is_public = false) {
      const base = is_public ? "api/public/donators/" : "api/donators/";
      const url = base + data.personal.id;
      return axios
        .put(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async add(data, is_public = false) {
      const url = is_public ? "api/public/donators" : "api/donators";
      return axios
        .post(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async upload(file, is_public = false) {
      const url = is_public ? "api/public/donators/csv" : "api/donators/csv";
      const formData = new FormData();
      formData.append("csv_file", file);
      return axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          return { status: true, data: null };
        })
        .catch((err) => {
          return { status: false, data: err };
        });
    },

    async deleteDonators(id, is_public) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      let url = is_public ? "api/public/donators/" : "api/donators/";
      url += id;
      return axios
        .delete(url)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async search(
      key,
      page_number,
      per_page,
      sort_column,
      sort_by,
      is_public = false
    ) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = is_public
        ? "api/public/donators/search"
        : "api/donators/search";

      return axios
        .get(url, {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            key: key,
          },
        })
        .then((response) => {
          this.donators = response.data.data;
          this.page.count = response.data.number_of_page;
          this.page.value = this.page.count > 0 ? response.data.page_number : 0;
          this.page.number_of_data = response.data.number_of_data;
          this.page.per_page = response.data.per_page;
          return { status: true, data: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          this.donators = [];
          this.page.count = 0;
          this.page.value = 0;
          return { status: false, message: "Not Found!" };
        });
    },

    async exist(key, is_public = false) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = is_public
        ? "api/public/donators/search"
        : "api/donators/search";

      return axios
        .get(url, {
          params: {
            key: key,
          },
        })
        .then((response) => {
          return { status: true, data: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async get(
      page_number,
      per_page,
      civility,
      sort_column,
      sort_by,
      donator_type = undefined,
      is_public = false
    ) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = is_public ? "api/public/donators" : "api/donators";
      return axios
        .get(url, {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            civility: civility == "null" ? null : civility,
            type: donator_type == "null" ? null : donator_type,
          },
        })
        .then((response) => {
          this.donators = response.data.data;
          this.page.count = response.data.number_of_page;
          this.page.value = this.page.count > 0 ? response.data.page_number : 0;
          this.page.number_of_data = response.data.number_of_data;
          this.page.per_page = response.data.per_page;
          return { status: true, value: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          this.donators = [];
          this.page.count = 0;
          this.page.value = 0;
          return { status: false, message: "Not Found!" };
        });
    },

    async getDonator(id, is_public = false) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      let url = is_public ? "api/public/donators/" : "api/donators/";
      url += id;
      return axios.get(url).then((response) => {
        let data = response.data;
        this.states = {
          address: data.address,
          personal: {
            checked: data.checked,
            civility: data.civility,
            email: data.email,
            email2: data.email2,
            id: data.id,
            last_name: data.last_name,
            name: data.name,
            phone: data.phone,
            phone2: data.phone2,
            type: data.type,
          },
        };

        if (
          this.states.address == undefined ||
          this.states.address.length == 0
        ) {
          this.states.address = {
            country: "",
            county: "",
            address: "",
            zipcode: "",
          };
        }
        if (this.states.address.country?.length > 0)
          this.states.address.country =
            this.states.address.country.toUpperCase();

        this.original_states.address = Object.assign({}, this.states.address);
        this.original_states.personal = Object.assign({}, this.states.personal);

        return { status: true, data: this.states };
      });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
