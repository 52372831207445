<template>
    <div class="form-group  my-2 autocomplete">
        <input v-model="searchText" @input="search" class="form-control" :placeholder="placeholder" @focus="search" />
        <ul v-if="showResults" class="autocomplete-results">
            <li v-if="!orphans_shown || orphans_shown.length < 1" class="autocomplete-result">
                {{ t('result_not_found') }}
            </li>
            <li v-for="item in orphans_shown" :key="item.id" @click="selectItem(item)" class="autocomplete-result">
                {{ `${item.name} ${item.last_name ? item.last_name : ""} - ${item.id}` }}
            </li>
        </ul>
    </div>
</template>
  
<script>
import _ from "lodash";
import { storeToRefs } from "pinia";
import { EnrolledSponsorshipsStore } from "@/stores/enrolledSponsorshipsStore";
import { useI18n } from "vue-i18n";
export default {
    props: ['is_family', 'selected_orphan'],
    data() {
        return {
            placeholder: "",
            orphans_shown: undefined,
            searchText: this.selected_orphan ? this.handleName(this.selected_orphan?.name, this.selected_orphan.last_name) : "",
            // Results to show in the dropdown
            showResults: false, // Flag to show/hide dropdown
        };
    },
    async created() {
        if (this.is_family) {
            await this.enrolledSponsorshipsStore.getFamilies()
        } else {
            await this.enrolledSponsorshipsStore.getOrphans()
        }
        this.orphans_shown = this.orphans
    },
    watch: {
        placeholder: {
            deep: true
        },
        is_family: {
            deep: true,
            async handler() {
                if (!this.selected_orphan) {
                    this.states.orphan_id = "";
                    this.searchText = "";
                }
                if (this.is_family) {
                    this.placeholder = 'Search Family'
                    await this.enrolledSponsorshipsStore.getFamilies()
                } else {
                    this.placeholder = 'Search Orphan'
                    await this.enrolledSponsorshipsStore.getOrphans()
                }
                this.orphans_shown = this.orphans
            }
        }
    },
    setup() {
        const enrolledSponsorshipsStore = EnrolledSponsorshipsStore()
        const { orphans, states } = storeToRefs(enrolledSponsorshipsStore)
        const { t } = useI18n({});
        return {

            t,
            states,
            enrolledSponsorshipsStore,
            orphans
        };
    },

    methods: {
        // Throttle the search method to avoid sending too many requests
        search: _.debounce(function () {
            if (this.searchText) {
                this.searchOrphans();
            } else {
                this.orphans_shown = this.orphans
                this.showResults = true

            }
        }, 1000), // Throttle for 3 seconds

        // Fetch search results from an API (adjust the API endpoint)
        async searchOrphans() {
            let lower_case_search_text = this.searchText.toLowerCase().split(" ")[0]
            let filtered_orphans = this.orphans.filter((orphan) => orphan.name && orphan.name.toLowerCase().includes(lower_case_search_text) || orphan.last_name && orphan.last_name.toLowerCase().includes(lower_case_search_text))
            this.orphans_shown = filtered_orphans
            this.showResults = true
        },

        async getOrphans() {
            this.orphans_shown = this.orphans
            this.showResults = true
        },
        handleName(name, last_name) {
            return (name || '') + (last_name ? ' ' + last_name : '');
        },
        // Handle item selection and update the selected_item prop
        selectItem(item) {
            this.states.orphan_id = item.id
            this.searchText = item.name + " " + (item.last_name ? item.last_name : ""); // Set the input text to the selected item's name
            this.showResults = false; // Hide the dropdown
        },
    },
};
</script>

<style>
.autocomplete {
    position: relative;
}

.autocomplete-results {
    padding: 5px;
    background-color: #f5f3f3;
    margin: 0;
    margin-top: 3px;
    border: 1px solid #eeeeee;
    height: auto;
    border-radius: 0 0 10px 10px;
    min-height: 1em;
    max-height: 20em;
    overflow: auto;
}

.autocomplete-result {
    list-style: none;
    /* border-bottom: solid 1px #96BFFE; */
    text-align: left;
    border-radius: 5px;
    padding: 3px 5px;
    cursor: pointer;
}

.autocomplete-result:hover {
    background-color: #5193f6;
    color: white;
}
</style>
  