import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const CurrencyStore = defineStore("currencyStore", {
  state: () => ({
    currencies: undefined,
  }),

  getters: {},

  actions: {
    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async getCurrencies() {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/currency")
        .then((response) => {
          this.currencies = response.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
