<template>
    <div>
        <div class="modal-header">
            <h5 class="modal-title">{{ t('orphan_modal_update_button') }}</h5>
        </div>
        <div class="modal-body" style="margin-top: 1%;">
            <form>
                <div class="form-row" v-if="states != undefined">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('orphan_modal_ngo_id') }}*</label>
                        <input @keyup="searching" @paste.once="pastedetected" v-if="states != undefined"
                            v-model="states.ngo_id" type="email" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('orphan_modal_ngo_id_place_holder')" />
                        <h6 style="color: red" v-if="is_exist" id="emailHelp" class="form-text text-muted">
                            {{ t('orphan_ngo_id_exist') }}
                        </h6>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail2">{{ t('orphan_modal_civility') }}</label>
                        <select v-model="states.civility" class="form-control" id="familyFilter">
                            <option v-for="option in civility_options" :value="option.value" :key="option.value">{{
                                t(option.label) }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-row">

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail3">{{ t('orphan_modal_name') }}*</label>
                        <input v-model="states.name" class="form-control" id="exampleInputEmail3"
                            aria-describedby="emailHelp" :placeholder="t('orphan_modal_name_place_holder')" />
                        <h6 style="color: red" v-if="states.name.length == 0" id="emailHelp" class="form-text text-muted">
                            {{ t('orphan_name_field_can_not_empty') }}
                        </h6>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail4">{{ t('orphan_modal_lastname') }}*</label>
                        <input v-model="states.last_name" type="email" class="form-control" id="exampleInputEmail4"
                            aria-describedby="emailHelp" :placeholder="t('orphan_modal_lastname_place_holder')" />
                        <h6 style="color: red" v-if="states.last_name.length === 0" id="emailHelp"
                            class="form-text text-muted">
                            {{ t('orphan_last_name_field_can_not_empty') }}
                        </h6>
                    </div>
                </div>
                <div class="form-row">

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail5">{{ t('orphan_modal_country') }}</label>
                        <select v-model="states.location" class="form-control" id="familyFilter">
                            <option value="">{{ t('select_country') }}</option>
                            <option v-for="country in countries" :value="country.alpha2Code" :key="country.alpha2Code">{{
                                languageStore.getLanguage == 'en' ? country.name : country.translations.fr }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="birthday">{{ t('orphan_modal_dob') }}</label>
                        <input v-model="states.dob" type="date" class="form-control" id="exampleInputEmail6"
                            aria-describedby="emailHelp" />
                    </div>

                </div>
                <div class="form-row">
                    <!-- <div class="from-group col-md-6">
                        <label for="exampleInputEmail6">{{ t('orphan_modal_phone') }}</label>
                        <input v-model="states.phone" type="phone" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('orphan_modal_phone_place_holder')" />
                    </div> -->
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail7">{{ t('orphan_modal_family') }}</label>
                        <select v-model="states.family_id" class="form-control" id="familyFilter"
                            v-if="families != undefined && families.data.length > 0">
                            <option :value="null">{{ t('orphan_filter_civility_undefined') }}</option>
                            <option v-for="option in families.data" :value="option.id" :key="'family' + option.id"> {{
                                option.name }} {{ '('
        + option.id + ')' }}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail7">{{ t('orphan_modal_treatmnet') }}</label>

                        <select v-model="states.treatment_type" class="form-control" id="familyFilter">
                            <option v-for="option in treatment_options" :value="option.value" :key="option.value">{{
                                t(option.label) }}
                            </option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        <div
            :class="canCheck ? 'w-100 d-flex flex-row justify-content-between' : 'w-100 d-flex flex-row justify-content-end'">
            <div class="form-check form-switch d-flex align-items-center" v-if="canCheck">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" :checked="states.checked"
                    @click="states.checked = !states.checked">
                <label class="p-0 flex-grow-1 m-0" for="flexSwitchCheckChecked">{{ t('checked') }}</label>
            </div>

            <div class="modal-footer">

                <button :disabled="JSON.stringify(edit_states) === JSON.stringify(states) || is_exist" @click="updateOrphan"
                    type="button" style="color: white" class="btn btn-primary add-button">
                    {{ t('orphan_modal_update_button') }}
                </button>

                <button type="button" style="color: white" class="btn btn-danger" @click="close">
                    {{ t('orphan_modal_cancel_button') }}

                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { NotificationStore } from '@/stores/notification';
import { OrphanStore } from "@/stores/orphan";
import { LanguageStore } from "@/stores/language";
import { CountriesStore } from '@/stores/countries';
import { OrphanEditStates } from "@/stores/orphanEditStates";
import { UserStore } from '@/stores/userStore';

export default {
    name: 'EditOrphan',
    props: ['isDisabled', 'first_disable', 'name_disable', 'last_name_disable', 'ngo_id_disable'],
    data() {
        return {
            data: {
                can_update: true,
                civility_options: [
                ],
                canCheck: false,
                treatment_options: [],
                treatment_type: 1,
                selected_country: null,
                selected_civility: null,
                selected_family: null,
                enable_add: false,
                country: '',
                timeout: undefined,
                name: '',
                last_name: '',
                dob: undefined,
                phone: undefined,
                family: undefined,
            }
        };
    },

    watch: {
        ngo_id: {
            deep: true,
        },
        isDisabled: {
            deep: true,
        },
        states: {
            handler(newv, oldv) {
                if (newv.ngo_id != oldv.ngo_id) {
                    this.can_update = false;
                }
            },
            deep: true
        },
        is_exist: {
            deep: true,
        }
    },
    async created() {
        this.edit_states = Object.assign({}, this.states);
        this.canCheck = this.userStore.checkIfHasPermission('check.entries.update')
        this.is_exist = false;
        this.name = null;
        this.last_name = null;
        this.phone = null;
        this.dob = null;
        this.treatment_type = 1;
        this.treatment_options = [
            { label: 'Undefined', value: null },
            { label: "no_treatment", value: 1 },
            { label: 'Medical', value: 2 },
            { label: "Finished", value: 3 },
        ];
        this.selected_family = null;
        this.civility_options = [
            { label: 'orphan_filter_civility_male', value: '0' },
            { label: 'orphan_filter_civility_female', value: '1' },
            { label: 'orphan_filter_civility_undefined', value: '-2' }];
        this.selected_civility = -2;
        this.loadingStore.setLoading(true);
        await this.orphanStore.getFamilies();
        this.loadingStore.setLoading(false);
        this.enable_add = false;
    },
    methods: {
        close() {
            this.$emit('close');
        },
        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.check_is_exist, 150);
        },

        async check_is_exist() {
            if (this.edit_states.ngo_id != this.states.ngo_id) {
                let is_exist = await this.orphanStore.searchOrphan(this.states.ngo_id);
                this.is_exist = is_exist.status;
            }

        },
        async updateOrphan() {
            this.loadingStore.setLoading(true);
            let response = await this.orphanStore.updateOrphan(this.states);
            if (response.status) {
                if (this.canCheck) {
                    await this.orphanEditStates.checkEntry('orphans', this.states.id, this.states.checked)

                }
                this.close();
                this.$emit('status', true);
            }
            // Call api
            this.loadingStore.setLoading(false);
            let error_message = undefined;
            if (response.data?.data?.error) {
                error_message = response.data.data.error
            }
            let message = response.status ? this.t('orphan_updated_alert') : `${this.t('orphan_not_updated_alert')}. ${error_message}`;
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        },

        pastedetected(eventdata) {
            this.ngo_id = eventdata.clipboardData.getData("Text");
            this.searching();
        },

        async check_button() {
            this.$emit('check', this.ngo_id, this.name, this.last_name);
        },


    },
    setup() {
        const orphanStore = OrphanStore();
        const languageStore = LanguageStore();
        const countriesStore = CountriesStore();
        const orphanEditStates = OrphanEditStates();
        const notificationStore = NotificationStore();
        const userStore = UserStore();
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const { families } = storeToRefs(orphanStore);
        const { states, edit_states, is_exist } = storeToRefs(orphanEditStates);
        const { countries } = storeToRefs(countriesStore);
        const { t } = useI18n({});
        return { t, is_loading, userStore, orphanEditStates, edit_states, is_exist, languageStore, countriesStore, countries, states, loadingStore, notificationStore, orphanStore, families };
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

label {
    float: left;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>
  