export default {
  "email_topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet du courriel"])},
  "email_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corps de l'e-mail"])},
  "select_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner les destinataires"])},
  "selected_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataires sélectionnés"])},
  "no_recipients_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun destinataire sélectionné"])},
  "email_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la langue de l'e-mail"])},
  "email_sent_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email envoyé avec succès"])},
  "email_not_sent_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'envoi de l'email a échoué !"])},
  "all_regular_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les donateurs réguliers"])},
  "all_vip_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les donateurs VIP"])}
}