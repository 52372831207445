import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const PermissionStore = defineStore("permissionStore", {
  state: () => ({
    page: {
      value: 0,
      count: 0,
    },
    permissions: undefined,
    permissionDescriptions: [
      { name: "index", description: "index_description" },
      { name: "search", description: "search_description" },
      { name: "show", description: "show_description" },
      { name: "create", description: "create_description" },
      { name: "destroy", description: "destroy_description" },
      { name: "update", description: "update_description" },
      { name: "upload", description: "upload_description" },
    ],
  }),

  getters: {},

  actions: {
    cleanStates() {
      this.permissions = undefined;
      this.page = {
        value: 0,
        count: 0,
      };
    },

    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async get(page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }

      let url = "api/permissions?";

      return axios
        .get(url, {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
          },
        })
        .then((response) => {
          let filter = [
            "auth.login",
            "auth.logout",
            "auth.changePassword",
            "user.info",
            "user.update",
            "auth.signup",
          ];

          this.permissions = response.data.data.filter(
            (obj) => !filter.includes(obj.name)
          );
          this.page.count = response.data.number_of_page;
          this.page.value = this.page.count > 0 ? response.data.page_number : 0;
          return { status: true };
        })
        .catch((exception) => {
          console.log(exception);
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async checkResponse(response_code) {
      if (response_code == 401) {
        if (localStorage.getItem("ngo_access_token") !== null) {
          localStorage.removeItem("ngo_access_token");
          window.location.replace("/login");
        }
      }
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
