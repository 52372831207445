<template>
    <BaseModal :open="isOpen" @close="isOpen = !isOpen">
        <CreateCampaignType v-if="isOpen" @status="fetch" @close="close"></CreateCampaignType>
    </BaseModal>

    <BaseModal :open="editIsOpen" @close="editIsOpen = !editIsOpen">
        <EditCampaignType v-if="editIsOpen" @status="fetch" @close="close"></EditCampaignType>
    </BaseModal>

    <div class="m-3 py-2">
        <div class="row justify-content-between">
            <div class="col-md-6">
                <input @keyup="searching" @paste.once="pastedetected" v-model="search" class="form-control" id="myInput"
                    type="text" placeholder="Search..">
            </div>
            <div class="col col-md-2 d-flex flex-row justify-content-between">
                <div class="w-100 mx-1">
                    <button class="edit-buttons btn btn-primary w-100" @click="openModal">{{ t('orphan_table_operation_add')
                    }}
                    </button>
                </div>
                <div class="w-100 mx-1">
                    <button id="refreshTable" class="edit-buttons btn btn-primary w-100" @click="getData(event, true)">{{
                        t('orphan_table_operation_refresh') }}</button>
                </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col col-md-2">
                <select v-model="selected_sort_by_options" class="form-control" id="familyFilter" @change="getData">
                    <option v-for="option in sort_by_options" :value="option.value" :key="option.value">{{ t(option.label)
                    }}
                    </option>
                </select>

            </div>
            <div class="col col-md-2">
                <select v-model="selected_sort_column" class="form-control" id="familyFilter" @change="getData">
                    <option v-for="option in sort_column_options" :value="option.value" :key="option.value">{{
                        t(option.label) }}
                    </option>
                </select>
            </div>

        </div>
        <br />
        <div class="table-responsive">
            <br />

            <h2 v-if="campaign_types != undefined && campaign_types.length == 0">Not Found</h2>

            <table v-if="campaign_types != undefined && campaign_types.length > 0"
                class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th data-field="id" data-sortable="true">{{ t('sponsorships_id') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('sponsorships_name') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{
                            t('orphan_table_column_registration_date') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_update_date') }}
                        </th>
                        <th data-field="is_checked" data-sortable="true">{{ t('orphan_table_column_checked') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_actions') }}</th>
                    </tr>
                </thead>
                <tbody id="myTable" v-if="campaign_types != undefined">
                    <tr v-for="type in campaign_types" :key="type.id"
                        :class="type.checked ? 'table-primary' : 'table-danger'">
                        <td>{{ type.id }}</td>
                        <td>{{ type.name }}</td>
                        <td>{{ generateDate(type.created_at) }}</td>
                        <td>{{ generateDate(type.updated_at) }}</td>
                        <td>{{ type.checked ? t('checked') : t('not_checked') }} </td>
                        <td>
                            <button @click="openModal(event, true, type)"
                                class="btn edit-button btn-sm btn-outline-primary"><i class="fa fa-pencil"></i> </button>
                            <button class="btn btn-sm btn-outline-danger" @click="deleteType(type)"><i
                                    class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-sm-1">
                <select v-model="selected_per_page" id="pageSize" class="form-control d-inline-block w-auto"
                    @change="perPageChange">
                    <option v-for="option in per_page_options" :value="option.value" :key="option.value">{{ (option.label)
                    }}
                    </option>
                </select>
            </div>
            <div class="col-sm">
                <div class="float-right">
                    <button class="btn btn-outline-secondary" :disabled="page.value == 1" @click="changePage(false)">
                        <i class="fas fa-angle-left"></i> {{ t('orphan_prev_button') }}
                    </button>
                    <button class="btn btn-outline-secondary" :disabled="page.value + 1 > [page.count]"
                        @click="changePage(true)">
                        {{ t('orphan_next_button') }} <i class="fas fa-angle-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="float-right mr-3">
            <span>{{ t('orphan_table_footer_page') }} {{ page.value }} </span>
            <span>{{ t('orphan_table_footer_total') }} {{ page.count }}</span>
        </div>
    </div>
</template>
    
<script>
import BaseModal from "../BaseModal.vue";
import CreateCampaignType from "./Modal/CreateCampaignType.vue";
import EditCampaignType from "./Modal/EditCampaignType.vue";
import { LoadingStore } from '@/stores/loading';
import { storeToRefs } from 'pinia';
import { NotificationStore } from '@/stores/notification';
import { CampaignTypeStore } from '@/stores/campaignTypeStore';
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import router from '@/routes/index.js'


export default {
    name: 'CampaignTypeTable',
    components: {
        BaseModal,
        CreateCampaignType,
        EditCampaignType
    },

    setup() {
        const loadingStore = LoadingStore();
        const notificationStore = NotificationStore();
        const campaignTypeStore = CampaignTypeStore();
        const { is_loading } = storeToRefs(loadingStore);
        const { campaign_types, page, states, field_states } = storeToRefs(campaignTypeStore);

        const isOpen = ref(false);
        const { t } = useI18n({});
        return {
            loadingStore,
            notificationStore,
            campaignTypeStore,
            campaign_types,
            states,
            field_states,
            page,
            is_loading,
            isOpen, t
        };
    },

    watch: {
        currencies: {
            handler(newv) {
                console.log("NEW", newv);


            },
            deep: true
        },
        page: {
            deep: true
        },
        campaign_types: {
            deep: true,
        }
    },
    data() {
        return {
            selected_currency: null,
            sort_column_options: [
                { label: 'sponsorships_name', value: 'name' },
                { label: 'orphan_filter_created_at', value: 'created_at' },
                { label: 'orphan_filter_updated_at', value: 'updated_at' },
            ],
            selected_sort_column: 'created_at',

            sort_by_options: [
                { label: 'orphan_filter_order_by_desc', value: '3' },
                { label: 'orphan_filter_order_by_asc', value: '2' },
            ],
            per_page_options: [
                { label: '5', value: '5' },
                { label: '10', value: '10' },
                { label: '15', value: '15' },
            ],
            selected_per_page: 5,
            selected_family: null,
            selected_sort_by_options: '3',

            isModalOpen: false,
            editIsOpen: false,
            isDisabled: false,
            first_disable: true,
            clean_state: false,
            name_disable: false,
            last_name_disable: false,
            ngo_id_disable: false,
            timeout: undefined,
            search: '',

        };
    },

    async created() {
        let params = this.parseURLParamaters();
        this.page.value = params.page;
        this.selected_per_page = params.per_page;
        this.selected_sort_column = params.sort_column;
        this.selected_sort_by_options = params.sort_by;
        await this.getData();
    },


    methods: {
        parseURLParamaters() {
            const url_params = new URLSearchParams(window.location.search);
            let params = {
                page: url_params.get('page') ? url_params.get('page') : 1,
                per_page: url_params.get('per_page') ? url_params.get('per_page') : 5,
                sort_column: url_params.get('sort_column') ? url_params.get('sort_column') : 'created_at',
                sort_by: url_params.get('sort_by') ? url_params.get('sort_by') : '3'
            };
            return params;
        },

        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.search_fetch, 150);
        },

        perPageChange() {
            this.page.value = 1;
            this.getData(undefined, true);
        },
        fetch(status = false, is_update = false) {
            if (status) {
                let status = false;
                if (this.selected_sort_by_options == 3 && !is_update)
                    status = true;
                this.getData(undefined, status);

            }
        },

        search_fetch(page_number = 1) {
            if (page_number == 0)
                page_number = 1;
            if (this.search.length == 0)
                this.getData(true);
            else {
                this.loadingStore.setLoading(true);
                this.campaignTypeStore.search_fetch(this.search, 1, page_number, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
                this.loadingStore.setLoading(false);
            }
        },
        async openModal(event, is_edit = false, data = undefined) {
            if (is_edit) {
                this.loadingStore.setLoading(true);
                let response = await this.campaignTypeStore.getType(data.id);

                if (!response.status) {
                    let message = this.t('campaign_type_not_found');
                    let text = this.t('refresh_data');
                    this.notificationStore.popupMessage(message, text, false, 'center', false, this.close(), this.close());
                }
                else {
                    this.editIsOpen = true;

                }
                this.loadingStore.setLoading(false);

            }
            else
                this.isOpen = true;
        },

        close() {
            this.editIsOpen = false;
            this.isOpen = false;
            this.campaignTypeStore.cleanStates();

        },

        async changePage(next) {
            this.page.value = next ? this.page.value + 1 : this.page.value - 1;
            await this.getData();
        },

        async getData(event, is_refresh = false) {
            const newURL = `${window.location.pathname}?page=${this.page.value}&per_page=${this.selected_per_page}&sort_column=${this.selected_sort_column}&sort_by=${this.selected_sort_by_options}`;
            router.push(newURL);

            if (is_refresh) {
                this.page.value = 1;
            }
            this.loadingStore.setLoading(true);
            if (this.search == "")
                await this.campaignTypeStore.get(this.page.value, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options)
            else
                this.search_fetch(this.page.value);

            this.loadingStore.setLoading(false);
        },

        async deleteType(data) {
            this.notificationStore.popupDialog(this.t('orphan_modal_delete_button'), this.t('orphan_modal_cancel_button'),
                this.t('campaign_type_delete_title'),
                data.name + " (" + data.id + ") " + this.t('orphan_delete_text'),
                true, data, 'center', this.deleteCall, undefined);

        },


        async deleteCall(data) {
            this.loadingStore.setLoading(true);
            let response = await this.campaignTypeStore.destroy(data.id);
            if (response.status) {
                if (this.page.number_of_data % this.page.per_page == 1 && this.page.value > 1)
                    this.page.value -= 1;
                await this.getData();
                let delete_text = data.name + '(' + data.id + ') ' + this.t('campaign_type_deleted');
                this.notificationStore.popupMessage(delete_text, "", true, "bottom", true);
            }
            else {
                let delete_text = data.name + '(' + data.id + ') ' + this.t('campaign_type_not_deleted');
                this.notificationStore.popupMessage(delete_text, this.t('refresh_data'), false, "center", false);
            }
            this.loadingStore.setLoading(false);
        },

        generateDate(date, include_date = false) {
            let d = new Date(date);
            const options = { weekday: 'short', month: 'short', day: 'numeric' };
            if (include_date) {
                delete options.weekday;
                options.year = 'numeric';
            }
            const formattedDate = d.toLocaleDateString('en-US', options);
            return formattedDate;
        },
    },

}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 767px) {
    .right-element {
        margin-top: 10px;
    }

    .containers {
        width: 100%;
    }
}

.edit-button {
    margin-right: 2%;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}

.containers {
    width: 95%;
    margin-left: 2.5%;
}

label {
    float: left;
}
</style>
    