import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";

axios.defaults.baseURL = base_url;

export const TitleStore = defineStore("titleStore", {
  state: () => ({
    title: "",
  }),

  getters: {
    getTitle: (state) => state.title,
  },

  actions: {
    createTitle(pathname) {
      switch (pathname) {
        case "/app/donation/history":
          this.title = "navbar_donation_history";
          break;
        case "/app/orphans":
          this.title = "navbar_orphans";
          break;
        case "/app/emails":
          this.title = "navbar_emails";
          break;
        case "/app/notifications/":
          this.title = "header_notifications";
          break;
        case "/app/admin-panel/":
          this.title = "header_admin_panel";
          break;
        case "/app/orphans/family":
          this.title = "header_orphans_family";
          break;
        case "/app/orphans/donations":
          this.title = "header_orphans_donations";
          break;
        case "/app/sponsorships/":
          this.title = "navbar_sponsorships";
          break;
        case "/app/sponsorships/types":
          this.title = "header_sponsorship_types";
          break;
        case "/app/campaigns":
          this.title = "navbar_campaigns";
          break;
        case "/app/campaigns/types":
          this.title = "header_campaign_types";
          break;
        case "/app/campaigns/donations":
          this.title = "header_campaign_donations";
          break;
        case "/app/donators/public":
          this.title = "header_donators_public";
          break;
        case "/app/donators/private":
          this.title = "header_donators_private";
          break;
        case "/app/donators/tax-receipt":
          this.title = "header_tax_receipt";
          break;
        default:
          this.title = "";
          break;
      }
    },
  },
});
