import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";

axios.defaults.baseURL = base_url;

export const OrphanFamilyEditStates = defineStore("orphanFamilyEditStates", {
  state: () => ({
    states: {},
    family_id: undefined,
    orginal_states: {},
    is_exist: false,
  }),

  getters: {},

  actions: {
    cleanStates() {
      this.states = {};
      this.family_id = undefined;
      this.orginal_states = {};
      this.is_exist = false;
    },

    setId(id) {
      this.family_id = id;
    },

    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async getFamily(id) {
      const url = "api/family/" + id;
      return axios
        .get(url)
        .then((response) => {
          this.states = response.data;
          return { status: true, data: this.states };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async deleteOrphan(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = "api/orphans/" + id;
      return axios
        .delete(url)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async checkResponse(response_code) {
      if (response_code == 401) {
        if (localStorage.getItem("ngo_access_token") !== null) {
          localStorage.removeItem("ngo_access_token");
          window.location.replace("/login");
        }
      }
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
