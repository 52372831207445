import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const CampaignsDonateStore = defineStore("campaignsDonateStore", {
  state: () => ({
    states: {
      donator_type: -1,
      selected_donator_type: 0,
      donator_id: null,
      donator: null,
      campaign_id: null,
      amount: null,
    },

    donates: undefined,
    page: {
      value: 0,
      count: 0,
    },
    original_states: {},
    campaigns: [],
    donators: [],
  }),

  getters: {},

  actions: {
    cleanStates() {
      this.states = {
        donator_type: -1,
        selected_donator_type: 0,
        donator_id: null,
        donator: null,
        campaign_id: null,
        amount: null,
      };
      this.field_states = {
        is_exist: false,
      };
      this.original_states = {};
      this.campaigns = [];
      this.donators = [];
    },

    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async update(id, data) {
      const url = "api/donations/campaign/" + id;
      return axios
        .put(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async add(data) {
      const url = "api/donations/campaign";
      return axios
        .post(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async destroy(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = "api/donations/campaign/" + id;
      return axios
        .delete(url)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async get(page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/donations/campaign", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            filters: {
              donator_id: this.states.donator_id,
              currency:
                this.states.currency == "null" ? null : this.states.currency,
              donator_type: parseInt(this.states.donator_type),
              campaign_id:
                this.states.campaign_id == "null"
                  ? null
                  : this.states.campaign_id,
            },
          },
        })
        .then((response) => {
          this.donates = response.data.data;
          this.page.count = response.data.number_of_page;
          this.page.value = this.page.count > 0 ? response.data.page_number : 0;
          this.page.number_of_data = response.data.number_of_data;
          this.page.per_page = response.data.per_page;
          return { status: true };
        })
        .catch((exception) => {
          console.log("EXCEPTion = ", exception);
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async getDonation(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }

      const url = "api/donations/campaign/" + id;
      return axios
        .get(url)
        .then((response) => {
          let data = response.data;
          this.states = data;
          this.original_states = Object.assign({}, this.states);

          return { status: true, data: this.states };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async getDonators(is_public = false) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      let url = is_public ? "api/public/donators" : "api/donators";
      return axios
        .get(url)
        .then((response) => {
          this.donators = response.data.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Donators Not Found!" };
        });
    },

    async getCampaigns() {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      let url = "api/campaigns";
      return axios
        .get(url)
        .then((response) => {
          this.campaigns = response.data.data;

          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Donators Not Found!" };
        });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
