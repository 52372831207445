export default {
  "donators_table_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "donators_table_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "donators_table_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "donators_table_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "donators_table_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "donators_table_civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilité"])},
  "donators_table_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de donateurs"])},
  "donators_table_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "donators_table_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Département"])},
  "donators_table_county_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le département"])},
  "donators_table_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "donators_table_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "donator_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les donateurs"])},
  "donator_regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régulier"])},
  "donator_vip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP"])},
  "donators_email_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez l'email"])},
  "donators_email_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le donateur existe déjà"])},
  "donators_email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ Email ne peut pas être vide"])},
  "donators_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nom"])},
  "donators_name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ Nom ne peut pas être vide"])},
  "donators_lastname_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nom de famille"])},
  "donators_lastname_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ Nom de famille ne peut pas être vide"])},
  "donators_phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le numéro de téléphone"])},
  "donators_address_county_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le département"])},
  "donators_address_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez l'adresse"])},
  "donators_adress_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le code postal"])},
  "donators_modal_add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un donateur"])},
  "donators_modal_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le donateur"])},
  "donators_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le donateur ?"])},
  "donators_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donateurs ajoutés avec succès"])},
  "donators_not_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'ajouter des donateurs"])},
  "donators_table_phone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone 2"])},
  "donators_table_email2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email 2"])},
  "donate_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le don"])},
  "donate_delete_text_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don de "])},
  "donate_delete_text_donation_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" à la campagne "])},
  "donate_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don supprimé avec succès"])},
  "donate_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer le don"])},
  "update_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les dons"])},
  "donation_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don mis à jour"])},
  "donation_not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le don n'a pas été mis à jour"])},
  "donation_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don introuvable"])},
  "import_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un fichier CSV"])},
  "csv_has_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format CSV n'est pas correct"])},
  "csv_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV téléchargé"])},
  "donator_type_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le type de donateur"])},
  "create-tax-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer un reçu fiscal"])},
  "generate-pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un PDF"])},
  "enter_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le don total"])},
  "select_fiscal_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'année fiscale"])},
  "total_donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des dons"])}
}