<template>
  <div class="m-3">
    <!-- <div class="row">
      <div class="col-md-6">
        <input @keyup="searching" @paste.once="pastedetected" v-model="search" class="form-control" id="myInput"
          type="text" placeholder="Search..">
      </div>
    </div> -->
    <div class="row justify-content-between">
      <div class="col col-md-8 d-flex flex-row justify-content-between">
        <select v-model="selected_sort_by_options" class="form-control mx-1" id="familyFilter" @change="getData">
          <option v-for="option in sort_by_options" :value="option.value" :key="option.value">{{ t(option.label)
          }}
          </option>
        </select>
        <select v-model="selected_sort_column" class="form-control mx-1" id="familyFilter" @change="getData">
          <option v-for="option in sort_column_options" :value="option.value" :key="option.value">{{
            t(option.label) }}
          </option>
        </select>
        <select v-model="states.enrollment_id" class="form-control mx-1" id="familyFilter" @change="getData">
          <option :value=undefined>{{ t('select_sponsorship') }}</option>
          <option v-for="option in enrollments" :value="option.id" :key="option.value">{{ option.donator.name + " -> " +
            option.orphan.name + " (" + option.id + ")" }}
          </option>
        </select>
      </div>
      <div class="col col-md-2 d-flex flex-row justify-content-end">
        <button id="refreshTable" class="edit-buttons btn btn-primary" @click="getData(event, true)">{{
          t('orphan_table_operation_refresh') }}</button>
      </div>
    </div>
    <br />

    <br />

    <div class="table-responsive">


      <h2 v-if="donations != undefined && donations.length == 0">Not Found</h2>
      <br />
      <table v-if="donations != undefined && donations.length > 0" class="table table-bordered table-striped">

        <thead>
          <tr>
            <th data-field="id" data-sortable="true">{{ t('sponsorships_id') }}</th>
            <th data-field="name" data-sortable="true">{{ t('enrolled_sponsorships') }}</th>
            <th data-field="name" data-sortable="true">{{ t('enrolled_orphan_ngo_id') }}</th>
            <th data-field="name" data-sortable="true">{{ t('enrolled_orphan_name') }}</th>
            <th data-field="name" data-sortable="true">{{ t('enrolled_donator_name') }}</th>

            <th width="12%" data-field="name" data-sortable="true">{{ t('amount') }}</th>
            <th data-field="registration_date" data-sortable="true">{{
              t('orphan_table_column_registration_date') }}</th>
            <th width="5%" data-field="registration_date" data-sortable="true">{{
              t('orphan_table_column_actions') }}</th>
          </tr>
        </thead>
        <tbody id="myTable" v-if="donations != undefined">
          <tr v-for="donation in donations " :key="donation.id">
            <td>{{ donation.id }}</td>
            <td>{{ donation.enrolled_sponsorships.sponsorships.name }}</td>
            <td>{{ donation.enrolled_sponsorships.orphan.ngo_id }}</td>
            <td>{{ donation.enrolled_sponsorships.orphan.name }} {{ donation.is_family ? (t("family")) :
              donation.enrolled_sponsorships.orphan.last_name
            }} </td>
            <td>{{ donation.enrolled_sponsorships.donator.name + " " + donation.enrolled_sponsorships.donator.last_name }}
              ({{ donation.enrolled_sponsorships.donator.id
              }})</td>
            <td>{{ donation.enrolled_sponsorships.sponsorships.currency.symbol + " " +
              donation.enrolled_sponsorships.sponsorships.amount }}</td>

            <td>{{ generateDate(donation.created_at) }}</td>
            <td>
              <button class="btn btn-sm btn-outline-danger" @click="deleteDonate(donation)"><i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-sm-1">
        <select v-model="selected_per_page" id="pageSize" class="form-control d-inline-block w-auto"
          @change="perPageChange">
          <option v-for="option in per_page_options" :value="option.value" :key="option.value">{{ (option.label)
          }}
          </option>
        </select>
      </div>
      <div class="col-sm">
        <div class="float-right">
          <button class="btn btn-outline-secondary" :disabled="page.value == 1" @click="changePage(false)">
            <i class="fas fa-angle-left"></i> {{ t('orphan_prev_button') }}
          </button>
          <button class="btn btn-outline-secondary" :disabled="page.value + 1 > [page.count]" @click="changePage(true)">
            {{ t('orphan_next_button') }} <i class="fas fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="float-right mr-3">
      <span>{{ t('orphan_table_footer_page') }} {{ page.value }} </span>
      <span>{{ t('orphan_table_footer_total') }} {{ page.count }}</span>
    </div>
  </div>
</template>
  
<script>
import { LoadingStore } from '@/stores/loading';
import { storeToRefs } from 'pinia';
import { NotificationStore } from '@/stores/notification';
import { DonationsStore } from "@/stores/DonationsStore";

import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import router from '@/routes/index.js'
import { UserStore } from '@/stores/userStore';

export default {
  name: 'DonationTable',
  components: {

  },

  setup() {
    const loadingStore = LoadingStore();
    const notificationStore = NotificationStore();
    const { is_loading } = storeToRefs(loadingStore);
    const donationsStore = DonationsStore();
    const userStore = UserStore()
    const { page, states, enrollments, donations } = storeToRefs(donationsStore);

    const isOpen = ref(false);
    const { t } = useI18n({});
    return {
      loadingStore,
      notificationStore,
      donationsStore,
      enrollments,
      userStore,
      donations,
      states,
      page,
      is_loading,
      isOpen, t
    };
  },

  watch: {


    donations: {
      deep: true
    },
    enrollments: {
      deep: true
    },
    currencies: {
      handler(newv) {
        console.log("NEW", newv);


      },
      deep: true
    },
    states: {
      handler(newv) {
        console.log("NEW", newv);


      },
      deep: true
    },
    donates: {
      deep: true,
    },
    page: {
      deep: true
    },
    donators: {
      deep: true
    },
    campaigns: {
      deep: true,
    }
  },
  data() {
    return {
      sort_column_options: [
        { label: 'orphan_filter_created_at', value: 'created_at' },
        { label: 'orphan_filter_updated_at', value: 'updated_at' },
      ],
      selected_sort_column: 'created_at',

      sort_by_options: [
        { label: 'orphan_filter_order_by_desc', value: '3' },
        { label: 'orphan_filter_order_by_asc', value: '2' },
      ],
      donator_type: [
        { label: 'all_donators', value: '-1' },
        { label: 'public_donators', value: '2' },
        { label: 'private_donators', value: '0' },

      ],
      per_page_options: [
        { label: '5', value: '5' },
        { label: '10', value: '10' },
        { label: '15', value: '15' },
      ],
      selected_per_page: 5,
      selected_family: null,
      selected_sort_by_options: '3',

      isModalOpen: false,
      editIsOpen: false,
      isDisabled: false,
      first_disable: true,
      clean_state: false,
      name_disable: false,
      last_name_disable: false,
      ngo_id_disable: false,
      timeout: undefined,
      search: '',

    };
  },

  async created() {
    let params = this.parseURLParamaters();
    this.page.value = params.page;
    this.selected_per_page = params.per_page;
    this.selected_sort_column = params.sort_column;
    this.selected_sort_by_options = params.sort_by;
    this.states.is_family = params.is_family;
    this.states.is_active = params.is_active;
    this.states.orphan_id = params.orphan_id;
    this.states.donator_id = params.donator_id;
    this.states.sponsorship_id = params.sponsorship_id;
    await this.getPreData();

    //   await this.getData();
  },


  methods: {
    sendMail() {
      alert("TODO : Send Mail");
      // TODO : you need to implement send mail notification on laravel backend and your store
    },
    parseURLParamaters() {
      const url_params = new URLSearchParams(window.location.search);
      let params = {
        page: url_params.get('page') ? url_params.get('page') : 1,
        per_page: url_params.get('per_page') ? url_params.get('per_page') : 5,
        sort_column: url_params.get('sort_column') ? url_params.get('sort_column') : 'created_at',
        sort_by: url_params.get('sort_by') ? url_params.get('sort_by') : '3',
        enrollment_id: url_params.get('enrollment_id') ? url_params.get('enrollment_id') : undefined,

      };
      return params;
    },

    searching() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.search_fetch, 150);
    },

    perPageChange() {
      this.page.value = 1;
      this.getData(undefined, true);
    },
    fetch(status = false, is_update = false) {
      if (status) {
        let status = false;
        if (this.selected_sort_by_options == 3 && !is_update)
          status = true;
        this.getData(undefined, status);

      }
    },

    async search_fetch(page_number = 1) {
      if (page_number == 0)
        page_number = 1;
      if (this.search.length == 0) {
        this.getData(undefined, true);
      }
      else {
        this.loadingStore.setLoading(true);
        await this.donationsStore.search_fetch(this.search, 1, page_number, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
        this.loadingStore.setLoading(false);
      }
    },
    async openModal(event, is_edit = false, data = undefined) {
      if (is_edit) {
        this.loadingStore.setLoading(true);
        let response = await this.donationsStore.getEnrollment(data.id);

        if (!response.status) {
          let message = this.t('enrollment_not_found');
          let text = this.t('refresh_data');
          this.notificationStore.popupMessage(message, text, false, 'center', false, this.close(), this.close());
        }
        else {
          this.editIsOpen = true;

        }
        this.loadingStore.setLoading(false);

      }
      else
        this.isOpen = true;
    },

    close() {
      this.editIsOpen = false;
      this.isOpen = false;
      this.donationsStore.cleanStates();
    },

    async changePage(next) {
      this.page.value = next ? this.page.value + 1 : this.page.value - 1;
      await this.getData();
    },

    async getPreData() {
      // TODO : CALL CAMPAIGN DONATES STORE
      this.loadingStore.setLoading(true);
      await this.donationsStore.getEnrollments();
      await this.getData();
      this.loadingStore.setLoading(false);
    },

    async getData(event, is_refresh = false) {
      if (this.page.value == 0)
        this.page.value = 1;

      const newURL = `${window.location.pathname}?page=${this.page.value}&per_page=${this.selected_per_page}&sort_column=${this.selected_sort_column}&sort_by=${this.selected_sort_by_options}&enrollment_id=${this.states.enrollment_id}`;

      router.push(newURL);
      if (is_refresh) {
        this.page.value = 1;
      }
      this.loadingStore.setLoading(true);
      await this.donationsStore.get(this.page.value, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options)

      this.loadingStore.setLoading(false);
    },

    async deleteDonate(data) {
      console.log("IAM", data.id);
      let text = this.t('delete_donate_text') + " " + data.id + "";
      text += " " + this.t('orphan_delete_text');
      this.notificationStore.popupDialog(this.t('orphan_modal_delete_button'), this.t('orphan_modal_cancel_button'),
        this.t('delete_donate') + " " + data.id + " ?",
        text,
        true, data, 'center', this.deleteCall, undefined);

    },

    async deleteCall(data) {
      this.loadingStore.setLoading(true);
      let response = await this.donationsStore.destroy(data.id);
      if (response.status) {
        if (this.page.number_of_data % this.page.per_page == 1 && this.page.value > 1)
          this.page.value -= 1;
        await this.getData();
        // this.userStore.getNotifications()
        let delete_text = data.id + " " + this.t('donation_deleted');
        this.notificationStore.popupMessage(delete_text, "", true, "bottom", true);
      }
      else {
        let delete_text = data.id + " " + this.t('donation_not_deleted');
        this.notificationStore.popupMessage(delete_text, this.t('refresh_data'), false, "center", false);
      }
      this.loadingStore.setLoading(false);
    },

    generateDate(date, include_date = false) {
      let d = new Date(date);
      const options = { weekday: 'short', month: 'short', day: 'numeric' };
      if (include_date) {
        delete options.weekday;
        options.year = 'numeric';
      }
      const formattedDate = d.toLocaleDateString('en-US', options);
      return formattedDate;
    },
  },

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 767px) {
  .right-element {
    margin-top: 10px;
  }

  .containers {
    width: 100%;
  }
}

.edit-button {
  margin-right: 2%;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1em auto;
  padding: 0.5em 3.5em 0.5em 1em;
}

td>button {
  margin-left: 2%;
}

button:disabled {
  background: #cccccc;
  color: black;
  border-color: black;
}

.containers {
  width: 95%;
  margin-left: 2.5%;
}

label {
  float: left;
}
</style>
  