import { createRouter, createWebHistory } from "vue-router";
import AuthLogin from "../components/auth/Login";
import Home from "../Pages/Home";
import OrphanTable from "../components/Orphans/OrphanTable";
import FamilyTable from "../components/Orphans/FamilyTable";
import DonationTable from "../components/Orphans/DonationTable";
import SponsorshipsTypeTable from "../components/Sponsorships/SponsorshipsTypeTable";
import PrivateDonatorsTable from "../components/Donators/PrivateDonatorsTable";
import PublicDonatorsTable from "../components/Donators/PublicDonatorsTable";
import CampaignTypeTable from "../components/Campaigns/CampaignTypeTable";
import CampaignsTable from "../components/Campaigns/CampaignsTable";
import CampaignsDonationsTable from "../components/Campaigns/CampaignsDonationsTable";
import EnrolledSponosrships from "../components/Sponsorships/EnrolledSponsorships";
import ProfilePage from "../components/user/ProfilePage";
import AdminPanel from "../components/user/AdminPanel";
import DonationHistory from "../components/Statistics/DonationHistory";
import NotificationPage from "../components/Notifications/NotificationPage";
import TaxReceiptPage from "../components/Donators/TaxReceiptPage";
import EmailPage from "../components/Emails/EmailPage";

const router = new createRouter({
  root: "/",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/app",
    },
    {
      path: "/login",
      name: "login",
      component: AuthLogin,
    },

    {
      path: "/app",
      name: "app",
      component: Home,
      children: [
        {
          path: "orphans",
          name: "orphans",
          component: OrphanTable,
        },
        {
          path: "emails",
          name: "emails",
          component: EmailPage,
        },
        {
          path: "donation/history",
          name: "donation/history",
          component: DonationHistory,
        },
        {
          path: "orphans/family",
          name: "orphans/family",
          component: FamilyTable,
        },
        {
          path: "orphans/donations",
          name: "orphans/donations",
          component: DonationTable,
        },
        {
          path: "sponsorships",
          name: "sponsorships/",
          component: EnrolledSponosrships,
        },
        {
          path: "sponsorships/types",
          name: "sponsorships/types",
          component: SponsorshipsTypeTable,
        },
        {
          path: "donators/private",
          name: "donators/private",
          component: PrivateDonatorsTable,
        },
        {
          path: "donators/public",
          name: "donators/public",
          component: PublicDonatorsTable,
        },
        {
          path: "donators/tax-receipt",
          name: "donators/tax-receipt",
          component: TaxReceiptPage,
        },
        {
          path: "campaigns/types",
          name: "campaigns/type",
          component: CampaignTypeTable,
        },
        {
          path: "campaigns",
          name: "campaigns/",
          component: CampaignsTable,
        },
        {
          path: "campaigns/donations",
          name: "campaigns/donations",
          component: CampaignsDonationsTable,
        },
        {
          path: "notifications",
          name: "notifications",
          component: NotificationPage,
        },
        { path: "profile", name: "profile", component: ProfilePage },
        { path: "admin-panel", name: "admin-panel", component: AdminPanel },
      ],
    },
    {
      path: "/subscribers",
      name: "subscribers",
    },

    {
      path: "/fit/analyze/:id",
      name: "fit-file",
    },
  ],
  history: createWebHistory(),
});
export default router;
