export default {
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
  "create_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un rôle"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les rôles"])},
  "role_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de rôle"])},
  "role_not_created_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rôle n'a pas pu être créé!"])},
  "role_created_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rôle a été créé avec succès!"])},
  "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
  "select_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les autorisations auxquelles ce rôle aura accès :"])},
  "update_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le rôle"])},
  "role_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de supprimer ce rôle ?"])},
  "role_delete_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (", _interpolate(_named("id")), ") sera supprimé, vous ne pourrez pas annuler cette action!"])},
  "assign_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer un rôle"])},
  "role_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rôle a été modifié avec succès"])},
  "role_change_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rôle n'a pas pu être mis à jour!"])},
  "entries_to_be_checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrées à vérifier:"])},
  "orphans_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table des Orphelins"])},
  "donators_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table des Donateurs"])},
  "public_donators_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table des Donateurs Publics"])},
  "campaigns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table des Campagnes"])},
  "campaign_donations_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table des Dons de Campagne"])},
  "campaign_types_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table des Types de Campagne"])},
  "orphans_family_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table des Familles d'Orphelins"])},
  "enrolled_sponsorships_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table des Parrainages Enregistrés"])},
  "sponsorships_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table des Types de Parrainage"])},
  "missing_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dons Manquants:"])},
  "no_missing_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun Don Manquant"])},
  "user_delete_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur a été supprimé avec succès."])},
  "index_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtient toutes les données de la table"])},
  "show_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtient une entrée de la table"])},
  "search_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtient les données de la table en fonction des options de recherche"])},
  "create_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crée une nouvelle entrée dans la table"])},
  "destroy_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprime l'entrée de la table, cette modification ne peut pas être annulée !"])},
  "update_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met à jour une entrée dans la table"])},
  "upload_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharge un fichier CSV dans la table"])}
}