<template>
  <BaseModal :open="isOpen" @close="isOpen = !isOpen">
    <CreateOrphan v-if="isOpen" :ngo_id_disable="ngo_id_disable" :name_disable="name_disable"
      :last_name_disable="last_name_disable" :first_disable="first_disable" :isDisabled="isDisabled" @status="fetch"
      @check="check_fields" @search="check_is_exist" @close="isOpen = !isOpen"></CreateOrphan>

  </BaseModal>
  <BaseModal :open="editIsOpen" @close="editIsOpen = !editIsOpen">
    <EditOrphan v-if="editIsOpen" :ngo_id="12345" :ngo_id_disable="ngo_id_disable" :name_disable="name_disable"
      :last_name_disable="last_name_disable" :first_disable="first_disable" :isDisabled="isDisabled" @status="fetch"
      @check="check_fields" @search="check_is_exist" @close="editIsOpen = !editIsOpen"></EditOrphan>
  </BaseModal>
  <div class=" m-3 py-2">
    <div class="row justify-content-between">
      <div class="col-md-6">
        <input v-model="search" @keyup="searching" class="form-control" id="myInput" type="text" placeholder="Search..">
      </div>
      <div class="col col-md-2 d-flex flex-row justify-content-between">
        <div class="w-100 mx-1">
          <button class="edit-buttons btn btn-primary w-100" @click="openModal">{{ t('orphan_table_operation_add') }}
          </button>
        </div>
        <div class="w-100 mx-1">
          <button id="refreshTable" class="edit-buttons btn btn-primary w-100" @click="getData(event, true)">{{
            t('orphan_table_operation_refresh') }}</button>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col col-md-2">
        <select v-model="selected_sort_by_options" class="form-control" id="familyFilter" @change="getData">
          <option v-for="option in sort_by_options" :value="option.value" :key="option.value">{{ t(option.label) }}
          </option>
        </select>
      </div>
      <div class="col col-md-2">
        <select v-model="selected_civility" class="form-control" id="familyFilter" @change="getData">
          <option v-for="option in civility_options" :value="option.value" :key="option.value">{{ t(option.label) }}
          </option>
        </select>
      </div>
      <div class="col col-md-2">
        <select v-model="selected_sort_column" class="form-control" id="familyFilter" @change="getData">
          <option v-for="option in sort_column_options" :value="option.value" :key="option.value">{{ t(option.label) }}
          </option>
        </select>
      </div>
      <div class="col col-md-2">
        <select v-model="treatment_type" class="form-control" id="familyFilter" @change="getData">
          <option v-for="option in treatment_options" :value="option.value" :key="option.value">{{ t(option.label) }}
          </option>
        </select>
      </div>
      <div class="col col-md-2">
        <select class="form-control" id="familyFilter" @change="getData" v-model="selected_family"
          v-if="families != undefined && families.data.length > 0">
          <option :value=null>{{ t('orphan_filter_family_option') }}</option>
          <option v-for="option in families.data" :value="option.id" :key="'family' + option.id"> {{ option.name }} {{ '('
            + option.id + ')' }}</option>
        </select>
      </div>
    </div>
    <br />
    <div class="row">
    </div>
    <div class="table-responsive">
      <br />
      <h2 v-if="orphans != undefined && orphans.data.length == 0">Not Found</h2>
      <table v-if="orphans != undefined && orphans.data.length > 0" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th data-field="id" data-sortable="true">{{ t('orphan_table_column_ngoid') }}</th>
            <th data-field="civility" data-sortable="true">{{ t('orphan_table_column_civility') }}</th>
            <th data-field="name" data-sortable="true">{{ t('orphan_table_column_name') }}</th>
            <th data-field="last_name" data-sortable="true">{{ t('orphan_table_column_lastname') }}</th>
            <th data-field="location" data-sortable="true">{{ t('orphan_table_column_location') }}</th>
            <th style="width:8%" data-sortable="false">{{ t('orphan_table_column_dob') }}</th>
            <th data-field="registration_date" data-sortable="true">{{ t('treatment_type') }}</th>
            <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_registration_date') }}</th>
            <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_update_date') }}</th>
            <th data-field="is_checked" data-sortable="true">{{ t('orphan_table_column_checked') }}</th>
            <th data-field="actions" data-sortable="true">{{ t('orphan_table_column_actions') }}</th>

          </tr>
        </thead>
        <tbody id="myTable" v-if="orphans != undefined">
          <tr v-for="orphan in orphans.data" :key="orphan.id" :class="orphan.checked ? 'table-primary' : 'table-danger'">
            <td>{{ orphan.ngo_id }}</td>
            <td>{{ orphan.civility == 0 ? t('orphan_filter_civility_male') : orphan.civility == 1 ?
              t('orphan_filter_civility_female') : t('orphan_filter_civility_undefined') }}</td>
            <td>{{ orphan.name }}</td>
            <td>{{ orphan.last_name }}</td>
            <td>{{ orphan.location ? orphan.location : '--' }}</td>
            <td>{{ orphan.dob ? generateDate(orphan.dob, true) : '--' }} </td>
            <td>{{ orphan.treatment == null ? t('Undefined') : t(orphan.treatment.name) }} </td>
            <td>{{ generateDate(orphan.created_at) }}</td>
            <td>{{ generateDate(orphan.updated_at) }}</td>
            <td>{{ orphan.checked ? t('checked') : t('not_checked') }} </td>
            <td>
              <button @click="openModal(event, true, {
                treatment_type: orphan.treatment ? orphan.treatment.id : null,
                ngo_id: orphan.ngo_id, name: orphan.name, last_name: orphan.last_name, phone: orphan.phone,
                location: orphan.location != undefined ? orphan.location.toUpperCase() : '', dob: orphan.dob,
                civility: orphan.civility == null ? -2 : orphan.civility,
                family_id: orphan.family_id,
                id: orphan.id,
                checked: orphan.checked,
                creator_id: orphan.creator_id
              })" class="btn edit-button btn-sm btn-outline-primary"><i class="fa fa-pencil"></i> </button>
              <button class="btn btn-sm btn-outline-danger" @click="deleteOrphan(orphan)"><i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-sm-1">
        <select v-model="selected_per_page" id="pageSize" class="form-control d-inline-block w-auto"
          @change="perPageChange">
          <option v-for="option in per_page_options" :value="option.value" :key="option.value">{{ (option.label) }}
          </option>
        </select>
      </div>
      <div class="col-sm">

        <div class="float-right">
          <button class="btn btn-outline-secondary" :disabled="page == 1" @click="changePage(false)">
            <i class="fas fa-angle-left"></i> {{ t('orphan_prev_button') }}
          </button>
          <button class="btn btn-outline-secondary" :disabled="page + 1 > page_count" @click="changePage(true)">
            {{ t('orphan_next_button') }} <i class="fas fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="float-right mr-3">
      <span>{{ t('orphan_table_footer_page') }} {{ page }} </span>
      <span>{{ t('orphan_table_footer_total') }} {{ page_count }}</span>
    </div>
  </div>
</template>
  
<script>
import BaseModal from "../BaseModal.vue";
import CreateOrphan from "./Modal/CreateOrphan.vue";
import EditOrphan from "./Modal/EditOrphan.vue";
import { LoadingStore } from '@/stores/loading';
import { OrphanStore } from "@/stores/orphan";
import { OrphanEditStates } from "@/stores/orphanEditStates";
import { storeToRefs } from 'pinia';
import { NotificationStore } from '@/stores/notification';
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import router from '@/routes/index.js'
import { UserStore } from "@/stores/userStore";

export default {
  name: 'OrphanTable',
  components: {
    BaseModal,
    CreateOrphan,
    EditOrphan
  },

  setup() {
    const loadingStore = LoadingStore();
    const orphanStore = OrphanStore();
    const orphanEditStates = OrphanEditStates();
    const { states } = storeToRefs(orphanEditStates);
    const notificationStore = NotificationStore();
    const { orphans } = storeToRefs(orphanStore);
    const { families } = storeToRefs(orphanStore);
    const userStore = UserStore();
    const { is_loading } = storeToRefs(loadingStore);
    const isOpen = ref(false);
    const editIsOpen = ref(false);

    const { t } = useI18n({});
    return {
      loadingStore,
      orphanStore,
      orphanEditStates,
      states,
      notificationStore,
      orphans,
      userStore,
      editIsOpen,
      families,
      is_loading,
      isOpen, t
    };

  },

  watch: {
    country: {
      handler(newv, oldv) {
        alert(newv + " " + oldv);
      }
    },
    region: {
      handler(newv, oldv) {
        alert(newv + " " + oldv);
      }
    },


    orphans: {
      handler() {
        this.page = this.orphans.page_number;
        this.page_count = this.orphans.number_of_page;
      },
      deep: true
    },

    families: {
      handler() {

      },
      deep: true,
    }
  },
  data() {
    return {
      page: 0,
      page_count: 0,
      civility_options: [
        { label: 'orphan_filter_civility_all', value: null },
        { label: 'orphan_filter_civility_male', value: '0' },
        { label: 'orphan_filter_civility_female', value: '1' },
        { label: 'orphan_filter_civility_undefined', value: '-2' },
      ],
      selected_civility: null,
      sort_column_options: [
        { label: 'orphan_filter_created_at', value: 'created_at' },
        { label: 'orphan_filter_updated_at', value: 'updated_at' },
      ],
      selected_sort_column: 'created_at',

      sort_by_options: [
        { label: 'orphan_filter_order_by_desc', value: '3' },
        { label: 'orphan_filter_order_by_asc', value: '2' },
      ],
      per_page_options: [
        { label: '5', value: '5' },
        { label: '10', value: '10' },
        { label: '15', value: '15' },
      ],
      treatment_options: [

        { label: 'All', value: null },
        { label: "no_treatment", value: 1 },
        { label: 'Medical', value: 2 },
        { label: "Finished", value: 3 },
        { label: 'Undefined', value: -1 },
      ],
      selected_per_page: 5,
      treatment_type: null,
      selected_family: null,
      selected_sort_by_options: '3',

      isModalOpen: false,
      civility: -1,
      country: '',
      region: '',
      isDisabled: false,
      first_disable: true,
      clean_state: false,
      name_disable: false,
      last_name_disable: false,
      ngo_id_disable: false,
      timeout: undefined,
      search: '',

    };
  },

  async created() {
    await this.orphanStore.getFamilies();

    let params = this.parseURLParamaters();
    this.page = params.page;
    this.selected_civility = params.civility;
    this.selected_family = params.family;
    this.selected_per_page = params.per_page;
    this.selected_sort_column = params.sort_column;
    this.selected_sort_by_options = params.sort_by;
    this.treatment_type = params.treatment_type;
    await this.getData();
  },

  methods: {
    parseURLParamaters() {
      const url_params = new URLSearchParams(window.location.search);
      let params = {
        page: url_params.get('page') ? url_params.get('page') : 1,
        civility: url_params.get('civility') ? url_params.get('civility') : null,
        family: url_params.get('family') ? url_params.get('family') : null,
        per_page: url_params.get('per_page') ? url_params.get('per_page') : 5,
        sort_column: url_params.get('sort_column') ? url_params.get('sort_column') : 'created_at',
        sort_by: url_params.get('sort_by') ? url_params.get('sort_by') : '3',
        treatment_type: url_params.get('treatment_type') ? url_params.get('treatment_type') : null
      };
      return params;
    },

    searching() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.SearchOrphan, 100);
    },

    SearchOrphan(page_number = 1) {
      this.selected_civility = null;
      this.selected_family = null;
      if (this.search.length == 0)
        this.getData();
      else {
        this.loadingStore.setLoading(true);
        this.orphanStore.search(this.search, page_number, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
        this.loadingStore.setLoading(false);
      }
    },
    changePage(next) {
      this.page = next ? this.page + 1 : this.page - 1;
      if (this.search.length > 0)
        this.SearchOrphan(this.page);
      else
        this.getData();
    },
    fetch(status = false, is_update = false) {
      if (status) {
        let status = false;
        if (this.selected_sort_column == 'updated_at' && !is_update)
          status = true;
        this.getData(undefined, status);
      }
    },
    deleteOrphan(orphan) {
      this.notificationStore.popupDialog(this.t('orphan_modal_delete_button'), this.t('orphan_modal_cancel_button'), this.t('orphan_delete_title'), orphan.name + " (" + orphan.ngo_id + ") " + this.t('orphan_delete_text'), true, orphan, 'center', this.deleteCall, undefined);
    },

    async deleteCall(orphan) {
      this.loadingStore.setLoading(true);
      let response = await this.orphanStore.deleteOrphan(orphan.id);
      if (response.status) {
        if (this.orphans.number_of_data % this.orphans.per_page == 1 && this.page > 1)
          this.page -= 1;
        await this.getData();
        let delete_text = orphan.name + '(' + orphan.ngo_id + ') ' + this.t('orphan_delete_success');
        this.notificationStore.popupMessage(delete_text, "", true, "bottom", true);
      }
      else {

        let delete_text = orphan.name + '(' + orphan.ngo_id + ') ' + this.t('orphan_delete_error');
        this.notificationStore.popupMessage(delete_text, "", false, "center", false);
      }
      this.loadingStore.setLoading(false);
    },
    perPageChange() {
      this.page = 1;
      this.getData();
    },
    generateDate(date, include_date = false) {
      let d = new Date(date);
      const options = { weekday: 'short', month: 'short', day: 'numeric' };
      if (include_date) {
        delete options.weekday;
        options.year = 'numeric';
      }
      const formattedDate = d.toLocaleDateString('en-US', options);
      return formattedDate;
    },
    async getData(event, is_refresh = false) {
      const newURL = `${window.location.pathname}?page=${this.page}&per_page=${this.selected_per_page}&sort_column=${this.selected_sort_column}&sort_by=${this.selected_sort_by_options}&civility=${this.selected_civility}&family=${this.selected_family}&treatment_type=${this.treatment_type}`;
      router.push(newURL);

      if (this.search.length != 0) {
        this.loadingStore.setLoading(true);
        this.orphanStore.search(this.search, this.page_number, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
        this.loadingStore.setLoading(false);
      }
      else {
        this.loadingStore.setLoading(true);
        await this.orphanStore.getFamilies();

        if (is_refresh) {
          if (!this.check_selected_family_exist())
            this.selected_family = null;
          this.page = 1;
        }
        await this.orphanStore.getOrphans(this.page, this.selected_per_page, this.selected_family, this.selected_civility, this.selected_sort_column, this.selected_sort_by_options, this.treatment_type);
        this.loadingStore.setLoading(false);
      }
    },

    check_selected_family_exist() {
      for (var member of this.families.data) {
        if (member.id == this.selected_family)
          return true;
      }
      return false;
    },
    openModal(event, is_edit = false, data = undefined) {
      this.first_disable = true;
      this.ngo_id_disable = false;
      this.name_disable = false;
      this.last_name_disable = false;
      if (is_edit) {
        console.log("RECEIVED DATA = ", data);
        this.states = data;
        this.editIsOpen = true;
      }
      else
        this.isOpen = true;
    },
    closeModal(is_edit = false) {
      if (is_edit)
        this.editIsOpen = false;
      else
        this.isOpen = false;
    },

    async check_fields(ngo_id, name, last_name) {
      if (ngo_id == undefined || ngo_id.length == 0)
        this.ngo_id_disable = true;
      else
        this.ngo_id_disable = false;
      if (name == undefined || name.length == 0)
        this.name_disable = true;
      else
        this.name_disable = false;
      if (last_name == undefined || last_name.length == 0)
        this.last_name_disable = true;
      else
        this.last_name_disable = false;
    },
    async check_is_exist(ngo_id) {
      if (ngo_id.length == 0) {
        this.first_disable = true;
      }
      else {
        let search = await this.orphanStore.searchOrphan(ngo_id);
        this.isDisabled = search.status;
        this.first_disable = false;
        console.log("SEARCH", search, this.isDisabled);
      }
    }
  }

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 767px) {
  .right-element {
    margin-top: 10px;
  }
}

.edit-button {
  margin-right: 2%;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1em auto;
  padding: 0.5em 3.5em 0.5em 1em;
}


/* .containers {
  width: 95%;
  margin-left: 2.5%;
} */

label {
  float: left;
}
</style>
  