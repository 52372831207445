<template>
    <BaseModal :open="isOpen" @close="isOpen = !isOpen">
        <CreatePublicDonators v-if="isOpen" @status="fetch" @close="isOpen = !isOpen">
        </CreatePublicDonators>
    </BaseModal>
    <BaseModal :open="editIsOpen" @close="editIsOpen = !editIsOpen">
        <EditPublicDonators v-if="editIsOpen" @status="fetch" @close="editIsOpen = !editIsOpen"></EditPublicDonators>
    </BaseModal>
    <div class="m-3 py-2">
        <div class="row justify-content-between">
            <div class="col-md-6">
                <input @keyup="searching" @paste.once="pastedetected" v-model="search" class="form-control" id="myInput"
                    type="text" placeholder="Search..">
            </div>
            <div class="col col-md-3 d-flex flex-row justify-content-between">
                <div class="w-100 mx-1">
                    <input class="btn btn-primary" type="file" ref="fileInput" style="display: none" accept=".csv"
                        @change="handleFileSelect">
                    <button class="edit-buttons btn btn-primary w-100" @click="openFileSelectDialog">{{ t('import_csv') }}
                    </button>
                </div>
                <div class="w-100 mx-1">
                    <button class="edit-buttons btn btn-primary w-100" @click="openModal">{{ t('orphan_table_operation_add')
                    }}
                    </button>
                </div>
                <div class="w-100 mx-1">
                    <button id="refreshTable" class="edit-buttons btn btn-primary w-100" @click="getData(event, true)">{{
                        t('orphan_table_operation_refresh') }}</button>
                </div>

            </div>
        </div>
        <br />
        <div class="row">
            <div class="col col-md-2">
                <select v-model="selected_sort_by_options" class="form-control" id="familyFilter" @change="getData">
                    <option v-for="option in sort_by_options" :value="option.value" :key="option.value">{{ t(option.label)
                    }}
                    </option>
                </select>

            </div>
            <div class="col col-md-2">
                <select v-model="selected_sort_column" class="form-control" id="familyFilter" @change="getData">
                    <option v-for="option in sort_column_options" :value="option.value" :key="option.value">{{
                        t(option.label) }}
                    </option>
                </select>
            </div>
        </div>
        <br />
        <div class="row">
        </div>
        <div class="table-responsive">
            <br />
            <h2 v-if="donators != undefined && donators.length == 0">Not Found</h2>

            <table v-if="donators != undefined && donators.length != 0" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th data-field="id" data-sortable="true">{{ t('donators_table_id') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('donators_table_name') }}</th>
                        <th data-field="email" data-sortable="true">{{ t('donators_table_email') }}</th>
                        <th data-field="phone" data-sortable="true">{{ t('donators_table_phone') }}</th>
                        <th style="width:3%" data-sortable="false">{{ t('donators_table_country') }}</th>
                        <th style="width:3%" data-sortable="false">{{ t('donators_table_address') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{
                            t('orphan_table_column_registration_date') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_update_date') }}
                        </th>
                        <th data-field="is_checked" data-sortable="true">{{ t('orphan_table_column_checked') }}</th>
                        <th data-field="actions" data-sortable="true">{{ t('orphan_table_column_actions') }}</th>

                    </tr>
                </thead>
                <tbody id="myTable" v-if="donators != undefined">
                    <tr v-for="donator in donators" :key="donator.id"
                        :class="donator.checked ? 'table-primary' : 'table-danger'">
                        <td>{{ donator.id }}</td>
                        <td>{{ donator.name }}</td>
                        <td>{{ donator.email }}</td>
                        <td>{{ donator.phone }}</td>
                        <td>{{ donator.address != undefined ? donator.address.country : '--' }}</td>
                        <td>{{ donator.address != undefined ? generateAddress(donator.address) : '--' }}</td>
                        <td>{{ generateDate(donator.created_at) }}</td>
                        <td>{{ generateDate(donator.updated_at) }}</td>
                        <td>{{ donator.checked ? t('checked') : t('not_checked') }} </td>
                        <td>
                            <button @click="openModal(event, true, donator)"
                                class="btn edit-button btn-sm btn-outline-primary"><i class="fa fa-pencil"></i>
                            </button>
                            <button class="btn btn-sm btn-outline-danger" @click="deleteDonator(donator)"><i
                                    class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-sm-1">
                <select v-model="selected_per_page" id="pageSize" class="form-control d-inline-block w-auto"
                    @change="perPageChange">
                    <option v-for="option in per_page_options" :value="option.value" :key="option.value">{{ (option.label)
                    }}
                    </option>
                </select>
            </div>
            <div class="col-sm">
                <div class="float-right">
                    <button class="btn btn-outline-secondary" :disabled="page.value == 0 || page.value == 1"
                        @click="changePage(false)">
                        <i class="fas fa-angle-left"></i> {{ t('orphan_prev_button') }}
                    </button>
                    <button class="btn btn-outline-secondary" :disabled="page.value + 1 > [page.count]"
                        @click="changePage(true)">
                        {{ t('orphan_next_button') }} <i class="fas fa-angle-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="float-right mr-3">
            <span>{{ t('orphan_table_footer_page') }} {{ page.value }} </span>
            <span>{{ t('orphan_table_footer_total') }} {{ page.count }}</span>
        </div>
    </div>
</template>
    
<script>
import BaseModal from "../BaseModal.vue";
import EditPublicDonators from "./PublicDonators/EditPublicDonators.vue";
import CreatePublicDonators from "./PublicDonators/CreatePublic.vue";
import { LoadingStore } from '@/stores/loading';
import { storeToRefs } from 'pinia';
import { NotificationStore } from '@/stores/notification';
import { DonatorStore } from '@/stores/donatorStore';
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import router from '@/routes/index.js'


export default {
    name: 'PrivateDonatorsTable',
    components: {
        BaseModal,
        CreatePublicDonators,
        EditPublicDonators
    },

    setup() {
        const loadingStore = LoadingStore();
        const notificationStore = NotificationStore();
        const donatorStore = DonatorStore();
        const { donators, page, states } = storeToRefs(donatorStore);

        const { is_loading } = storeToRefs(loadingStore);
        const isOpen = ref(false);
        const { t } = useI18n({});
        return {
            loadingStore,
            notificationStore,
            is_loading,
            isOpen, t,
            donatorStore,
            states,
            donators, page
        };

    },

    watch: {

        donators: {

            deep: true
        },

        page: {
            deep: true

        }

    },
    data() {
        return {
            selected_civility: null,
            sort_column_options: [
                { label: 'orphan_filter_created_at', value: 'created_at' },
                { label: 'orphan_filter_updated_at', value: 'updated_at' },
            ],
            selected_sort_column: 'created_at',
            selected_donator_type: null,
            sort_by_options: [
                { label: 'orphan_filter_order_by_desc', value: '3' },
                { label: 'orphan_filter_order_by_asc', value: '2' },
            ],
            donator_types_option: [
                { label: 'donator_all', value: null },
                { label: 'donator_regular', value: '0' },
                { label: 'donator_vip', value: '1' },
            ],
            per_page_options: [
                { label: '5', value: '5' },
                { label: '10', value: '10' },
                { label: '15', value: '15' },
            ],
            selected_per_page: 5,
            selected_family: null,
            selected_sort_by_options: '3',

            isModalOpen: false,
            civility: -1,
            country: '',
            editIsOpen: false,
            region: '',
            isDisabled: false,
            first_disable: true,
            clean_state: false,
            name_disable: false,
            last_name_disable: false,
            ngo_id_disable: false,
            timeout: undefined,
            search: '',

        };
    },

    async created() {
        let params = this.parseURLParamaters();
        this.page.value = params.page;
        this.selected_per_page = params.per_page;
        this.selected_sort_column = params.sort_column;
        this.selected_sort_by_options = params.sort_by;

        await this.getData(undefined, false);
    },

    methods: {
        openFileSelectDialog() {
            this.$refs.fileInput.click();
        },
        async handleFileSelect(event) {
            const file = event.target.files[0];
            if (file != undefined) {
                this.loadingStore.setLoading(true);
                let response = await this.donatorStore.upload(file, true);
                if (response.status)
                    this.notificationStore.popupMessage(this.t('csv_uploaded'), "", true, "bottom", true);
                else
                    this.notificationStore.popupMessage(this.t('csv_has_error'), "", true, "bottom", true);
                await this.getData(null, true);
                this.loadingStore.setLoading(false);
            }


        },
        parseURLParamaters() {
            const url_params = new URLSearchParams(window.location.search);
            let params = {
                page: url_params.get('page') ? url_params.get('page') : 1,
                per_page: url_params.get('per_page') ? url_params.get('per_page') : 5,
                sort_column: url_params.get('sort_column') ? url_params.get('sort_column') : 'created_at',
                sort_by: url_params.get('sort_by') ? url_params.get('sort_by') : '3'
            };
            return params;
        },

        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.search_fetch, 150);
        },

        search_fetch(page_number = 1) {
            if (this.search.length == 0)
                this.getData(true);
            else {
                this.loadingStore.setLoading(true);
                //        async search(key, page_number, per_page, sort_column, sort_by, is_public = false) {

                this.donatorStore.search(this.search, page_number,
                    this.selected_per_page, this.selected_sort_column,
                    this.selected_sort_by_option, true);
                this.loadingStore.setLoading(false);
            }
        },
        generateAddress(data) {
            let address = "";
            if (data.county)
                address += (address.length > 0 ? ', ' : '') + data.county;
            if (data.address)
                address += (address.length > 0 ? ', ' : '') + data.address;
            if (data.zipcode)
                address += (address.length > 0 ? ', ' : '') + data.zipcode;
            if (address.length == 0)
                address = '--';
            return address;
        },

        changePage(next) {
            this.page.value = next ? this.page.value + 1 : this.page.value - 1;
            if (this.search.length > 0)
                this.SearchOrphan(this.page);
            else
                this.getData();
        },
        fetch(status = false, is_update = false) {
            if (status) {
                let status = false;
                if (this.selected_sort_column == 'updated_at' && !is_update)
                    status = true;
                this.getData(undefined, status);
            }
        },
        deleteDonator(donator) {
            this.notificationStore.popupDialog(this.t('orphan_modal_delete_button'),
                this.t('orphan_modal_cancel_button'),
                this.t('donators_delete_title'), donator.name
                + " (" + donator.id + ") " + this.t('orphan_delete_text'), true,
                donator, 'center', this.deleteCall, undefined);
        },

        async deleteCall(donator) {
            this.loadingStore.setLoading(true);
            let response = await this.donatorStore.deleteDonators(donator.id, true);
            if (response.status) {
                if (this.page.number_of_data % this.page.per_page == 1 && this.page.value > 1)
                    this.page.value -= 1;
                await this.getData();
                let delete_text = donator.name + '(' + donator.id + ') ' + this.t('orphan_delete_success');
                this.notificationStore.popupMessage(delete_text, "", true, "bottom", true);
            }
            else {

                let delete_text = donator.name + '(' + donator.id + ') ' + this.t('orphan_delete_error');
                this.notificationStore.popupMessage(delete_text, "", false, "center", false);
            }
            this.loadingStore.setLoading(false);
        },
        perPageChange() {
            this.page.value = 1;
            this.getData();
        },
        generateDate(date, include_date = false) {
            let d = new Date(date);
            const options = { weekday: 'short', month: 'short', day: 'numeric' };
            if (include_date) {
                delete options.weekday;
                options.year = 'numeric';
            }
            const formattedDate = d.toLocaleDateString('en-US', options);
            return formattedDate;
        },
        async getData(event, is_refresh = false) {
            const newURL = `${window.location.pathname}?page=${this.page.value}&per_page=${this.selected_per_page}&sort_column=${this.selected_sort_column}&sort_by=${this.selected_sort_by_options}`;
            router.push(newURL);
            this.loadingStore.setLoading(true);

            if (is_refresh || this.page.value == 0) {
                this.page.value = 1;
            }

            if (this.search == "")
                await this.donatorStore.get(this.page.value, this.selected_per_page,
                    this.selected_civility, this.selected_sort_column,
                    this.selected_sort_by_options, this.selected_donator_type, true);
            else
                this.search_fetch(this.page.value);

            this.loadingStore.setLoading(false);

        },

        check_selected_family_exist() {
            for (var member of this.families.data) {
                if (member.id == this.selected_family)
                    return true;
            }
            return false;
        },
        async openModal(event, is_edit = false, data = undefined) {
            if (is_edit) {
                this.donatorStore.cleanStates();
                this.loadingStore.setLoading(true);
                let response = await this.donatorStore.getDonator(data.id, true);

                if (!response.status) {
                    let message = this.t('sponsorships_type_not_found');
                    let text = this.t('refresh_data');
                    this.notificationStore.popupMessage(message, text, false, 'center', false, this.close(), this.close());
                }
                else {
                    this.editIsOpen = true;

                }
                this.loadingStore.setLoading(false);
                this.editIsOpen = true;
            }
            else
                this.isOpen = true;
        },
        closeModal(is_edit = false) {
            if (is_edit)
                this.editIsOpen = false;
            else
                this.isOpen = false;
        },

        async check_fields(ngo_id, name, last_name) {
            if (ngo_id == undefined || ngo_id.length == 0)
                this.ngo_id_disable = true;
            else
                this.ngo_id_disable = false;
            if (name == undefined || name.length == 0)
                this.name_disable = true;
            else
                this.name_disable = false;
            if (last_name == undefined || last_name.length == 0)
                this.last_name_disable = true;
            else
                this.last_name_disable = false;
        },
        async check_is_exist(ngo_id) {
            if (ngo_id.length == 0) {
                this.first_disable = true;
            }
            else {
                let search = await this.orphanStore.searchOrphan(ngo_id);
                this.isDisabled = search.status;
                this.first_disable = false;
                console.log("SEARCH", search, this.isDisabled);
            }
        }
    }

}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 767px) {
    .right-element {
        margin-top: 10px;
    }

    .containers {
        width: 100%;
    }
}

.edit-button {
    margin-right: 2%;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}

.containers {
    width: 95%;
    margin-left: 2.5%;
}

label {
    float: left;
}
</style>
    