<template>
    <div class="create-user p-0">
        <div class="modal-header">
            <h4 class="modal-title">{{ t('create_user') }}</h4>
        </div>
        <div class="modal-body" style="margin-top: 1%;"></div>
        <form @submit.prevent="submitForm">

            <div class="form-group d-flex flex-column align-items-start">
                <label for="name">{{ t('name') }} *</label>
                <input v-model="name" type="text" id="name" required class="form-control" />
            </div>
            <div class="form-group d-flex flex-column align-items-start">
                <label for="email">{{ t('email') }} *</label>
                <input v-model="email" type="email" id="email" required class="form-control" />
            </div>
            <div class="form-group d-flex flex-column align-items-start">
                <label for="role">{{ t('role') }} *</label>
                <select v-if="roles" v-model="role" class="form-control mx-1" id="familyFilter" @change="getDonators">
                    <option v-for="role in roles.data" :value="role.id" :key="role.id">{{ t(role.name)
                    }}
                    </option>
                </select>
            </div>
            <div class="error-message">{{ createUserError }}</div>
            <div class="modal-footer">
                <button @click="createUser" type="submit" style="color: white" class="btn btn-primary add-button">
                    {{ t('create_user') }}
                </button>
                <button type="button" style="color: white" class="btn btn-danger" @click="close">
                    {{ t('cancel') }}
                </button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { UserStore } from '@/stores/userStore';
import { NotificationStore } from '@/stores/notification';
import { RoleStore } from '@/stores/roles';

export default {
    name: 'CreateUserModal',
    props: [],
    data() {
        return {
            role: undefined,
            name: '',
            email: '',

        };
    },
    async created() {
        this.clearModal();
        await this.roleStore.getRoles()
    },
    watch: {
        user_info: {
            deep: true
        }
    },
    methods: {
        clearModal() {
            this.createUserError = '';
            this.role = undefined;
            this.name = '';
            this.email = '';

        },
        close() {
            this.createUserError = '';
            this.role = undefined;
            this.name = '';
            this.email = '';
            this.$emit('close');
        },
        async createUser() {
            let data = {
                role_id: this.role,
                name: this.name,
                email: this.email,
            };
            let response = await this.userStore.createUser(data);
            if (response.status) {
                this.$emit('status', true);
                this.close();
            }
            let message = response.status ? this.t('user_added_alert') : this.t('user_not_added_alert');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        },
    },
    setup() {
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const languageStore = LanguageStore();
        const { t } = useI18n({});
        const notificationStore = NotificationStore()
        const userStore = UserStore();
        const roleStore = RoleStore();
        const { roles } = storeToRefs(roleStore);
        const { createUserError } = storeToRefs(userStore);
        return { t, is_loading, languageStore, loadingStore, createUserError, userStore, notificationStore, roles, roleStore };
    },
};
</script>
  
<style scoped>
.error-message {
    color: red;
    margin-bottom: 20px;
}
</style>
  