export default {
  "orphan_filter_family_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute la famille"])},
  "orphan_filter_civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilité"])},
  "orphan_filter_civility_male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monsieur"])},
  "orphan_filter_civility_female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madame"])},
  "orphan_filter_civility_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
  "orphan_filter_civility_undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non défini"])},
  "orphan_filter_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé le"])},
  "orphan_filter_updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis à jour le"])},
  "orphan_filter_order_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
  "orphan_filter_order_by_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desc"])},
  "orphan_filter_order_by_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asc"])},
  "orphan_table_operation_refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
  "orphan_table_operation_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "orphan_table_column_ngoid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ONG"])},
  "orphan_table_column_civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilité"])},
  "orphan_table_column_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "orphan_table_column_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "orphan_table_column_dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "orphan_table_column_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "orphan_table_column_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
  "orphan_table_column_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "orphan_table_footer_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
  "orphan_table_footer_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "orphan_family_not_found_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille sélectionnée introuvable"])},
  "orphan_not_added_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphelin déjà existant ou impossible à ajouter"])},
  "orphan_added_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphelin ajouté avec succès"])},
  "orphan_updated_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphelin mis à jour avec succès"])},
  "orphan_not_updated_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre à jour l'orphelin"])},
  "orphan_table_column_registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'inscription"])},
  "orphan_table_column_update_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de mise à jour"])},
  "orphan_table_column_checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut vérifié"])},
  "orphan_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer l'orphelin ?"])},
  "orphan_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sera supprimé, vous ne pourrez pas revenir en arrière"])},
  "orphan_modal_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "orphan_modal_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "orphan_delete_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé avec succès"])},
  "orphan_delete_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer"])},
  "orphan_next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "orphan_prev_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
  "orphan_name_field_can_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ du nom ne peut pas être vide."])},
  "orphan_last_name_field_can_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ du nom de famille ne peut pas être vide."])},
  "orphan_ngo_id_field_can_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ de l'ID de l'ONG ne peut pas être vide."])},
  "orphan_ngo_id_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ID de l'ONG fourni existe déjà."])},
  "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un pays"])},
  "orphan_family_add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une famille"])},
  "orphan_family_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la famille"])},
  "orphan_family_modal_name_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nom de la famille"])},
  "orphan_family_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer la famille orpheline ?"])},
  "select_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les devises"])},
  "treatment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de traitement"])},
  "no_treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de traitement"])},
  "Medical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médical"])},
  "Finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "Undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non défini"])},
  "orphan_modal_treatmnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de Traitement"])},
  "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifié"])},
  "not_checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non vérifié"])}
}