export default {
  "enrolled_sponsorships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrolled sponsorships"])},
  "sponosrship_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship price"])},
  "enrolled_orphan_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphan name"])},
  "enrolled_orphan_ngo_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphan NGO ID"])},
  "enrolled_donator_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donator"])},
  "enrolled_sponsorship_active_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active status"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "deactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated"])},
  "delete_enrollment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are yo usure you want to delete enrollment"])},
  "delete_enrollment_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship enrollment"])},
  "delete_enrollment_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to orphan"])},
  "delete_enrollment_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by the donator"])},
  "enrollment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollment deleted"])},
  "enrolment_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollment can not deleted"])},
  "select_sponsorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Sponsorship"])},
  "enable_force": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force Donation"])},
  "enable_force_enrollment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force Enrollment"])},
  "select_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Family"])},
  "add_enrollment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Enrollment"])},
  "update_enrollment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Enrollment"])},
  "enrollment_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship enrollment updated"])},
  "enrollment_not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship enrollment not updated"])},
  "select_activation_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Activation Type"])},
  "enrollment_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollment not found"])},
  "donated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donated successfully"])},
  "donated_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not donate"])},
  "delete_donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete donation"])},
  "delete_donate_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation"])},
  "donation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation deleted succesfully"])},
  "donation_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation not deleted"])},
  "result_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result not found"])}
}