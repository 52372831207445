import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const TaxReceiptStore = defineStore("taxReceiptStore", {
  state: () => ({
    selectedYear: null,
    fiscalYears: [],
    donator_type: -1,
    selected_donator_type: 0,
    donator_id: null,
    donator: null,
    donators: [],
  }),
  actions: {
    async getFiscalYears() {
      return await axios
        .get("/api/donations/fiscal-years")
        .then((response) => {
          const data = response.data;
          this.fiscalYears = data;
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async getDonators(is_public = false) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      let url = is_public ? "api/public/donators" : "api/donators";
      return axios
        .get(url)
        .then((response) => {
          this.donators = response.data.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Donators Not Found!" };
        });
    },

    selectDonator(id) {
      this.donator = this.donators.find((donator) => donator.id === id);
    },

    selectYear(year) {
      this.selectedYear = year;
    },
    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
