import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";

axios.defaults.baseURL = base_url;

export const LanguageStore = defineStore('languageStore', {
    state: () => ({
        selected_language: "fr"
    }),

    getters: {
        getLanguage: (state) => state.selected_language,
    },

    actions: {
        async getLangauge() {
            if (localStorage.getItem('language') == null) {
                await this.setLanguage(this.selected_language);
            }
            return localStorage.getItem("language");
        },
        async setLanguage(language) {
            this.selected_language = language;
            localStorage.setItem("language", language);
        },
    }
});
