<template>
    <div class="create-user p-0">
        <div class="modal-header">
            <h4 class="modal-title" v-if="!is_edit">{{ t('create_role') }}</h4>
            <h4 class="modal-title" v-else-if="is_edit">{{ t('update_role') }}</h4>
        </div>

        <div class="modal-body" style="margin-top: 1%;"></div>
        <form @submit.prevent="submitForm">

            <div class="form-group d-flex flex-column align-items-start">
                <label for="name">{{ t('role_name') }} *</label>
                <input v-model="name" type="text" id="name" required class="form-control" />
            </div>
            <div class="d-flex flex-row justify-content-between mt-4">
                <h6 class="form-group d-flex flex-column align-items-start">{{ t('select_permissions') }}</h6>
                <div>
                    <input class="form-check-input" type="checkbox" :id=checkedall @click="chooseAll = !chooseAll"
                        value="chooseAll" v-model="chooseAll">
                    <label class="form-check-label text-danger" :for=checkedall>
                        {{ t('select_all') }}
                    </label>
                </div>

            </div>
            <div v-if="permissions != undefined && permissions.length > 0" class="d-flex flex-wrap justify-content-start">
                <div class="form-check m-1 justify-content-start d-flex" style="width:270px"
                    v-for="permission in permissions" :key="permission.id">
                    <input class="form-check-input" type="checkbox" :id=permission.id @click="handleCheck(permission.id)"
                        :checked="isChecked(permission.id)">
                    <label class="form-check-label" :for=permission.id>
                        {{ permission.name }}
                    </label>
                </div>
            </div>

            <div class="error-message">{{ createRoleError }}</div>
            <div class="modal-footer">
                <button @click="createRole" type="submit" style="color: white" class="btn btn-primary add-button"
                    v-if="!is_edit">
                    {{ t('create_role') }}
                </button>
                <button @click="updateRole" type="submit" style="color: white" class="btn btn-primary add-button"
                    v-if="is_edit">
                    {{ t('update_role') }}
                </button>
                <button type="button" style="color: white" class="btn btn-danger" @click="close">
                    {{ t('cancel') }}
                </button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { RoleStore } from '@/stores/roles';
import { NotificationStore } from '@/stores/notification';
import { PermissionStore } from "@/stores/permissions";

export default {
    name: 'CreateRoleModal',
    props: ['is_edit'],
    data() {
        return {
            name: '',
            checkedPermissions: [],
            chooseAll: false,

        };
    },
    async created() {
        this.clearModal();
        this.permissionStore.get()
    },
    watch: {
        user_info: {
            deep: true
        },
        permission: {
            deep: true
        },
        checkedPermissions: {
            deep: true
        },
        chooseAll: {
            deep: true,
            handler(newv) {
                if (newv) {
                    let allIds = this.permissions.map((permission) => permission.id)
                    this.checkedPermissions = allIds
                }
                else {
                    this.checkedPermissions = []
                }
            }
        },
        is_edit: {
            handler() {
                if (this.is_edit) {
                    this.handleGetRole(this.is_edit)
                }
            },
            deep: true
        }
    },
    methods: {
        clearModal() {
            this.createRoleError = '';
            this.name = '';
            this.chooseAll = false;
            this.checkedPermissions = [];
        },
        close() {
            this.createRoleError = '';
            this.name = '';
            this.chooseAll = false;
            this.checkedPermissions = [];
            this.$emit('close');
        },

        async handleGetRole(id) {
            let response = await this.roleStore.getRole(id)
            if (response.status === true) {
                this.name = response.data.name
                let permissionsArray = response.data.permissions.map((permission) => permission.id)
                this.checkedPermissions = permissionsArray
            }
        },
        handleCheck(id) {
            const index = this.checkedPermissions.indexOf(id);
            if (index !== -1) {
                this.checkedPermissions.splice(index, 1);
            } else {
                this.checkedPermissions.push(id);
            }
        },
        isChecked(permissionId) {
            return this.checkedPermissions.includes(permissionId);
        },

        async createRole() {
            let data = {
                name: this.name,
                permissions: this.checkedPermissions
            };
            let response = await this.roleStore.createRole(data);
            if (response.status) {
                this.$emit('status', true);
                this.close();
            }
            let message = response.status ? this.t('role_created_alert') : this.t('role_not_created_alert');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        },

        async updateRole() {
            let data = {
                name: this.name,
                permissions: this.checkedPermissions
            };
            let response = await this.roleStore.updateRole(data, this.is_edit);
            if (response.status) {
                this.$emit('status', true);
                this.close();
            }
            let message = response.status ? this.t('role_updated_alert') : this.t('role_not_updated_alert');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        }
    },
    setup() {
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const languageStore = LanguageStore();
        const { t } = useI18n({});
        const notificationStore = NotificationStore()
        const roleStore = RoleStore();
        const permissionStore = PermissionStore()
        const { permissions } = storeToRefs(permissionStore)
        const { createRoleError } = storeToRefs(roleStore);
        return { t, is_loading, languageStore, loadingStore, createRoleError, roleStore, notificationStore, permissionStore, permissions };
    },
};
</script>
  
<style scoped>
.error-message {
    color: red;
    margin-bottom: 20px;
}
</style>
  