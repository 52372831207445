
<template>
    <template v-if="isvisible">
        <div class="containers">
            <div class="row flex-nowrap w-100">

                <NavBar> </NavBar>
                <div class="col py-3 main-page">
                    <HeaderBar></HeaderBar>
                    <router-view></router-view>

                </div>
            </div>
        </div>
    </template>
</template>
  
<script>
require('../bootstrap')
import { UserStore } from '@/stores/userStore';
import { LoadingStore } from '@/stores/loading';
import NavBar from '@/components/NavBar.vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import HeaderBar from '@/components/HeaderBar.vue';

import { useI18n } from 'vue-i18n';
export default {
    name: 'HomePage',
    components: {
        NavBar,
        HeaderBar

    },
    setup() {
        const loadingStore = LoadingStore();
        const userStore = UserStore();
        const { t } = useI18n({});
        return {
            loadingStore,
            userStore, t
        };

    },

    data: () => ({
        isvisible: false,
        user_info: {},
    }),
    async created() {
        this.loadingStore.setLoading(true);
        this.isvisible = await this.userStore.userIsValid();
        this.loadingStore.setLoading(false);

        if (!this.isvisible)
            window.location.replace("/login");
        //TO DO : ADD DIALOG
        console.log("IAM", this.t('session_expired'),);
    }

}




</script>
  
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.containers {
    width: 100%;
}

.main-page {
    width: 80% !important;
}
</style>
  