<template>
    <BaseModal :open="isOpen" @close="isOpen = !isOpen">
        <CreateEnrollment v-if="isOpen" @status="fetch" @close="close" :selected_enrollment="selectedEnrollment">
        </CreateEnrollment>
    </BaseModal>
    <div class="m-3 py-2">
        <div class="row justify-content-between">
            <div class="col-md-5">
                <select v-model="states.donator_id" class="form-control" id="familyFilter" @change="getData">
                    <option value="">{{ t('select_donator') }}</option>
                    <option v-for="option in donators" :value="option.id" :key="option.name">{{ option.name + " " +
                        option.last_name
                    }} - {{ option.id }}
                    </option>
                </select>
            </div>
            <div class="col col-md-2 d-flex flex-row justify-content-between">
                <div class="w-100 mx-1">
                    <button class="edit-buttons btn btn-primary w-100" @click="openModal">{{ t('orphan_table_operation_add')
                    }}
                    </button>
                </div>
                <div class="w-100 mx-1">
                    <button id="refreshTable" class="edit-buttons btn btn-primary w-100" @click="getData(event, true)">{{
                        t('orphan_table_operation_refresh') }}</button>
                </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col col-md-2">
                <select v-model="selected_sort_by_options" class="form-control" id="familyFilter" @change="getData">
                    <option v-for="option in sort_by_options" :value="option.value" :key="option.value">{{ t(option.label)
                    }}
                    </option>
                </select>
            </div>
            <div class="col col-md-2">
                <select v-model="selected_sort_column" class="form-control" id="familyFilter" @change="getData">
                    <option v-for="option in sort_column_options" :value="option.value" :key="option.value">{{
                        t(option.label) }}
                    </option>
                </select>
            </div>
            <div class="col col-md-2">
                <select v-model="states.sponsorship_id" class="form-control" id="familyFilter" @change="getData">
                    <option value="">{{ t('select_sponsorship') }}</option>
                    <option v-for="option in sponsorships" :value="option.id" :key="option.name">{{ option.name
                    }}
                    </option>
                </select>
            </div>
            <div class="col col-md-2">
                <select class="form-control" id="active" @change="getData" v-model="states.is_active">
                    <option :value=-1>{{ t("select_activation_status") }}</option>
                    <option :value=1>{{ t("active") }}</option>
                    <option :value=0>{{ t("deactive") }}</option>
                </select>
            </div>
        </div>
        <br />
        <div class="table-responsive">
            <h2 v-if="enrolled_sponsorships != undefined && enrolled_sponsorships.length == 0">Not Found</h2>
            <div class=" float-right">
                <div class="float-right">
                    <div class="form-check form-switch" style="width:265px;">
                        <label class="form-check-label" for="flexSwitchCheckDefault">{{ t("enable_force") }}</label>
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                            v-model="states.enable_force">
                    </div>
                </div>
            </div>
            <table v-if="enrolled_sponsorships != undefined && enrolled_sponsorships.length > 0"
                class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th data-field="id" data-sortable="true">{{ t('sponsorships_id') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('enrolled_sponsorships') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('enrolled_orphan_ngo_id') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('enrolled_orphan_name') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('enrolled_donator_name') }}</th>

                        <th width="12%" data-field="name" data-sortable="true">{{ t('amount') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('enrolled_sponsorship_active_status') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{
                            t('orphan_table_column_registration_date') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_update_date') }}
                        </th>
                        <th data-field="is_checked" data-sortable="true">{{ t('orphan_table_column_checked') }}</th>
                        <th width="12%" data-field="actions" data-sortable="true">{{
                            t('orphan_table_column_actions') }}</th>
                    </tr>
                </thead>
                <tbody id="myTable" v-if="enrolled_sponsorships != undefined">
                    <tr v-for="sponsorship in enrolled_sponsorships " :key="sponsorship.id"
                        :class="sponsorship.checked ? 'table-primary' : 'table-danger'">
                        <td>{{ sponsorship.id }}</td>
                        <td>{{ sponsorship.sponsorships.name }} ({{ sponsorship.sponsorships.id }})</td>
                        <td>{{ sponsorship.orphan.ngo_id }}</td>
                        <td>{{ sponsorship.orphan.name }} {{ sponsorship.is_family ? (t("family") +
                            sponsorship.orphan.last_name) : ""
                        }} </td>
                        <td>{{ sponsorship.donator.name + " " + sponsorship.donator.last_name }} ({{ sponsorship.donator.id
                        }})</td>
                        <td>{{ sponsorship.sponsorships.currency.symbol + " " + sponsorship.sponsorships.amount }}</td>

                        <td>{{ sponsorship.is_active ? t("active") : t("deactive") }}</td>

                        <td>{{ generateDate(sponsorship.created_at) }}</td>
                        <td>{{ generateDate(sponsorship.updated_at) }}</td>
                        <td>{{ sponsorship.checked ? t('checked') : t('not_checked') }} </td>
                        <td>
                            <button :disabled="!sponsorship.enable_monthly_pay && !states.enable_force"
                                class="btn btn-sm btn-outline-warning" @click="sendMail(sponsorship)"><i
                                    class="fa fa-envelope"></i>
                            </button>
                            <button :disabled="!sponsorship.enable_monthly_pay && !states.enable_force"
                                class="btn btn-sm btn-outline-success" @click="Donate(sponsorship)"><i
                                    class="fa fa-money"></i>
                            </button>
                            <button @click="openModal(event, true, sponsorship)"
                                class="btn edit-button btn-sm btn-outline-primary"><i class="fa fa-pencil"></i> </button>
                            <button class="btn btn-sm btn-outline-danger" @click="deleteDonate(sponsorship)"><i
                                    class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-sm-1">
                <select v-model="selected_per_page" id="pageSize" class="form-control d-inline-block w-auto"
                    @change="perPageChange">
                    <option v-for="option in per_page_options" :value="option.value" :key="option.value">{{ (option.label)
                    }}
                    </option>
                </select>
            </div>
            <div class="col-sm">
                <div class="float-right">
                    <button class="btn btn-outline-secondary" :disabled="page.value == 1" @click="changePage(false)">
                        <i class="fas fa-angle-left"></i> {{ t('orphan_prev_button') }}
                    </button>
                    <button class="btn btn-outline-secondary" :disabled="page.value + 1 > [page.count]"
                        @click="changePage(true)">
                        {{ t('orphan_next_button') }} <i class="fas fa-angle-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="float-right mr-3">
            <span>{{ t('orphan_table_footer_page') }} {{ page.value }} </span>
            <span>{{ t('orphan_table_footer_total') }} {{ page.count }}</span>
        </div>
    </div>
</template>
    
<script>
import BaseModal from "../BaseModal.vue";
import { LoadingStore } from '@/stores/loading';
import { storeToRefs } from 'pinia';
import { NotificationStore } from '@/stores/notification';
import { EnrolledSponsorshipsStore } from "@/stores/enrolledSponsorshipsStore";
import CreateEnrollment from './Modals/CreateEnrollment.vue'
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import router from '@/routes/index.js'
import { UserStore } from "@/stores/userStore";

export default {
    name: 'EnrolledSponsorships',
    components: {
        BaseModal,
        CreateEnrollment,
    },

    setup() {
        const loadingStore = LoadingStore();
        const notificationStore = NotificationStore();
        const { is_loading } = storeToRefs(loadingStore);
        const enrolledSponsorshipsStore = EnrolledSponsorshipsStore();
        const userStore = UserStore()
        const { page, states, sponsorships, enrolled_sponsorships, orphans, donators } = storeToRefs(enrolledSponsorshipsStore);

        const isOpen = ref(false);
        const { t } = useI18n({});
        return {
            loadingStore,
            userStore,
            notificationStore,
            enrolledSponsorshipsStore,
            states,
            page,
            sponsorships,
            enrolled_sponsorships,
            orphans,
            donators,
            is_loading,
            isOpen, t
        };
    },

    watch: {
        campaign_types: {
            deep: true
        },
        currencies: {
            handler(newv) {
                console.log("NEW", newv);
            },
            deep: true
        },
        states: {
            deep: true
        },
        donates: {
            deep: true,
        },
        page: {
            deep: true
        },
        donators: {
            deep: true
        },
        campaigns: {
            deep: true,
        }
    },
    data() {
        return {
            sort_column_options: [
                { label: 'orphan_filter_created_at', value: 'created_at' },
                { label: 'orphan_filter_updated_at', value: 'updated_at' },
            ],
            selected_sort_column: 'created_at',

            sort_by_options: [
                { label: 'orphan_filter_order_by_desc', value: '3' },
                { label: 'orphan_filter_order_by_asc', value: '2' },
            ],
            donator_type: [
                { label: 'all_donators', value: '-1' },
                { label: 'public_donators', value: '2' },
                { label: 'private_donators', value: '0' },

            ],
            per_page_options: [
                { label: '5', value: '5' },
                { label: '10', value: '10' },
                { label: '15', value: '15' },
            ],
            selected_per_page: 5,
            selected_family: null,
            selected_sort_by_options: '3',
            isModalOpen: false,
            isDisabled: false,
            first_disable: true,
            clean_state: false,
            name_disable: false,
            last_name_disable: false,
            ngo_id_disable: false,
            timeout: undefined,
            search: '',
            selectedEnrollment: undefined

        };
    },

    async created() {
        let params = this.parseURLParamaters();
        this.page.value = params.page;
        this.selected_per_page = params.per_page;
        this.selected_sort_column = params.sort_column;
        this.selected_sort_by_options = params.sort_by;
        this.states.is_family = params.is_family;
        this.states.is_active = params.is_active;
        this.states.orphan_id = params.orphan_id;
        this.states.donator_id = params.donator_id;
        this.states.sponsorship_id = params.sponsorship_id;
        await this.getPreData();
    },


    methods: {
        handleEditEnrollment(enrollment) {
            this.selectedEnrollment = enrollment
            this.isOpen = true
        },

        sendMail() {
            alert("TODO : Send Mail");
            // TODO : you need to implement send mail notification on laravel backend and your store
        },
        async Donate(data) {
            console.log("MYDATA = ", data);
            this.loadingStore.setLoading(true);
            let response = await this.enrolledSponsorshipsStore.donate({
                enrollment_id: data["id"],
                currency: data["sponsorships"]["currency"]["id"],
                amount: data["sponsorships"]["amount"]
            });
            if (response.status) {
                await this.getData();
                let donate_text = data.id + " " + this.t('donated_successfully');
                this.notificationStore.popupMessage(donate_text, "", true, "bottom", true);
                // this.userStore.getNotifications()
            }
            else {
                let donate_text = data.id + " " + this.t('donated_failure');
                this.notificationStore.popupMessage(donate_text, this.t('refresh_data'), false, "center", false);
            }
            this.loadingStore.setLoading(false);
        },
        parseURLParamaters() {
            const url_params = new URLSearchParams(window.location.search);
            let params = {
                page: url_params.get('page') ? url_params.get('page') : 1,
                per_page: url_params.get('per_page') ? url_params.get('per_page') : 5,
                sort_column: url_params.get('sort_column') ? url_params.get('sort_column') : 'created_at',
                sort_by: url_params.get('sort_by') ? url_params.get('sort_by') : '3',
                is_active: url_params.get('is_active') ? url_params.get('is_active') : -1,
                is_family: url_params.get('is_family') ? url_params.get('is_family') : -1,
                orphan_id: url_params.get('orphan_id') ? url_params.get('orphan_id') : "",
                donator_id: url_params.get('donator_id') ? url_params.get('donator_id') : "",
                sponsorship_id: url_params.get('sponsorship_id') ? url_params.get('sponsorship_id') : "",
            };
            return params;
        },

        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.search_fetch, 150);
        },

        perPageChange() {
            this.page.value = 1;
            this.getData(undefined, true);
        },
        fetch(status = false, is_update = false) {
            if (status) {
                let status = false;
                if (this.selected_sort_by_options == 3 && !is_update)
                    status = true;
                this.getData(undefined, status);

            }
        },

        async search_fetch(page_number = 1) {
            if (page_number == 0)
                page_number = 1;
            if (this.search.length == 0) {
                this.getData(undefined, true);
            }
            else {
                this.loadingStore.setLoading(true);
                await this.enrolledSponsorshipsStore.search_fetch(this.search, 1, page_number, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
                this.loadingStore.setLoading(false);
            }
        },
        async openModal(event, is_edit = false, data = undefined) {
            if (is_edit) {
                this.loadingStore.setLoading(true);
                let response = await this.enrolledSponsorshipsStore.getEnrollment(data.id);
                if (!response.status) {
                    let message = this.t('campaign_not_found');
                    let text = this.t('refresh_data');
                    this.notificationStore.popupMessage(message, text, false, 'center', false, this.close(), this.close());
                }
                else {
                    this.selectedEnrollment = data
                    this.isOpen = true

                }
                this.loadingStore.setLoading(false);

            }
            else
                this.isOpen = true;
        },
        // async openModal() {
        //     this.isOpen = true;
        // },

        close() {
            this.isOpen = false;
            this.selectedEnrollment = undefined
            this.enrolledSponsorshipsStore.cleanStates();
        },

        async changePage(next) {
            this.page.value = next ? this.page.value + 1 : this.page.value - 1;
            await this.getData();
        },

        async getPreData() {
            // TODO : CALL CAMPAIGN DONATES STORE
            this.loadingStore.setLoading(true);
            await this.enrolledSponsorshipsStore.getDonators();
            await this.enrolledSponsorshipsStore.getOrphans();
            await this.enrolledSponsorshipsStore.getSponsorships();
            await this.getData();
            this.loadingStore.setLoading(false);
        },

        async getData(event, is_refresh = false) {
            if (this.page.value == 0)
                this.page.value = 1;

            const newURL = `${window.location.pathname}?page=${this.page.value}&per_page=${this.selected_per_page}&sort_column=${this.selected_sort_column}&sort_by=${this.selected_sort_by_options}&donator_id=${this.states.donator_id}&sponsorship_id=${this.states.sponsorship_id}&is_active=${this.states.is_active}`;
            router.push(newURL);
            if (is_refresh) {
                this.page.value = 1;
            }
            this.loadingStore.setLoading(true);
            if (this.search == "")
                await this.enrolledSponsorshipsStore.get(this.page.value, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options)
            else
                this.search_fetch(this.page.value);

            this.loadingStore.setLoading(false);
        },

        async deleteDonate(data) {
            console.log("IAM", data.id);
            let text = this.t('delete_enrollment_text_1') + " " + data.sponsorships.name + "(" + data.id + ") "
                + this.t('delete_enrollment_text_2') + " " + data.orphan.name + "(" + data.orphan.id + ")"
                + " " + this.t('delete_enrollment_text_3') + " "
                + data.donator.name + "(" + data.donator.id + ")";
            text += " " + this.t('orphan_delete_text');
            this.notificationStore.popupDialog(this.t('orphan_modal_delete_button'), this.t('orphan_modal_cancel_button'),
                this.t('delete_enrollment_title') + " " + data.id + " ?",
                text,
                true, data, 'center', this.deleteCall, undefined);

        },

        async deleteCall(data) {
            this.loadingStore.setLoading(true);
            let response = await this.enrolledSponsorshipsStore.destroy(data.id);
            if (response.status) {
                if (this.page.number_of_data % this.page.per_page == 1 && this.page.value > 1)
                    this.page.value -= 1;
                await this.getData();
                let delete_text = data.id + " " + this.t('enrollment_deleted');
                this.notificationStore.popupMessage(delete_text, "", true, "bottom", true);
            }
            else {
                let delete_text = data.id + " " + this.t('enrolment_not_deleted');
                this.notificationStore.popupMessage(delete_text, this.t('refresh_data'), false, "center", false);
            }
            this.loadingStore.setLoading(false);
        },

        generateDate(date, include_date = false) {
            let d = new Date(date);
            const options = { weekday: 'short', month: 'short', day: 'numeric' };
            if (include_date) {
                delete options.weekday;
                options.year = 'numeric';
            }
            const formattedDate = d.toLocaleDateString('en-US', options);
            return formattedDate;
        },
    },

}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 767px) {
    .right-element {
        margin-top: 10px;
    }

    .containers {
        width: 100%;
    }
}

.edit-button {
    margin-right: 2%;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}

td>button {
    margin-left: 2%;
}

button:disabled {
    background: #cccccc;
    color: black;
    border-color: black;
}

.containers {
    width: 95%;
    margin-left: 2.5%;
}

label {
    float: left;
}
</style>
    