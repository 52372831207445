<template>
    <div class="change-password p-0">
        <div class="modal-header">
            <h4 class="modal-title">{{ t('change_password') }}</h4>
        </div>
        <div class="modal-body" style="margin-top: 1%;"></div>
        <form @submit.prevent="submitForm">
            <div class="form-group d-flex flex-column align-items-start">
                <label for="oldPassword">{{ t('current_password') }} *</label>
                <input v-model="oldPassword" type="password" id="oldPassword" required class="form-control" />
            </div>
            <div class="form-group d-flex flex-column  align-items-start">
                <label for="password">{{ t('new_password') }} *</label>
                <input v-model="password" type="password" id="password" required class="form-control" />
            </div>
            <div class="form-group d-flex flex-column  align-items-start">
                <label for="confirmationPassword">{{ t('confirm_password') }} *</label>
                <input v-model="confirmPassword" type="password" id="confirmationPassword" required class="form-control" />
            </div>
            <div class="error-message">{{ changePasswordError }}</div>
            <div class="modal-footer">
                <button @click="changePassword" type="submit" style="color: white" class="btn btn-primary add-button">
                    {{ t('change_password') }}
                </button>

                <button type="button" style="color: white" class="btn btn-danger" @click="close">
                    {{ t('orphan_modal_cancel_button') }}

                </button>
            </div>

        </form>

    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { UserStore } from '@/stores/userStore';
import { NotificationStore } from '@/stores/notification';

export default {
    name: 'ChangePasswordModal',
    props: ['is_login', 'email'],
    data() {
        return {

            oldPassword: "",
            password: '',
            confirmPassword: '',

        };
    },

    async created() {
        this.clearModal()
    },
    methods: {
        clearModal() {
            this.changePasswordError = ''
            this.oldPassword = ""
            this.password = ""
            this.confirmPassword = ""
            console.log('clearing')
        },
        close() {
            this.changePasswordError = ''
            this.oldPassword = ""
            this.password = ""
            this.confirmPassword = ""
            this.$emit('close');

        },
        async changePassword() {

            let data = {
                "old_password": this.oldPassword,
                "password": this.password,
                "confirmation_password": this.confirmPassword
            }
            if (this.is_login) {
                data['email'] = this.email
            }
            let response = await this.userStore.changePassword(data);
            if (response.status) {
                this.$emit('status', true);
                this.close();
            }
            let message = response.status ? this.t('password_change_success') : this.t('password_change_fail');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        }

    },
    setup() {
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const languageStore = LanguageStore();
        const { t } = useI18n({});
        const notificationStore = NotificationStore()
        const userStore = UserStore();
        const { user_info, changePasswordError } = storeToRefs(userStore);
        return { t, is_loading, languageStore, loadingStore, user_info, changePasswordError, userStore, notificationStore };
    },
}
</script>
  
<style scoped>
.error-message {
    color: red;
    margin-bottom: 20px;
}
</style>