import { createApp } from "vue";
import App from "./Pages/App.vue";
import router from "./routes/index";
import { createPinia } from "pinia";
import vueCountryRegionSelect from "vue3-ts-country-region-select";
import { createI18n } from "vue-i18n";
import HomePageEN from "./locales/en/HomePage.json";
import HomePageFR from "./locales/fr/HomePage.json";

import NavbarEN from "./locales/en/NavbarEN.json";
import NavbarFR from "./locales/fr/NavbarFR.json";
import AuthEN from "./locales/en/AuthEN.json";
import AuthFR from "./locales/fr/AuthFR.json";
import OrphanEN from "./locales/en/OrphanEN.json";
import OrphanFR from "./locales/fr/OrphanFR.json";
import OrphanModalEN from "./locales/en/Modals/OrphanModalEN.json";
import OrphanModalFR from "./locales/fr/Modals/OrphanModalFR.json";
import SponsorshipsFR from "./locales/fr/SponsorshipsFR.json";
import SponsorshipsEN from "./locales/en/SponsorshipsEN.json";
import DonatorsEN from "./locales/en/DonatorsEN.json";
import DonatorsFR from "./locales/fr/DonatorsFR.json";
import CampaignsEN from "./locales/en/CampaignsEN.json";
import CampaignsFR from "./locales/fr/CampaignsFR.json";
import EnrolledSponsorshipsEN from "./locales/en/EnrolledSponsorshipsEN.json";
import EnrolledSponsorshipsFR from "./locales/fr/EnrolledSponsorshipsFR.json";
import AdminEN from "./locales/en/AdminEN.json";
import AdminFR from "./locales/fr/AdminFR.json";
import EmailsEN from "./locales/en/EmailsEN.json";
import EmailsFR from "./locales/fr/EmailsFR.json";

const translate = createI18n({
  legacy: false, // Set this to true if you are using v8.x
  locale: "en",
  messages: {
    en: {
      ...EmailsEN,
      ...AdminEN,
      ...HomePageEN,
      ...NavbarEN,
      ...AuthEN,
      ...OrphanEN,
      ...OrphanModalEN,
      ...SponsorshipsEN,
      ...DonatorsEN,
      ...CampaignsEN,
      ...EnrolledSponsorshipsEN,
    },
    fr: {
      ...EmailsFR,
      ...AdminFR,
      ...HomePageFR,
      ...NavbarFR,
      ...AuthFR,
      ...OrphanFR,
      ...OrphanModalFR,
      ...SponsorshipsFR,
      ...DonatorsFR,
      ...CampaignsFR,
      ...EnrolledSponsorshipsFR,
    },
  },
});

const app = createApp(App).use(translate);
const pinia = createPinia();

app.use(router);
app.use(pinia);
app.use(vueCountryRegionSelect);

app.mount("#app");
