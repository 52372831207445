

<template>
    <div class="d-flex justify-content-start align-items-center p-3">
        <div class="d-flex flex-row justify-content-evenly w-100">
            <select v-model="selected_donator_type" class="form-control mx-1" id="familyFilter" @change="updateSelection">
                <option v-for="option in donator_type" :value="option.value" :key="option.value">{{ t(option.label)
                }}
                </option>
            </select>
            <select :disabled="selected_donator_type == -1" v-model="donator_id" class="form-control mx-1" id="familyFilter"
                @change="updateDonator">
                <option :value=null>{{ t('select_donator') }}</option>
                <option v-for="option in donators" :value="option.id" :key="option.name">{{ option.name
                }}
                </option>
            </select>
            <select class="form-control mx-1" id="fiscal-year" v-model="selectedYear" :disabled="enter_total">
                <option :value=null>{{ t('select_fiscal_year') }}</option>
                <option v-for="option in fiscalYears" :value="option" :key="'family' + option"> {{
                    option }} </option>
            </select>
        </div>

        <button class="edit-buttons btn btn-primary w-25 ml-5" @click="getTaxReceiptInfo"
            :disabled="(selected_donator_type == -1 || donator_id == null || (!enter_total && selectedYear == null))">{{
                t('create-tax-receipt') }}
        </button>
        <button class="edit-buttons btn btn-primary w-25 ml-5" @click="generatePDF" :disabled="!showTaxReceipt">{{
            t('generate-pdf') }}
        </button>
    </div>
    <div class="d-flex flex-row justify-content-start w-100 px-4">

        <div class="form-check form-check-inline">
            <input class="form-check-input mt-1" @click="enter_total = !enter_total" v-model="enter_total" type="checkbox"
                :checked="enter_total" name="inlineRadioOptions" id="inlineRadio1" value=0>
            <label class="form-check-label" for="inlineRadio1">{{ t('enter_total') }}</label>

        </div>
        <input type="number" placeholder="Total" v-model="totalDonations" class="form-control w-25 mx-4" v-if="enter_total"
            id="totalInput" aria-describedby="totalInput" />
    </div>
    <div class="p-5" ref="content" v-if="showTaxReceipt" id="content">
        <img src="../../assets/Logo.jpg" class="d-block" style="height: 100px; width:150px; padding:0px;" ref="image" />
        <br />
        <div class="d-flex flex-column align-items-end">

            <div class="d-flex flex-column align-items-start">

                <h6 style="font-size: 10px;" class="">{{ this.donator && `${this.donator.civility == 0 ? 'Monsieur' :
                    this.donator.civility == 1 ? 'Madame' : ''} ${this.donator.name} ${this.donator.last_name
                        ?
                        this.donator.last_name
                        : ""}`
                }}
                </h6>
                <h6 style="font-size: 10px;" class="text-capitalize">{{ this.donator?.address.address }}</h6>
                <h6 style="font-size: 10px;" class="text-capitalize">{{ this.donator?.address.county }} {{
                    this.donator?.address.zipcode ? this.donator.address.zipcode : ""
                }}</h6>

                <h6 style="font-size: 10px;" class="text-capitalize">{{ this.donator?.address.country ?
                    this.countries.find((country) => country.alpha2Code ==
                        this.donator.address.country?.toUpperCase())?.name
                    : "" }}</h6>
                <p style="font-size: 10px;">Grand-Saconnex, {{ this.formatted_date }}</p>
            </div>
        </div>
        <br />
        <p class="mt-3 text-center " style="font-size: 15px; text-decoration-line:underline; font-weight: bold;">
            ATTESTATION DE DONS {{ `${this.selectedYear}` }}</p>
        <br />
        <div>
            <p style="font-size: 10px;">{{ "Nous, soussignés," }}</p>
            <p style="font-size: 10px; text-align: start;">
                Attestons que cette institution, personne morale ayant son siège en Suisse et étant exonérée
                de l’impôt en raison de ses buts de service public ou de pure utilité publique, a reçu:
            </p>
            <p style="font-size: 10px; text-decoration-line:underline; font-weight: bold; " class=" text-center ">{{ `La
                somme de ${this.totalDonations} CHF

                ` }}</p>

            <p style="font-size: 10px;">
                Nous certifions que le-s dons ci-dessus est-sont acquis à l’institution susmentionnée à titre
                définitif et ne sera-ont en aucun cas restitué-s au donateur sous quelque forme que ce soit;
                en particulier, que celui-ci ne reçoit aucune contre-prestation de la part de l’institution.
            </p>
        </div>
        <br />
        <div class="d-flex flex-column align-items-end mt-5">
            <p style="font-size: 10px;" class="text-start">Association Swiss-Group</p>
            <img src="../../assets/signature.png" class=" d-block" style="height: 100px; width: 200px; padding:0px;" />
        </div>
        <br />
        <br />
        <br />
        <div class="mt-5">
            <p class="mt-3 text-center mb-0" style="font-size: 8px; font-weight: bold;">Association Swiss-Group</p>
            <p style="font-size: 8px;" class="mb-0 text-center">
                Case postale 36-CH–1218 Le Grand-Saconnex
            </p>
            <p style="font-size: 8px;" class="mb-0 text-center">
                Banque Alternative Suisse – Account N° 46-110-7 - IBAN: CH10 0839 0034 6485 1000 6
            </p>
        </div>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { TaxReceiptStore } from "@/stores/taxReceiptStore";
import axios from 'axios';
import { ref } from 'vue'
import html2pdf from "html2pdf.js"
import { CountriesStore } from '@/stores/countries';


export default {
    name: 'TaxReceiptPage',

    data() {
        return {
            donator_type: [
                { label: 'donator_type_selection', value: '-1' },
                { label: 'public_donators', value: '2' },
                { label: 'private_donators', value: '0' },

            ],
            enter_total: false,
            total_amount: "",
            formatted_date: ""
        };
    },

    async created() {
        await this.taxReceiptStore.getDonators(this.selected_donator_type == 2);
        if (this.donators.length > 0) {
            this.donator_id = this.donators[0].id;
            this.donator = this.donators[0]

        }
        await this.taxReceiptStore.getFiscalYears();
    },

    methods: {
        generatePDF() {
            var element = document.getElementById('content');
            var opt = {
                margin: 0.5,
                filename: `${this.donator.name}_${this.donator.last_name}_${this.selectedYear}.pdf`,
		image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };
            html2pdf().set(opt).from(element).save();

        },

        async updateSelection() {
            this.loadingStore.setLoading(true);
            await this.taxReceiptStore.getDonators(this.selected_donator_type == 2);
            this.donator_id = null;
            this.donator = null
            this.loadingStore.setLoading(false);
        },

        async updateDonator() {
            this.taxReceiptStore.selectDonator(this.donator_id)
        },

        formattedDate() {
            const date = new Date();
            const options = {

                day: 'numeric',
                month: 'long',
                year: 'numeric',
            };

            this.formatted_date = date.toLocaleDateString("fr", options);

        },

        async getTaxReceiptInfo() {
            this.formattedDate()
            if (!this.enter_total) {
                await axios.get(`/api/donations/total/${this.donator_id}/${this.selectedYear}/${this.selected_donator_type}`).then((res) => {
                    this.totalDonations = res.data.campaigns.total + res.data.sponsorships.total
                    this.showTaxReceipt = true

                }).catch((error) => {
                    alert('Information not found');
                    console.log(error)
                })
            } else {
                this.showTaxReceipt = true
            }
        }
    },

    setup() {
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const languageStore = LanguageStore();
        const showTaxReceipt = ref(false);
        const countriesStore = CountriesStore();
        const { countries } = storeToRefs(countriesStore);
        const { t } = useI18n({});
        const totalDonations = ref(null);
        const taxReceiptStore = TaxReceiptStore()
        const { fiscalYears, selectedYear, donator, selected_donator_type, donator_id, donators } = storeToRefs(taxReceiptStore)
        return {
            taxReceiptStore,

            donator_id,
            totalDonations,
            t, is_loading, languageStore, donators, loadingStore,
            fiscalYears, selectedYear, donator,
            showTaxReceipt, selected_donator_type, countries
        };
    },
};
</script>
  
<style scoped></style>
  
