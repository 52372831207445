
<template>
  <loading :active="is_loading" :can-cancel="true" :is-full-page="fullPage" />

  <router-view style="width: 100%;  height:100%; padding: 0px"></router-view>
</template>
  
<script>
require('../bootstrap')
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { LoadingStore } from '@/stores/loading';
import { storeToRefs } from 'pinia';

export default {
  name: 'App',
  components: {
    Loading,
  },
  setup() {
    const loadingStore = LoadingStore();
    const { is_loading } = storeToRefs(loadingStore);
    return {
      loadingStore,
      is_loading
    };
  },

  watch: {
    is_loading: {
      deep: true
    }
  }
}



</script>
  
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
  