export default {
  "orphan_modal_ngo_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngo id"])},
  "orphan_modal_ngo_id_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Ngo id"])},
  "orphan_modal_add_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new orphan"])},
  "orphan_modal_update_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update orphan"])},
  "orphan_modal_delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete orphan"])},
  "orphan_modal_civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
  "orphan_modal_civility_option_undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
  "orphan_modal_civility_option_mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "orphan_modal_civility_option_ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "orphan_modal_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "orphan_modal_name_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter orphan name"])},
  "orphan_modal_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "orphan_modal_lastname_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter last name"])},
  "orphan_modal_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "orphan_modal_country_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Country"])},
  "orphan_modal_dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
  "orphan_modal_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "orphan_modal_phone_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter phone number"])},
  "orphan_modal_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family"])},
  "orphan_modal_family_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not selected"])}
}