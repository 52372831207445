<template>
    <div>
        <div class="modal-header">
            <h5 class="modal-title">{{ t('create_campaign') }}</h5>
        </div>
        <div class="modal-body" style="margin-top: 1%;">
            <h6 style="color: red" v-if="campaign_types == undefined || campaign_types.length == 0">
                {{ t('please_add_campaign') }}
            </h6>
            <form>
                <div class="form-row" v-if="campaign_types != undefined">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('sponsorships_name') }}*</label>
                        <input v-model="states.name" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('sponsorships_name_place_holder')" />
                        <h6 style="color: red" v-if="field_states.is_exist" id="emailHelp" class="form-text text-muted">
                            {{ t('campaign_type_exist') }}
                        </h6>
                        <h6 style="color: red" v-if="!field_states.is_exist && states.name.length == 0" id="emailHelp"
                            class="form-text text-muted">
                            {{ t('sponsorships_name_field_can_not_empty') }}
                        </h6>
                    </div>

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('campaign_type') }}*</label>

                        <select class="form-control" id="campaignType" v-model="states.selected_type">
                            <option v-for="option in campaign_types" :value="option.id" :key="'family' + option.id"> {{
                                option.name }} {{ '('
        + option.id + ')' }}</option>
                        </select>
                    </div>

                    <div class="form-group col-md-6">
                        <div>
                            <h6>{{ t('register_title') }}*</h6>

                            <div class="form-check form-check-inline ">
                                <input class="form-check-input" @change="updateSelection" v-model="states.is_family"
                                    type="radio" name="inlineRadioOptions" id="inlineRadio1" value=-1>
                                <label class="form-check-label" for="inlineRadio1">{{ t('register_noone') }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" @change="updateSelection" v-model="states.is_family"
                                    type="radio" name="inlineRadioOptions" id="inlineRadio2" value=1>
                                <label class="form-check-label" for="inlineRadio2">{{ t('register_family') }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" @change="updateSelection" v-model="states.is_family"
                                    type="radio" name="inlineRadioOptions" id="inlineRadio3" value=0>
                                <label class="form-check-label" for="inlineRadio3">{{ t('register_orphan') }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <donatorSelect2 v-if="states.is_family == 1" :items="families" :selected_item="selected_orphan"
                            :changeItemId="chnageOrphanId" :label="'Select Family'">
                        </donatorSelect2>
                        <donatorSelect2 v-if="states.is_family == 0" :items="orphans" :selected_item="selected_orphan"
                            :changeItemId="chnageOrphanId" :label="'Select Orphan'">
                        </donatorSelect2>
                    </div>
                </div>
            </form>
        </div>
        <div
            :class="canCheck ? 'w-100 d-flex flex-row justify-content-between' : 'w-100 d-flex flex-row justify-content-end'">
            <div class="form-check form-switch d-flex align-items-center" v-if="canCheck">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" :checked="states.checked"
                    @click="states.checked = !states.checked">
                <label class="p-0 flex-grow-1 m-0" for="flexSwitchCheckChecked">{{ t('checked') }}</label>
            </div>

            <div class="modal-footer">
                <button
                    :disabled="(campaign_types == undefined || campaign_types.length == 0 || states.name.length == 0 || field_states.is_exist)"
                    @click="add" type="button" style="color: white" class="btn btn-primary add-button">
                    {{ selected_campaign !== undefined ? t('update_campaign') : t('create_campaign') }}
                </button>

                <button type="button" style="color: white" class="btn btn-danger" @click="close">
                    {{ t('orphan_modal_cancel_button') }}

                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { NotificationStore } from '@/stores/notification';
import { CampaignTypeStore } from '@/stores/campaignTypeStore';
import { CampaignsStore } from '@/stores/campaignsStore';
import donatorSelect2 from '@/components/donatorSelect2.vue';
import { UserStore } from '@/stores/userStore';
import { OrphanEditStates } from '@/stores/orphanEditStates';
export default {
    name: 'CreateCampaign',
    props: ['selected_campaign'],
    data() {
        return {
            canCheck: false,
            selected_orphan: undefined
        };
    },
    components: {
        donatorSelect2,
    },

    watch: {
        orphans: {
            deep: true
        },
        families: {
            deep: true
        },
        campaign_types: {
            deep: true
        },
        currencies: {
            deep: true
        },
        field_states: {
            handler(newv, oldv) {
                console.log("n", newv, oldv);
            },
            deep: true,
        },
        isDisabled: {
            deep: true,
        },
        selected_campaign: {
            deep: true,
            immediate: true,
            handler(newV) {
                if (newV) {
                    this.selected_orphan = newV.orphan_info
                }
            }
        }
    },
    async created() {
        this.loadingStore.setLoading(true);
        await this.campaignTypeStore.get();
        if (!this.selected_campaign) {
            if (this.campaign_types != undefined && this.campaign_types.length > 0) {
                this.states.selected_type = this.campaign_types[0].id;
            }
        }
        else {
            this.canCheck = this.userStore.checkIfHasPermission('check.entries.update')
            if (this.states.is_family == 1) {
                this.campaignsStore.getFamilies()
            } else {
                this.campaignsStore.getOrphans()
            }
        }
        this.loadingStore.setLoading(false);

    },
    methods: {
        async updateSelection() {
            this.selected_orphan = undefined
            if (this.states.is_family == 1) {
                this.loadingStore.setLoading(true);
                await this.campaignsStore.getFamilies();
                this.states.orphan_id = this.families[0].id;
                this.loadingStore.setLoading(false);
            }

            if (this.states.is_family == 0) {
                this.loadingStore.setLoading(true);
                await this.campaignsStore.getOrphans();
                this.states.orphan_id = this.orphans[0].id;
                this.loadingStore.setLoading(false);
            }

        },
        close() {
            this.campaignTypeStore.cleanStates();
            this.$emit('close');
        },

        async add() {
            this.loadingStore.setLoading(true);
            if (!this.selected_campaign) {
                let response = await this.campaignsStore.add({
                    name: this.states.name,
                    type: this.states.selected_type,
                    orphan: this.states.is_family == -1 ? null : {
                        orphan_id: this.states.orphan_id,
                        is_family: this.states.is_family
                    }
                });
                if (response.status) {
                    this.$emit('status', true);
                    this.close();
                }
                let message = response.status ? this.t('campaign_type_added') : this.t('campaign_type_cannot_added');
                this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);

            }
            else {
                let response = await this.campaignsStore.update(this.states.id, {
                    name: this.states.name,
                    type: this.states.selected_type,
                    orphan: this.states.is_family == -1 ? null : {
                        orphan_id: this.states.orphan_id,
                        is_family: parseInt(this.states.is_family)
                    }
                });
                if (response.status) {
                    if (this.canCheck) {
                        await this.orphanEditStates.checkEntry('campaigns', this.states.id, this.states.checked)
                    }
                    this.$emit('status', true);
                    this.close();

                }
                let message = response.status ? this.t('campaign_updated') : this.t('campaign_cannot_updated');
                this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
            }
            this.loadingStore.setLoading(false);
        },
        chnageOrphanId(id) {
            this.states.orphan_id = id
        },
        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.check_is_exist, 75);
        },

        pastedetected(eventdata) {
            this.search = "";
            this.states.name = eventdata.clipboardData.getData("Text");
            this.searching();
        },

        async check_button() {
            this.$emit('check', this.ngo_id, this.name, this.last_name);
        },

        async check_is_exist() {
            if (this.states.name.length != 0) {
                let response = await this.campaignTypeStore.search(this.states.name, 0);
                if (response.status && response.data.data.length > 0)
                    this.field_states.is_exist = true;
                else
                    this.field_states.is_exist = false;

            }
            else {
                this.field_states.is_exist = false;
            }
        }

    },
    setup() {
        const campaignTypeStore = CampaignTypeStore();
        const campaignsStore = CampaignsStore();
        const notificationStore = NotificationStore();
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const languageStore = LanguageStore();
        const orphanEditStates = OrphanEditStates();
        const userStore = UserStore();
        const { states, families, orphans, field_states } = storeToRefs(campaignsStore);
        const { campaign_types } = storeToRefs(campaignTypeStore);
        const { t } = useI18n({});
        return {
            t, is_loading, languageStore, campaignTypeStore, campaign_types, families, orphans, campaignsStore, loadingStore,
            notificationStore, states, field_states, userStore, orphanEditStates
        };
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

label {
    float: left;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>