<template>
    <div>
        <div class="modal-header">
            <h5 class="modal-title">{{ t('add_enrollment') }}</h5>
        </div>
        <div class="modal-body" style="margin-top: 1%;">
            <form>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="sponsorship">{{ t('select_sponsorship') }}*</label>

                        <select v-model="states.sponsorship_id" class="form-control" id="sponsorship"
                            @change="CheckIsExist">
                            <option value="">{{ t('select_sponsorship') }}</option>

                            <option v-for="option in sponsorships" :value="option.id" :key="option.name">{{ option.name
                            }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="sponsorship">{{ t('select_donator') }}*</label>
                        <donatorSelect2 :selected_item="selected_donator" :changeItemId="changeDonatorId" :items="donators"
                            :label="'Select Donator'">
                        </donatorSelect2>
                    </div>
                </div>
                <div class="form-row">

                    <div class="form-group col-md-6">
                        <div>
                            <h6>{{ t('register_title') }}*</h6>

                            <div class="form-check form-check-inline">
                                <input class="form-check-input" @change="updateSelection" v-model="states.is_family"
                                    type="radio" name="inlineRadioOptions" id="inlineRadio2" value=1>
                                <label class="form-check-label" for="inlineRadio2">{{ t('register_family') }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" @change="updateSelection" v-model="states.is_family"
                                    type="radio" name="inlineRadioOptions" id="inlineRadio3" value=0>
                                <label class="form-check-label" for="inlineRadio3">{{ t('register_orphan') }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">

                    <div class="form-group col-md-6 p-0">
                        <label for="family">{{ states.is_family ? t('select_family') : t('select_orphan') }}*</label>
                        <OrphanSelect2 :is_family="states.is_family" :selected_orphan="selected_orphan">
                        </OrphanSelect2>

                    </div>
                    <div class="form-group col-md-5 p-0 mx-2" v-if="selected_enrollment !== undefined">
                        <label for="actibe">{{ t('active') }}*</label>

                        <select class="form-control" id="active" v-model="states.is_active">
                            <option :value=1>{{ t("active") }}</option>
                            <option :value=0>{{ t("deactive") }}</option>
                        </select>
                    </div>
                </div>

            </form>
        </div>
        <br />
        <div
            :class="canCheck ? 'w-100 d-flex flex-row justify-content-between' : 'w-100 d-flex flex-row justify-content-end'">
            <div class="form-check form-switch d-flex align-items-center" v-if="canCheck">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" :checked="states.checked"
                    @click="states.checked = !states.checked">
                <label class="p-0 flex-grow-1 m-0" for="flexSwitchCheckChecked">{{ t('checked') }}</label>
            </div>

            <div class="modal-footer">
                <button
                    :disabled="(states.orphan_id == '' || states.sponsorship_id == '' || states.donator_id == '' || field_states.is_exist)"
                    @click="add" type="button" style="color: white" class="btn btn-primary add-button">
                    {{ selected_enrollment ? t('update_enrollment') : t('add_enrollment') }}
                </button>

                <button type="button" style="color: white" class="btn btn-danger" @click="close">
                    {{ t('orphan_modal_cancel_button') }}

                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { NotificationStore } from '@/stores/notification';
import { EnrolledSponsorshipsStore } from '@/stores/enrolledSponsorshipsStore';
import { useI18n } from 'vue-i18n';
import donatorSelect2 from '@/components/donatorSelect2.vue';
import OrphanSelect2 from "@/components/orphanSelect2.vue";
import { UserStore } from "@/stores/userStore";
import { OrphanEditStates } from "@/stores/orphanEditStates";

export default {
    name: 'CreateEnrollment',
    props: ['selected_enrollment'],
    components: {
        donatorSelect2,
        OrphanSelect2
    },
    data() {
        return {
            selected_donator: this.selected_enrollment ? this.selected_enrollment.donator : undefined,
            selected_orphan: this.selected_enrollment ? this.selected_enrollment.orphan : undefined,
            canCheck: false
        };
    },

    watch: {
        currencies: {
            deep: true
        },
        field_states: {
            deep: true,
        },
        isDisabled: {
            deep: true,
        },
        states: {
            deep: true,

        },
        selected_enrollment: {
            deep: true,
            immediate: true
        },

    },
    async created() {
        if (!this.selected_enrollment) {
            this.enrolledSponsorshipsStore.cleanStates();
        } else {
            this.canCheck = this.userStore.checkIfHasPermission('check.entries.update')
        }
        this.getPreData();
    },



    methods: {

        async getPreData() {
            this.loadingStore.setLoading(true);
            if (this.selected_enrollment) {
                await this.enrolledSponsorshipsStore.getDonators();
                await this.enrolledSponsorshipsStore.getSponsorships();
                if (this.states.is_family == 1) {
                    await this.enrolledSponsorshipsStore.getFamilies();
                } else {
                    await this.enrolledSponsorshipsStore.getOrphans();
                }
            } else {
                await this.enrolledSponsorshipsStore.getDonators();
                this.states.is_family = 0;
                await this.enrolledSponsorshipsStore.getOrphans();
                if (this.states.orphans == undefined || this.states.orphans.length == 0) {
                    this.states.is_family = 1;
                }
                await this.enrolledSponsorshipsStore.getSponsorships();
            }
            this.loadingStore.setLoading(false);
        },

        async CheckIsExist() {
            this.loadingStore.setLoading(true);
            await this.enrolledSponsorshipsStore.isExist(this.states);
            this.loadingStore.setLoading(false);

        },
        async updateSelection() {
            //todo fix this on update
            if (this.states.is_family == 1) {
                this.loadingStore.setLoading(true);
                await this.enrolledSponsorshipsStore.getFamilies();
                if (!this.selected_enrollment) {
                    this.states.orphan_id = "";
                }
                this.loadingStore.setLoading(false);
            }
            if (this.states.is_family == 0) {
                this.loadingStore.setLoading(true);
                await this.enrolledSponsorshipsStore.getOrphans();
                if (!this.selected_enrollment) {
                    this.states.orphan_id = "";
                }
                this.loadingStore.setLoading(false);
            }

        },

        changeDonatorId(donatorId) {
            this.states.donator_id = donatorId
        },

        close() {
            this.enrolledSponsorshipsStore.cleanStates();
            this.selected_donator = undefined;
            this.selected_orphan = undefined
            this.$emit('close');
        },

        async add() {
            this.loadingStore.setLoading(true);
            if (this.selected_enrollment) {
                let response = await this.enrolledSponsorshipsStore.update(this.states.id, this.states);
                if (this.canCheck) {
                    await this.orphanEditStates.checkEntry('enrolled_sponsorships', this.states.id, this.states.checked)
                }
                if (response.status) {
                    this.$emit('status', true);
                    this.close();
                }
                let error_message = undefined;
                if (response.data?.data?.error) {
                    error_message = response.data.data.error
                }
                let message = response.status ? this.t('enrollment_updated') : `${error_message}. ${this.t('enrollment_not_updated')}. `;
                this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
            }
            else {
                let response = await this.enrolledSponsorshipsStore.add(this.states);
                if (response.status) {
                    this.$emit('status', true);
                    this.close();

                }
                let message = response.status ? this.t('sponsorships_type_added') : this.t('sponsorships_type_not_added');
                this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);

            }
            this.loadingStore.setLoading(false);
        },

        async check_button() {
            this.$emit('check', this.ngo_id, this.name, this.last_name);
        },

    },
    setup() {
        const loadingStore = LoadingStore();
        const notificationStore = NotificationStore();
        const { is_loading } = storeToRefs(loadingStore);
        const enrolledSponsorshipsStore = EnrolledSponsorshipsStore();
        const languageStore = LanguageStore();
        const userStore = UserStore();
        const orphanEditStates = OrphanEditStates()
        const { page, states, field_states, sponsorships, enrolled_sponsorships, orphans, donators } = storeToRefs(enrolledSponsorshipsStore);
        const { t } = useI18n({});
        return {
            languageStore,
            loadingStore,
            userStore,
            orphanEditStates,
            notificationStore,
            enrolledSponsorshipsStore,
            states,
            field_states,
            page,
            sponsorships,
            enrolled_sponsorships,
            orphans,
            donators,
            is_loading,
            t
        };
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

label {
    float: left;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>
  