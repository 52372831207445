<template>
    <div>
        <div class="modal-header">
            <h5 class="modal-title">{{ t('donators_modal_add_button') }}</h5>
        </div>
        <div class="modal-body" style="margin-top: 1%;">
            <form>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_email') }}*</label>
                        <input v-model="states.personal.email" type="email"
                            class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            :placeholder="t('donators_email_place_holder')" />
                        <h6 style="color: red" v-if="states.personal.email.length == 0" id="emailHelp"
                            class="form-text text-muted">
                            {{ t('donators_email_empty') }}
                        </h6>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_name') }}*</label>
                        <input v-model="states.personal.name" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_name_placeholder')" />
                        <h6 style="color: red" v-if="states.personal.name.length == 0" id="emailHelp"
                            class="form-text text-muted">
                            {{ t('donators_name_empty') }}
                        </h6>
                    </div>

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_phone') }}</label>
                        <input v-model="states.personal.phone" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_phone_placeholder')" />
                    </div>

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail5">{{ t('orphan_modal_country') }}</label>
                        <select v-model="states.address.country" class="form-control" id="familyFilter">
                            <option value="">{{ t('select_country') }}</option>
                            <option v-for="country in countries" :value="country.alpha2Code" :key="country.alpha2Code">{{
                                languageStore.getLanguage == 'en' ? country.name : country.translations.fr }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_county') }}</label>
                        <input v-model="states.address.county" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_table_county_place_holder')" />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_address') }}</label>
                        <input v-model="states.address.address" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_address_place_holder')" />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_zipcode') }}</label>
                        <input v-model="states.address.zipcode" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_adress_zipcode')" />
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button :disabled="(states.personal.name.length == 0 || states.personal.email.length == 0)" @click="add" type="button"
                style="color: white" class="btn btn-primary add-button">
                {{ t('donators_modal_add_button') }}
            </button>

            <button type="button" style="color: white" class="btn btn-danger" @click="close">
                {{ t('orphan_modal_cancel_button') }}

            </button>
        </div>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { NotificationStore } from '@/stores/notification';
import { DonatorStore } from "@/stores/donatorStore";
import { CountriesStore } from '@/stores/countries';

export default {
    name: 'CreatePublicDonators',
    data() {
        return {
            data: {

            }
        };
    },

    watch: {
        currencies: {
            deep: true
        },
        field_states: {
            deep: true,
        },
        isDisabled: {
            deep: true,
        }
    },
    async created() {
        this.donatorStore.cleanStates();
    },
    methods: {

        close() {
            this.donatorStore.cleanStates();
            this.$emit('close');
        },

        async add() {
            this.loadingStore.setLoading(true);
            let response = await this.donatorStore.add(this.states, true);
            if (response.status) {
                this.$emit('status', true);
                this.close();

            }
            // Call api
            this.loadingStore.setLoading(false);
            let message = response.status ? this.t('donators_added') : this.t('donators_not_added');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        },

        async check_button() {
            this.$emit('check', this.ngo_id, this.name, this.last_name);
        },

    },
    setup() {
        const donatorStore = DonatorStore();
        const notificationStore = NotificationStore();
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const countriesStore = CountriesStore();
        const { countries } = storeToRefs(countriesStore);
        const languageStore = LanguageStore();
        const { states, civility_options, type_options, field_states } = storeToRefs(donatorStore);
        const { t } = useI18n({});
        return {
            t, is_loading, languageStore, type_options, civility_options, loadingStore,
            countries, notificationStore, donatorStore, states, field_states
        };
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

label {
    float: left;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>
  