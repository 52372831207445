import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const CampaignsStore = defineStore("campaignsStore", {
  state: () => ({
    states: {
      checked: false,
      id: "",
      name: "",
      type: -1,
      selected_type: -1,
      is_family: -1,
      orphan_id: undefined,
    },
    families: undefined,
    orphans: undefined,
    field_states: {
      is_exist: false,
    },
    page: {
      value: 0,
      count: 0,
    },
    original_states: {},
    campaigns: undefined,
  }),

  getters: {},

  actions: {
    cleanStates() {
      this.states = {
        name: "",
        checked: false,
        selected_type: -1,
        is_family: -1,
        orphan_id: undefined,
      };
      this.field_states = {
        is_exist: false,
      };
      this.original_states = {};
      this.families = undefined;
      this.orphans = undefined;
    },

    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async update(id, data) {
      const url = "api/campaigns/" + id;
      return axios
        .put(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async add(data) {
      const url = "api/campaigns";
      if (data.selected_type == "-1") data.selected_type = null;
      return axios
        .post(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async destroy(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = "api/campaigns/" + id;
      return axios
        .delete(url)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async search_fetch(key, like, page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }

      return axios
        .get("api/campaigns/search", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            key: key,
            like: like,
            type:
              this.states.selected_type == -1
                ? null
                : this.states.selected_type,
          },
        })
        .then((response) => {
          this.campaigns = response.data.data;
          this.page.count = response.data.number_of_page;
          this.page.value = this.page.count > 0 ? response.data.page_number : 0;

          return { status: true, data: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          this.campaigns.data = [];
          this.page.count = 0;
          this.page.value = 0;
          return { status: false, message: "Not Found!" };
        });
    },

    async search(key, like, page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/campaigns/search", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            key: key,
            like: like,
            type:
              this.states.selected_type == -1
                ? null
                : this.states.selected_type,
          },
        })
        .then((response) => {
          return { status: true, data: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          this.orphans.data = [];
          return { status: false, message: "Not Found!" };
        });
    },

    async get(page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/campaigns", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            type:
              this.states.selected_type == -1
                ? null
                : this.states.selected_type,
          },
        })
        .then((response) => {
          this.campaigns = response.data.data;
          this.page.count = response.data.number_of_page;
          this.page.value = this.page.count > 0 ? response.data.page_number : 0;
          this.page.number_of_data = response.data.number_of_data;
          this.page.per_page = response.data.per_page;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },
    //For create sponsorships
    async getFamilies() {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/family")
        .then((response) => {
          this.families = response.data.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },
    //For create sponsorships to orphans

    async getOrphans() {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/orphans")
        .then((response) => {
          this.orphans = response.data.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async getCampaign(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }

      const url = "api/campaigns/" + id;
      return axios
        .get(url)
        .then((response) => {
          let data = response.data;
          this.states = {
            checked: data.checked,
            id: data.id,
            name: data.name,
            type: data.type,
            selected_type: data.type,
            is_family: data.orphan == null ? -1 : data.orphan.is_family,
            orphan_id: data.orphan == null ? undefined : data.orphan.orphan_id,
          };
          this.original_states = Object.assign({}, this.states);

          return { status: true, data: this.states };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
