import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const UserStore = defineStore("userStore", {
  state: () => ({
    notifications: [],
    checkEntries: [],
    user: {},
    users: undefined,
    token: "",
    user_info: {},
    first_login: false,
    changePasswordError: "",
    forgotPasswordError: "",
    createUserError: "",
    editProfileError: "",
    updateRoleError: "",
    permissions: [],
  }),

  getters: {
    getToken: (state) => state.token,
    getUserEmail: (state) => state.user.email,
    getUserInfo: (state) => state.user_info,
    getUserID: (state) => state.user_info.id,
    getName: (state) => (state.user_info ? state.user_info.name : undefined),
  },

  actions: {
    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    checkIfHasPermission(permissionName) {
      if (this.permissions.length) {
        return this.permissions.some(
          (permission) => permission.name == permissionName
        );
      } else return false;
    },

    async getNotifications() {
      if (this.checkIfHasPermission("sponsorships.late.donations.index")) {
        return axios
          .get("api/enrolled/sponsorship/missing/donations")
          .then((response) => {
            if (response.data.data.length > 0) {
              let reformed_data = response.data.data.map((data) => {
                return {
                  id: data.id,
                  donator_name: `${data.donator.name} ${data.donator.last_name}`,
                  sponsorship: data.sponsorship.name,
                  orphan_name: `${data.orphan?.name} ${
                    data.orphan?.last_name ? data.orphan.last_name : ""
                  }`,
                  last_donation: data.last_donation,
                };
              });
              this.notifications = reformed_data;
            } else {
              this.notifications = [];
            }
            return { status: true };
          })
          .catch((exception) => {
            checkResponse(exception.response.status);
            return { status: false, message: "Not Found!" };
          });
      } else {
        this.notifications = [];
      }
    },

    async handleForgotPassword(data) {
      return axios
        .post("/api/send-otp", data)
        .then((response) => {
          return { status: true, message: "success", data: response.data };
        })
        .catch((e) => {
          this.forgotPasswordError = e.response.data?.error;
          return { status: false, message: e.response.data.error };
        });
    },

    transformTableName(name, type) {
      switch (name) {
        case "orphans":
          return type === "name" ? "orphans_table" : "/app/orphans";
        case "donators":
          return type === "name" ? "donators_table" : "/app/donators/private";
        case "public_donators":
          return type === "name"
            ? "public_donators_table"
            : "/app/donators/public";
        case "campaigns":
          return type === "name" ? "campaigns_table" : "/app/campaigns";
        case "campaign_donations":
          return type === "name"
            ? "campaign_donations_table"
            : "/app/campaigns/donations";
        case "campaign_types":
          return type === "name"
            ? "campaign_types_table"
            : "/app/campaigns/types";
        case "orphans_family":
          return type === "name"
            ? "orphans_family_table"
            : "/app/orphans/family";
        case "enrolled_sponsorships":
          return type === "name"
            ? "enrolled_sponsorships_table"
            : "/app/sponsorships/";
        case "sponsorships":
          return type === "name"
            ? "sponsorships_table"
            : "/app/sponsorships/types";
        default:
          return "No Matching Name";
      }
    },

    async getToBeChecked() {
      if (this.permissions.length < 1) {
        await this.getInfo();
      }
      if (this.checkIfHasPermission("check.entries.index")) {
        return axios
          .get("api/check-entries")
          .then((response) => {
            console.log(response.data);
            let reformed_data = response.data.data.map((data) => {
              return {
                ...data,
                name: this.transformTableName(data.table_name, "name"),
                route: this.transformTableName(data.table_name, "route"),
              };
            });

            this.checkEntries = reformed_data;
            return { status: true };
          })
          .catch((exception) => {
            checkResponse(exception.response.status);
            return { status: false, message: "Not Found!" };
          });
      } else {
        this.checkEntries = [];
      }
    },

    async updateRole(id, role_id) {
      return axios
        .post(`/api/users/${id}/role`, { role_id: role_id })
        .then((response) => {
          this.getInfo();
          return { status: true, message: "success", data: response.data };
        })
        .catch((e) => {
          this.updateRoleError = e.response.data?.error;
          return { status: false, message: e.response.data.error };
        });
    },
    async updateProfile(data) {
      return axios
        .put(`/api/users/${this.user_info.id}`, data)
        .then((response) => {
          this.getInfo();
          return { status: true, message: "success", data: response.data };
        })
        .catch((e) => {
          this.editProfileError = e.response.data?.error;
          return { status: false, message: e.response.data.error };
        });
    },

    async createUser(data) {
      return axios
        .post("/api/user/register", data)
        .then((response) => {
          return { status: true, message: "success", data: response.data };
        })
        .catch((e) => {
          this.createUserError = e.response.data?.error;
          return { status: false, message: e.response.data.error };
        });
    },

    async changePassword(data) {
      return axios
        .put("/api/change-password", data)
        .then((response) => {
          return { status: true, message: "success", data: response.data };
        })
        .catch((e) => {
          this.changePasswordError = e.response.data?.error;
          return { status: false, message: e.response.data.error };
        });
    },

    async login(user) {
      return axios
        .post("api/login", {
          email: user.email,
          password: user.password,
        })
        .then((response) => {
          let response_data = response.data;
          if (response_data.first_login === true) {
            this.first_login = true;
            return { status: true, data: "first_login" };
          }
          if (response_data.access_token) {
            this.first_login = false;

            this.user.email = response_data.email;
            this.token = response_data.access_token;
            if (localStorage.getItem("ngo_access_token") !== null)
              localStorage.clear();
            localStorage.setItem("ngo_access_token", this.token);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("ngo_access_token");
            return { status: true, message: "success" };
          }
          return false;
        })
        .catch((e) => {
          console.log(e);
          return { status: false, message: "Email or password is wrong !" };
        });
    },

    async getInfo() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return axios
        .get("api/user")
        .then((response) => {
          let response_data = response.data;
          this.user_info = response_data;
          this.permissions = response_data.permissions;
          return { status: true, message: "success", data: response_data };
        })
        .catch(() => {
          return { status: false, message: "Session expired", data: null };
        });
    },

    async logout() {
      if (localStorage.getItem("ngo_access_token") == null)
        return { status: false };
      else {
        await axios.post("api/user/logout").then((res) => {
          if (res.status === 200) {
            localStorage.removeItem("ngo_access_token");
            axios.defaults.headers.common["Authorization"] = null;
            window.location.replace("/login");
            return { status: true, message: "success" };
          } else
            return { status: false, message: "An error occured", data: null };
        });
      }
    },
    async getUsers(page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/users", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
          },
        })
        .then((response) => {
          this.users = response.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async deleteUser(id) {
      const url = "api/user/" + id;
      return axios
        .delete(url)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
