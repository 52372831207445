export default {
  "navbar_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "navbar_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails"])},
  "navbar_donation_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation History"])},
  "navbar_orphans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphans"])},
  "navbar_orphans_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphan"])},
  "navbar_orphans_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Orphans"])},
  "navbar_orphans_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations"])},
  "navbar_sponsorships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorships"])},
  "navbar_sponsorships_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship"])},
  "navbar_sponsorships_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "navbar_campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])},
  "navbar_campaigns_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign"])},
  "navbar_campaigns_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "navbar_campaigns_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations"])},
  "navbar_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donators"])},
  "navbar_donators_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
  "navbar_donators_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
  "navgar_donators_donates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Receipt"])}
}