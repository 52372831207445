export default {
  "auth_forget_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "login_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email ou le mot de passe est erroné !"])},
  "session_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La session a expiré"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe"])},
  "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le nouveau mot de passe"])},
  "create_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un utilisateur"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe à usage unique"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "user_added_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur a été créé avec succès"])},
  "user_not_added_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite"])},
  "Regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régulière"])},
  "Editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur"])},
  "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratrice"])},
  "user_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de supprimer l'utilisateur ?"])},
  "user_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sera supprimé, vous ne pourrez pas revenir en arrière!"])},
  "user_delete_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ne peut pas être supprimé"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer le profil"])},
  "profile_edit_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le profil a été modifié avec succès"])},
  "profile_edit_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le profil n'a pas pu être modifié, une erreur s'est produite"])},
  "password_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe a été modifié avec succès"])},
  "password_change_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la modification du mot de passe"])},
  "change_password_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changez votre mot de passe avant de vous connecter"])},
  "enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez l'adresse e-mail de votre compte"])},
  "send_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer OTP"])},
  "otp_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP envoyé à l'adresse e-mail fournie"])},
  "otp_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP n'a pas pu être envoyé!"])}
}