<template>
    <div class="form-group  my-2 autocomplete">
        <input v-model="searchText" @input="search" class="form-control" :placeholder='this.label' @focus="search" />
        <ul v-if="showResults" class="autocomplete-results">
            <li v-if="!donators_shown || donators_shown.length < 1" class="autocomplete-result">
                {{ t('result_not_found') }}
            </li>
            <li v-for="item in donators_shown" :key="item.id" @click="selectItem(item)" class="autocomplete-result">
                {{ `${handleName(item.name, item.last_name)} - ${item.id}` }}
            </li>
        </ul>
    </div>
</template>
  
<script>
import _ from "lodash";
import { DonatorStore } from "@/stores/donatorStore";
import { EnrolledSponsorshipsStore } from "@/stores/enrolledSponsorshipsStore";
import { useI18n } from "vue-i18n";
export default {
    props: ['selected_item', 'changeItemId', 'items', 'label'],
    data() {
        return {
            donators_shown: undefined,
            searchText: this.selected_item ? this.handleName(this.selected_item.name, this.selected_item.last_name) : "",
            showResults: false,
        };
    },
    async created() {
        await this.enrolledSponsorshipsStore.getDonators()
        this.donators_shown = this.items
    },
    setup() {
        const donatorStore = DonatorStore()
        const enrolledSponsorshipsStore = EnrolledSponsorshipsStore()
        const { t } = useI18n({});
        return {
            donatorStore,
            t,
            enrolledSponsorshipsStore,
        };
    },
    watch: {
        filtered_donators: {
            deep: true
        },
        selected_item: {

            deep: true,
            immediate: true
        }
    },
    methods: {
        // Throttle the search method to avoid sending too many requests
        search: _.debounce(function () {
            if (this.searchText) {
                this.changeItemId(null)
                this.searchDonators();
            } else {
                this.changeItemId(null)
                this.donators_shown = this.items
                this.showResults = true

            }
        }, 1000), // Throttle for 3 seconds

        // Fetch search results from an API (adjust the API endpoint)
        async searchDonators() {
            let lower_case_search_text = this.searchText.toLowerCase().split(" ")[0]
            let filtered_donators = this.items.filter((donator) => donator.name && donator.name.toLowerCase().includes(lower_case_search_text) || donator.last_name && donator.last_name.toLowerCase().includes(lower_case_search_text))
            this.donators_shown = filtered_donators
            this.showResults = true
        },

        async getDonators() {
            this.filtered_donators = this.items
            this.showResults = true
        },

        handleName(name, last_name) {
            return (name || '') + (last_name ? ' ' + last_name : '');
        },
        // Handle item selection and update the selected_item prop
        selectItem(item) {
            this.changeItemId(item.id)
            this.searchText = this.handleName(item.name, item.last_name) // Set the input text to the selected item's name
            this.showResults = false; // Hide the dropdown
        },
    },
};
</script>

<style>
.autocomplete {
    position: relative;
}

.autocomplete-results {
    padding: 5px;
    background-color: #f5f3f3;
    margin: 0;
    margin-top: 3px;
    border: 1px solid #eeeeee;
    height: auto;
    border-radius: 0 0 10px 10px;
    min-height: 1em;
    max-height: 20em;
    overflow: auto;
}

.autocomplete-result {
    list-style: none;
    /* border-bottom: solid 1px #96BFFE; */
    text-align: left;
    border-radius: 5px;
    padding: 3px 5px;
    cursor: pointer;
}

.autocomplete-result:hover {
    background-color: #5193f6;
    color: white;
}
</style>
  