export default {
  "campaign_type_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Type added succesfully"])},
  "campaign_type_cannot_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Type cannot added"])},
  "campaign_type_modal_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Campaign Type"])},
  "campaign_type_modal_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Campaign Type"])},
  "campaign_type_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given name already exist"])},
  "campaign_type_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete Campaign type ?"])},
  "campaign_type_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign type successfully deleted"])},
  "campaign_type_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign type can not deleted"])},
  "campaign_type_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign type updated"])},
  "campaign_type_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign type not found"])},
  "campaign_type_not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign type can not updated"])},
  "campaign_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign name"])},
  "campaign_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Type"])},
  "campaign_orphan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered Orphan"])},
  "please_add_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Type do not exist, please add campaign type before proceed"])},
  "register_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register To"])},
  "register_orphan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphan"])},
  "register_noone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No one"])},
  "register_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family"])},
  "select_orphan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Orphan"])},
  "create_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Campaign"])},
  "campaign_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign added succesfully"])},
  "campaign_cannot_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign cannot added"])},
  "campaign_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete Campaign ?"])},
  "campaign_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign successfully deleted"])},
  "campaign_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign can not deleted"])},
  "campaign_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign updated"])},
  "campaign_cannot_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign updated"])},
  "update_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Campaign"])},
  "all_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Types"])},
  "campaign_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign not found"])},
  "donator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donator"])},
  "donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations"])},
  "public_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Donators"])},
  "private_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Donators"])},
  "all_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Donators"])},
  "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Donations"])},
  "create_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Donation"])},
  "donation_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of donation"])},
  "amount_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount can not be empty or less than 0"])},
  "enter_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter amount"])}
}