export default {
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello this is test"])},
  "header_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "header_admin_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Panel"])},
  "header_orphans_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphans > Family"])},
  "header_orphans_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphans > Donations"])},
  "header_sponsorship_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorships > Types"])},
  "header_campaign_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns > Types"])},
  "header_campaign_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Donations"])},
  "header_donators_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donators > Public"])},
  "header_donators_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donators > Private"])},
  "header_tax_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donators > Tax Receipt"])}
}