<template>
    <div class="m-4 d-flex">
        <BaseModal :open="changePasswordIsOpen" @close="changePasswordIsOpen = !changePasswordIsOpen">
            <ChangePasswordModal @close="changePasswordIsOpen = !changePasswordIsOpen" :is_login='false'>
            </ChangePasswordModal>
        </BaseModal>
        <BaseModal :open="editOpen" @close="editOpen = !editOpen">
            <EditProfileModal :user="this.user_info" @close="editOpen = !editOpen" :is_login='false'>
            </EditProfileModal>
        </BaseModal>
        <!-- <input type="file" accept="image/*" @change="uploadImage"> -->
        <img v-bind:src="require('../../assets/user.png')" class="profileImg" />
        <!-- <img :src="previewImage" /> -->
        <div class="mx-3 my-3 d-flex flex-column align-items-start justify-content-evenly px-3">
            <h4>Name: {{ user_info.name }}</h4>
            <h4>Email: {{ user_info.email }} </h4>
            <h4>Role: {{ user_info.role?.name }}</h4>
        </div>
        <div class="mx-3 my-3 d-flex flex-column align-items-start justify-content-evenly px-3">
            <button class="edit-buttons btn btn-dark h-25 " @click="openModal(false)">{{ t('change_password') }}</button>
            <button class="edit-buttons btn btn-dark  h-25 w-100" @click="openModal(true)">{{ t('edit_profile') }}</button>
        </div>
    </div>
</template>

<script>
import { UserStore } from '@/stores/userStore';
import { storeToRefs } from 'pinia';
import ChangePasswordModal from './Modal/ChangePasswordModal.vue'
import EditProfileModal from './Modal/EditProfile.vue'

import BaseModal from '../BaseModal.vue';
import { useI18n } from 'vue-i18n';
export default {
    name: 'ProfilePage',
    components: {
        BaseModal,
        ChangePasswordModal,
        EditProfileModal
    },
    setup() {
        const userStore = UserStore();
        const { user_info } = storeToRefs(userStore);
        const { t } = useI18n({});
        return {
            user_info,
            userStore,
            t

        }

    },
    watch: {
        user_info: {
            deep: true
        }
    },

    data() {
        return {
            changePasswordIsOpen: false,
            previewImage: undefined,
            editOpen: false,

        }
    },
    async created() {
    },
    methods: {
        uploadImage(e) {
            const [image] = e.target.files;
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
                //TODO: add image to the backend
                console.log(this.previewImage);
            };
        },

        openModal(edit = false) {
            if (edit) {
                this.userStore.getInfo();
                this.editOpen = true
            }
            else {
                this.changePasswordIsOpen = true
            }
        },

        close() {
            this.changePasswordIsOpen = false
        },



    },

}
</script>

<style scoped>
.profileImg {
    height: 200px;
    width: 200px
}

.error-message {
    color: red;
    margin-bottom: 20px;
}
</style>