<template>
    <div>
        <div class="text-start">

            <div class="d-flex flex-row justify-content-between">
                <div class="form-group col-md-7 px-0">
                    <p class="m-0 px-2">{{ t('select_recipients') }}:</p>
                    <div class="d-flex flex-row w-100">
                        <recipientSelect2 :items="public_donators" :label="'Select Public Donator'"
                            :toggleSelection="toggleRecipientSelection" :is_public="true"
                            :toggleSelectAll="toggleSelectAll">
                        </recipientSelect2>
                        <recipientSelect2 :items="private_donators" :label="'Select Private Donator'"
                            :toggleSelection="toggleRecipientSelection" :toggleVipSelection="toggleVIPSelection"
                            :is_public="false" :toggleRegularSelection="toggleRegularSelection"
                            :toggleSelectAll="toggleSelectAll">
                        </recipientSelect2>
                    </div>
                </div>
                <div class="form-group col-md-4 px-0 ">

                    <label for="email_lang">{{ t('email_lang') }}</label>

                    <select v-model="email_lang" class="form-control" key="email_lang">
                        <option v-for="option in languages" :value="option.key" :key="option.key">
                            {{ option.label }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="mx-2">
                <div class="form-group">
                    <label for="recipients">{{ t('selected_recipients') }}:</label>
                    <div class="item-container p-2 flex-wrap">
                        <p class="p-0 m-0" v-if="selectedItems < 1">{{
                            t('no_recipients_selected') }}</p>
                        <div v-for="(item, index) in selectedItems" :key="index">
                            <button class="text-center btn btn-outline-primary m-1"
                                @click="toggleRecipientSelection(item, false)">
                                <span>{{ item.name }} {{ item.last_name }} ( {{ item.email ? item.email : 'no email' }}
                                    )</span>
                                <i class="bi bi-x-circle-fill m-2"></i> </button>
                        </div>

                    </div>
                </div>

                <div class="form-group">
                    <label for="emailTopic">{{ t('email_topic') }}:</label>
                    <input type="text" id="emailTopic" v-model="emailTopic" class="form-control" required />
                </div>

                <div class="form-group">
                    <label for="emailBody">{{ t('email_body') }}:</label>
                    <textarea id="emailBody" v-model="emailBody" class="form-control" rows="10" required></textarea>
                </div>


                <button class="btn btn-primary" @click="showModal = true">Preview Email</button>

            </div>

            <BaseModal :open="showModal" @close="showModal = !showModal">
                <div class="modal-footer ">
                    <button style="color: white" class="btn btn-primary" @click="sendEmail()">
                        {{ t('send_email') }}
                    </button>

                    <button style="color: white" class="btn btn-danger" @click="showModal = false">
                        {{ t('orphan_modal_cancel_button') }}

                    </button>
                </div>
                <hr />
                <div class="modal-body p-0">
                    <div class="email-template">
                        <br />
                        <div class="d-flex flex-row align-items-center justify-content-between ">

                            <img src="../../assets/Logo.jpg" class="d-block"
                                style="height: 100px; width:150px; padding:0px;" ref="image" />
                            <h3 class="text-danger">Association Swiss-Group</h3>
                        </div>
                        <br />
                        <br />
                        <div class="text-left email-content">
                            <h5 v-if="email_lang == 'en'">Dear [Recepient Name],</h5>
                            <h5 v-if="email_lang == 'fr'">Cher(e) [Recepient Name],</h5>

                            <br />
                            <p>{{ emailBody }}</p>

                            <br />
                            <br />
                            <p v-if="email_lang == 'fr'">Cordialement,</p>

                            <p v-if="email_lang == 'en'">Kind Regards,</p>
                        </div>

                        <div class="footer my-2">
                            <div class="d-flex flex-column align-items-start ">
                                <h5 class="text-start">Association Swiss-Group</h5>
                                <img src="../../assets/signature.png" class=" d-block"
                                    style="height: 100px; width: 200px; padding:0px;" />
                            </div>
                            <div class="p-2  bg-danger">
                                <p class=" text-center mb-0 fw-bold text-light">Association Swiss-Group</p>
                                <p class="mb-0 text-center text-light">
                                    Case postale 36-CH–1218 Le Grand-Saconnex
                                </p>
                                <p class="mb-0 text-center text-light">
                                    Banque Alternative Suisse – Account N° 46-110-7 - IBAN: CH10 0839 0034 6485 1000 6
                                </p>
                            </div>
                        </div>
                        <br />
                    </div>

                </div>

            </BaseModal>
        </div>

    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import recipientSelect2 from './recipientSelect2.vue';
import { EmailStore } from '@/stores/emailStore';
import { storeToRefs } from 'pinia';
import BaseModal from '../BaseModal.vue';
import { NotificationStore } from '@/stores/notification';

export default {
    name: "EmailPage",
    components: {
        recipientSelect2,
        BaseModal,
    },
    data() {
        return {
            selected_public_donators: [],
            selected_private_donators: [],
            showModal: false,
            emailTopic: "",
            emailBody: "",
            recipients: "",
            languages: [{ key: "en", label: "English" }, { key: "fr", label: "French" }],
            email_lang: "en"
        };
    },
    watch: {
        private_donators: {
            deep: true
        },
        public_donators: {
            deep: true
        }

    },
    computed: {
        selectedItems() {
            return [...this.public_donators.filter((donator) => donator.selected), ...this.private_donators.filter((donator) => donator.selected)]
        }
    },

    setup() {
        const { t } = useI18n({});
        const emailStore = EmailStore()
        const { public_donators, private_donators } = storeToRefs(emailStore);
        const notificationStore = NotificationStore();
        return {
            t,
            public_donators, private_donators, emailStore, notificationStore
        }
    },
    async created() {
        await this.emailStore.getPrivateDonators();
        await this.emailStore.getPublicDonators();
    },
    methods: {

        toggleRecipientSelection(donator) {
            donator.selected = !donator.selected

        },
        toggleSelectAll(is_selected, is_public) {
            if (is_public) {
                this.public_donators = this.public_donators.map((donator) => {
                    return {
                        ...donator, selected: is_selected
                    }
                })
            } else {
                this.private_donators = this.private_donators.map((donator) => {
                    return {
                        ...donator, selected: is_selected
                    }
                })
            }
        },
        toggleVIPSelection(is_selected) {
            this.private_donators = this.private_donators.map((donator) => {
                if (donator.type === 1) {
                    return {
                        ...donator, selected: is_selected
                    }
                } return donator
            })
        },
        toggleRegularSelection(is_selected) {
            this.private_donators = this.private_donators.map((donator) => {
                if (donator.type !== 1) {
                    return {
                        ...donator, selected: is_selected
                    }
                } return donator
            })
        },

        async sendEmail() {

            let recipients = this.selectedItems.map((donator) => { return { name: donator.name + ' ' + `${donator.last_name ? donator.last_name : ''}`, email: donator.email } })
            // You can implement your email sending logic here
            const emailData = {
                subject: this.emailTopic,
                body: this.emailBody,
                recipients: recipients,
                email_language: this.email_lang
            };
            let response = await this.emailStore.sendCampaigns(emailData)
            if (response.status) {
                this.showModal = false;
            }
            // Call api

            let message = response.status ? this.t('email_sent_alert') : this.t('email_not_sent_alert');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        },
    },
};
</script>
  
<style>
.item-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.email-template {
    height: 85vh
}

.footer {
    /* position: absolute;
    bottom: 0; */
    /* Stick the footer to the bottom of the parent (.email-template) */
    width: 100%;
    /* Make the footer full width */
    height: 250px;
    /* background-color: red; */
}

.email-content {
    min-height: 40vh
}

/* Add your CSS styles here */
</style>
  