<template>
    <div class="mx-3">
        <BaseModal></BaseModal>
        <div class="card text-center">
            <div class="card-header">

                <ul class="nav nav-tabs card-header-tabs">
                    <li v-for="tab in tabs" :key="tab.id" @click="selectTab(tab.id)" class="nav-item">
                        <a class="nav-link" :class="{ 'active': selectedTab === tab.id }" href="#"> {{ tab.label }}</a>

                    </li>

                </ul>
            </div>
            <div class="card-body">
                <component :is="selectedTabComponent"></component>

            </div>
        </div>
    </div>
</template>

<script>
import { UserStore } from '@/stores/userStore';
import { storeToRefs } from 'pinia';
import BaseModal from '../BaseModal.vue';
import UsersCard from './UsersCard.vue';
import PermissionsCard from './PermissionsCard.vue';
import { useI18n } from 'vue-i18n';
import RolesCard from './RolesCard.vue';

export default {
    name: 'AdminPanel',
    components: {
        BaseModal,
        UsersCard,
        PermissionsCard,
        RolesCard
    },
    setup() {
        const userStore = UserStore();
        const { user_info } = storeToRefs(userStore);
        const { t } = useI18n({});
        return {
            user_info,
            userStore,
            t,


        }

    },
    data() {
        return {

            selectedTab: 1,
            tabs: [
                { id: 1, label: this.t('users'), component: UsersCard },
                { id: 2, label: this.t('roles'), component: RolesCard },
                { id: 3, label: this.t('permissions'), component: PermissionsCard },
            ],
        }
    },
    computed: {
        selectedTabComponent() {
            const selectedTab = this.tabs.find(tab => tab.id === this.selectedTab);
            return selectedTab ? selectedTab.component : null;
        },
    },

    methods: {

        selectTab(tabId) {
            this.selectedTab = tabId;
        },

    },

}
</script>

<style scoped>
.profileImg {
    height: 200px;
    width: 200px
}

.error-message {
    color: red;
    margin-bottom: 20px;
}
</style>