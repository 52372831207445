import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const OrphanStore = defineStore("orphanStore", {
  state: () => ({
    orphans: undefined,
    families: undefined,
  }),

  getters: {
    allOrphans: (state) => state.orphans,
    allFamily: (state) => state.families,
  },

  actions: {
    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async getFamilies(page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/family", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
          },
        })
        .then((response) => {
          this.families = response.data;
          return { status: true };
        })
        .catch((exception) => {
          if (exception.response) {
            checkResponse(exception.response.status);
          }
          return { status: false, message: "Not Found!" };
        });
    },

    async updateOrphan(data) {
      const url = "api/orphans/" + data.id;
      if (data.treatment_type == "null") data.treatment_type = null;
      return axios
        .put(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async addOrphan(data) {
      const url = "api/orphans";
      return axios
        .post(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async deleteOrphan(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = "api/orphans/" + id;
      return axios
        .delete(url)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async search(key, page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/orphans/search", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            key: key,
          },
        })
        .then((response) => {
          this.orphans = response.data;
          return { status: true, data: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          this.orphans.data = [];
          return { status: false, message: "Not Found!" };
        });
    },

    async searchOrphan(ngo_id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/orphans/search", {
          params: {
            key: ngo_id,
            search_column: "ngo_id",
          },
        })
        .then((response) => {
          return { status: true, data: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async getOrphans(
      page_number,
      per_page,
      family_id,
      civility,
      sort_column,
      sort_by,
      treatment_type
    ) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/orphans", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            filters: {
              family_id: family_id == "null" ? null : family_id,
              civility: civility == "null" ? null : civility,
              treatment_type: treatment_type == "null" ? null : treatment_type,
            },
          },
        })
        .then((response) => {
          this.orphans = response.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
