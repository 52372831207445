<template>
    <Bar id="my-chart-id" :options="data.chartOptions" :data="data.chartData" />
</template>
  
<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { StatsStore } from '@/stores/statsStore';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'DonationsGraph',
    components: { Bar },
    setup() {
        const statsStore = StatsStore();
        const { graphData } = storeToRefs(statsStore);
        const { t } = useI18n();
        return {
            statsStore,
            graphData,
            t
        };
    },

    computed: {

        data() {
            return {
                chartData: {
                    labels: [this.t('jan'), this.t('feb'), this.t('mar'), this.t('apr'), this.t('may'), this.t('jun'), this.t('jul'), this.t('aug'), this.t('sep'), this.t('oct'), this.t('nov'), this.t('dec')],
                    datasets: [{
                        label: `${this.t('don_label')} ${this.graphData?.sign ? `${this.t('in')} ${this.graphData?.sign ? this.graphData?.sign : ""}` : ""}`,
                        data: this.graphData?.data, backgroundColor: '#98CFFA', borderRadius: 10, borderColor: "#36A2EB", borderWidth: 2
                    }]
                },
                chartOptions: {
                    responsive: true,

                    scales: {
                        y: {
                            ticks: {
                                color: '#0D6EFD',
                                textStrokeWidth: 10,

                            }
                        },
                        x: {
                            ticks: {
                                color: 'red',
                                textStrokeWidth: 10
                            }
                        }
                    },
                }
            }
        }
    }
}
</script>