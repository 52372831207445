<template>
    <div v-if="states != {}">
        <div class="modal-header">
            <h5 class="modal-title">{{ t('donators_modal_update_button') }}</h5>
        </div>
        <div class="modal-body" style="margin-top: 1%;">
            <form>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_email') }}*</label>
                        <input v-model="states.personal.email" type="email" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_email_place_holder')" />
                        <h6 style="color: red" v-if="states.personal.email?.length == 0 || !states.personal.email"
                            id="emailHelp" class="form-text text-muted">
                            {{ t('donators_email_empty') }}
                        </h6>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_name') }}*</label>
                        <input v-model="states.personal.name" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_name_placeholder')" />
                        <h6 style="color: red" v-if="states.personal.name?.length == 0 || !states.personal.name"
                            id="emailHelp" class="form-text text-muted">
                            {{ t('donators_name_empty') }}
                        </h6>
                    </div>

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_phone') }}*</label>
                        <input v-model="states.personal.phone" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_phone_placeholder')" />
                    </div>

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail5">{{ t('orphan_modal_country') }}</label>
                        <select v-model="states.address.country" class="form-control" id="familyFilter">
                            <option value="">{{ t('select_country') }}</option>
                            <option v-for="country in countries" :value="country.alpha2Code" :key="country.alpha2Code">{{
                                languageStore.getLanguage == 'en' ? country.name : country.translations.fr }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_county') }}</label>
                        <input v-model="states.address.county" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_table_county_place_holder')" />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_address') }}</label>
                        <input v-model="states.address.address" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_address_place_holder')" />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donators_table_zipcode') }}</label>
                        <input v-model="states.address.zipcode" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('donators_adress_zipcode')" />
                    </div>
                </div>
            </form>
        </div>
        <div
            :class="canCheck ? 'w-100 d-flex flex-row justify-content-between' : 'w-100 d-flex flex-row justify-content-end'">
            <div class="form-check form-switch d-flex align-items-center" v-if="canCheck">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                    :checked="states.personal.checked" @click="states.personal.checked = !states.personal.checked">
                <label class="p-0 flex-grow-1 m-0" for="flexSwitchCheckChecked">{{ t('checked') }}</label>
            </div>
            <div class="modal-footer">
                <button
                    :disabled="field_states.is_exist || JSON.stringify(original_states) === JSON.stringify(states) || states.personal.email?.length == 0 || states.personal.name?.length == 0"
                    @click="update" type="button" style="color: white" class="btn btn-primary add-button">
                    {{ t('donators_modal_update_button') }}
                </button>

                <button type="button" style="color: white" class="btn btn-danger" @click="close">
                    {{ t('orphan_modal_cancel_button') }}

                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { NotificationStore } from '@/stores/notification';
import { CurrencyStore } from '@/stores/currency';
import { DonatorStore } from "@/stores/donatorStore";
import { CountriesStore } from '@/stores/countries';
import { UserStore } from '@/stores/userStore';
import { OrphanEditStates } from '@/stores/orphanEditStates';

export default {
    name: 'EditPublicDonators',
    data() {
        return {
            canCheck: false
        };
    },
    setup() {
        const donatorStore = DonatorStore();
        const countriesStore = CountriesStore();
        const { countries } = storeToRefs(countriesStore);
        const notificationStore = NotificationStore();
        const userStore = UserStore();
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const languageStore = LanguageStore();
        const currencyStore = CurrencyStore();
        const orphanEditStates = OrphanEditStates();
        const { currencies } = storeToRefs(currencyStore);
        const { states, civility_options, type_options, field_states,
            original_states } = storeToRefs(donatorStore);
        const { t } = useI18n({});
        return {
            t, is_loading, languageStore
            , currencyStore, currencies, loadingStore,
            donatorStore,
            notificationStore, countries, countriesStore, civility_options, type_options,
            states, field_states, original_states, userStore, orphanEditStates
        };
    },
    watch: {
        currencies: {
            deep: true
        },
        field_states: {
            deep: true,
        },
        states: {
            handler() {
                for (let address in this.states.address) {
                    if (this.states.address[address] == "")
                        this.states.address[address] = null;
                }
            },
            deep: true,
        },

        isDisabled: {
            deep: true,
        }
    },
    async created() {
        this.canCheck = this.userStore.checkIfHasPermission('check.entries.update')

    },
    methods: {
        amountEmpty() {
            if (this.states.amount == 0)
                this.field_states.amount_empty = false;
            else
                this.field_states.amount_empty = true;

        },
        close() {
            this.donatorStore.cleanStates();
            this.$emit('close');
        },

        async update() {
            this.loadingStore.setLoading(true);
            let response = await this.donatorStore.update(this.states, true);
            if (response.status) {
                if (this.canCheck) {
                    await this.orphanEditStates.checkEntry('public_donators', this.states.personal.id, this.states.personal.checked)
                }
                this.$emit('status', true, true);
                this.close();
            }
            let error_message = undefined;
            if (response.data?.data?.error) {
                error_message = response.data.data.error
            }
            // Call api
            this.loadingStore.setLoading(false);
            let message = response.status ? this.t('sponsorships_type_updated') : `${this.t('sponsorships_type_not_updated')}. ${error_message}`;
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        },


        async check_button() {
            this.$emit('check', this.ngo_id, this.name, this.last_name);
        },

    },

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

label {
    float: left;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>
  