<template>
    <BaseModal :open="isOpen" @close="isOpen = !isOpen">
        <CreateUserModal @close="isOpen = !isOpen" :is_login='false'></CreateUserModal>
    </BaseModal>
    <div class=" m-3 py-2">
        <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-row w-75">

                <div class="mx-2">
                    <select v-model="selected_sort_by_options" class="form-control" id="familyFilter" @change="getData">
                        <option v-for="option in sort_by_options" :value="option.value" :key="option.value">{{
                            t(option.label)
                        }}
                        </option>
                    </select>
                </div>

                <div class="mx-2">
                    <select v-model="selected_sort_column" class="form-control" @change="getData">
                        <option v-for="option in sort_column_options" :value="option.value" :key="option.value">{{
                            t(option.label) }}
                        </option>
                    </select>
                </div>
            </div>
            <br />
        </div>
        <br />
        <div class="row">
        </div>
        <div class="table-responsive">
            <br />
            <h2 v-if="permissions != undefined && permissions.length == 0">Not Found</h2>
            <table v-if="permissions != undefined && permissions.length > 0" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th data-field="id" data-sortable="true">Id</th>
                        <th data-field="name" data-sortable="true">{{ t('orphan_table_column_name') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{
                            t('orphan_table_column_registration_date') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_update_date') }}
                        </th>


                    </tr>
                </thead>
                <tbody id="myTable" v-if="permissions != undefined">
                    <tr v-for="permission in permissions" :key="permission.id">
                        <td>{{ permission.id }}</td>
                        <td>{{ permission.name }}</td>
                        <td>{{ generateDate(permission.created_at) }}</td>
                        <td>{{ generateDate(permission.updated_at) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-sm-1">
                <select v-model="selected_per_page" id="pageSize" class="form-control d-inline-block w-auto"
                    @change="perPageChange">
                    <option v-for="option in per_page_options" :value="option.value" :key="option.value">{{ (option.label)
                    }}
                    </option>
                </select>
            </div>
            <div class="col-sm">
                <div class="float-right">
                    <button class="btn btn-outline-secondary" :disabled="page.value == 1" @click="changePage(false)">
                        <i class="fas fa-angle-left"></i> {{ t('orphan_prev_button') }}
                    </button>
                    <button class="btn btn-outline-secondary" :disabled="page.value + 1 > page.count"
                        @click="changePage(true)">
                        {{ t('orphan_next_button') }} <i class="fas fa-angle-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="float-right mr-3">
            <span>{{ t('orphan_table_footer_page') }} {{ page.value }} </span>
            <span>{{ t('orphan_table_footer_total') }} {{ page.count }}</span>
        </div>
        <div class="table-responsive">
            <br />
            <table v-if="permissionDescriptions != undefined && permissionDescriptions.length > 0"
                class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th data-field="name" data-sortable="true">{{ t('orphan_table_column_name') }}</th>
                        <th data-field="registration_date" data-sortable="true">
                            Description </th>


                    </tr>
                </thead>
                <tbody id="myTable" v-if="permissionDescriptions != undefined">
                    <tr v-for="permission in permissionDescriptions" :key="permission.name">
                        <td>{{ permission.name }}</td>
                        <td>{{ t(permission.description) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
    
<script>

import { LoadingStore } from '@/stores/loading';
import { PermissionStore } from "@/stores/permissions";
import { storeToRefs } from 'pinia';
import { NotificationStore } from '@/stores/notification';
import { ref } from "vue";
import { useI18n } from 'vue-i18n';

import BaseModal from '../BaseModal.vue';
import CreateUserModal from './Modal/CreateUserModal.vue';

export default {
    name: 'PermissionsCard',

    components: {
        BaseModal, CreateUserModal

    },

    setup() {
        const loadingStore = LoadingStore();
        const permissionStore = PermissionStore();
        const { permissions, page, permissionDescriptions } = storeToRefs(permissionStore);
        const notificationStore = NotificationStore();
        const { is_loading } = storeToRefs(loadingStore);
        const isOpen = ref(false);
        const { t } = useI18n({});
        return {
            loadingStore,
            permissionStore,
            permissions,
            notificationStore,
            is_loading,
            page,
            permissionDescriptions,
            isOpen, t
        };

    },

    watch: {

        permissions: {

            deep: true
        },
        page: {
            deep: true
        }


    },
    data() {
        return {


            sort_column_options: [
                { label: 'orphan_filter_created_at', value: 'created_at' },
                { label: 'orphan_filter_updated_at', value: 'updated_at' },
            ],
            selected_sort_column: 'created_at',
            sort_by_options: [
                { label: 'orphan_filter_order_by_desc', value: '3' },
                { label: 'orphan_filter_order_by_asc', value: '2' },
            ],
            per_page_options: [
                { label: '5', value: '5' },
                { label: '10', value: '10' },
                { label: '15', value: '15' },
            ],
            selected_per_page: 5,
            selected_sort_by_options: '3',
            isModalOpen: false,
            editIsOpen: false,
            timeout: undefined,
            search: '',

        };
    },

    async created() {
        let params = this.parseURLParamaters();
        this.page.value = params.page;
        this.selected_per_page = params.per_page;
        this.selected_sort_column = params.sort_column;
        this.selected_sort_by_options = params.sort_by;
        await this.getData();
    },

    methods: {
        parseURLParamaters() {
            const url_params = new URLSearchParams(window.location.search);
            let params = {
                page: url_params.get('page') ? url_params.get('page') : 1,
                per_page: url_params.get('per_page') ? url_params.get('per_page') : 10,
                sort_column: url_params.get('sort_column') ? url_params.get('sort_column') : 'created_at',
                sort_by: url_params.get('sort_by') ? url_params.get('sort_by') : '2',
            }
            return params;
        },
        changePage(next) {
            this.page.value = next ? this.page.value + 1 : this.page.value - 1;

            this.getData();
        },
        fetch(status = false, is_update = false) {
            if (status) {
                let status = false;
                if (this.selected_sort_column == 'updated_at' && !is_update)
                    status = true;
                this.getData(undefined, status);
            }
        },


        perPageChange() {
            this.page.value = 1;
            this.getData();
        },

        generateDate(date, include_date = false) {
            let d = new Date(date);
            const options = { weekday: 'short', month: 'short', day: 'numeric' };
            if (include_date) {
                delete options.weekday;
                options.year = 'numeric';
            }
            const formattedDate = d.toLocaleDateString('en-US', options);
            return formattedDate;
        },
        async getData() {
            this.loadingStore.setLoading(true);
            await this.permissionStore.get(this.page.value, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
            this.loadingStore.setLoading(false);

        },


        openModal() {
            this.isOpen = true
        },
        closeModal(is_edit = false) {
            if (is_edit)
                this.editIsOpen = false;
            else
                this.isOpen = false;
        },




    }

}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 767px) {}

/* .edit-button {
    margin-right: 2%;
} */

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>
    