<template>
    <div>
        <div class="modal-header">
            <h5 class="modal-title">{{ t('orphan_modal_add_button') }}</h5>
        </div>
        <div class="modal-body" style="margin-top: 1%;">
            <form>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('orphan_modal_ngo_id') }}*</label>
                        <input v-model="ngo_id" @keyup="searching" @paste.once="pastedetected" type="email"
                            class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            :placeholder="t('orphan_modal_ngo_id_place_holder')" />
                        <h6 style="color: red" v-if="isDisabled" id="emailHelp" class="form-text text-muted">
                            {{ t('orphan_ngo_id_exist') }}
                        </h6>
                        <h6 style="color: red" v-if="!isDisabled && ngo_id_disable" id="emailHelp"
                            class="form-text text-muted">
                            {{ t('orphan_ngo_id_field_can_not_empty') }}
                        </h6>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail2">{{ t('orphan_modal_civility') }}</label>
                        <select v-model="selected_civility" class="form-control" id="familyFilter">
                            <option v-for="option in civility_options" :value="option.value" :key="option.value">{{
                                t(option.label) }}
                            </option>
                        </select>
                    </div>

                </div>
                <div class="form-row">

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail3">{{ t('orphan_modal_name') }}*</label>
                        <input v-model="name" @keyup="check_button" class="form-control" id="exampleInputEmail3"
                            aria-describedby="emailHelp" :placeholder="t('orphan_modal_name_place_holder')" />
                        <h6 style="color: red" v-if="name_disable" id="emailHelp" class="form-text text-muted">
                            {{ t('orphan_name_field_can_not_empty') }}
                        </h6>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail4">{{ t('orphan_modal_lastname') }}*</label>
                        <input v-model="last_name" @keyup="check_button" type="email" class="form-control"
                            id="exampleInputEmail4" aria-describedby="emailHelp"
                            :placeholder="t('orphan_modal_lastname_place_holder')" />
                        <h6 style="color: red" v-if="last_name_disable" id="emailHelp" class="form-text text-muted">
                            {{ t('orphan_last_name_field_can_not_empty') }}
                        </h6>
                    </div>
                </div>
                <div class="form-row">

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail5">{{ t('orphan_modal_country') }}</label>
                        <select v-model="selected_country" class="form-control" id="familyFilter">
                            <option value="">{{ t('select_country') }}</option>
                            <option v-for="country in countries" :value="country.alpha2Code" :key="country.alpha2Code">{{
                                languageStore.getLanguage == 'en' ? country.name : country.translations.fr }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="birthday">{{ t('orphan_modal_dob') }}</label>
                        <input v-model="dob" type="date" class="form-control" id="exampleInputEmail6"
                            aria-describedby="emailHelp" />
                    </div>

                </div>
                <div class="form-row">
                    <!-- <div class="from-group col-md-6">
                        <label for="exampleInputEmail6">{{ t('orphan_modal_phone') }}</label>
                        <input v-model="phone" type="phone" class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp" :placeholder="t('orphan_modal_phone_place_holder')" />
                    </div> -->
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail7">{{ t('orphan_modal_family') }}</label>
                        <select v-model="selected_family" class="form-control" id="familyFilter"
                            v-if="families != undefined && families.data.length > 0">
                            <option :value="null">{{ t('orphan_filter_civility_undefined') }}</option>
                            <option v-for="option in families.data" :value="option.id" :key="'family' + option.id"> {{
                                option.name }} {{ '('
        + option.id + ')' }}</option>
                        </select>
                    </div>

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail7">{{ t('orphan_modal_treatmnet') }}</label>

                        <select v-model="treatment_type" class="form-control" id="familyFilter">
                            <option v-for="option in treatment_options" :value="option.value" :key="option.value">{{
                                t(option.label) }}
                            </option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button :disabled="isDisabled || first_disable || name_disable || last_name_disable" @click="addOrphan"
                type="button" style="color: white" class="btn btn-primary add-button">
                {{ t('orphan_modal_add_button') }}
            </button>

            <button type="button" style="color: white" class="btn btn-danger" @click="close">
                {{ t('orphan_modal_cancel_button') }}

            </button>
        </div>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { NotificationStore } from '@/stores/notification';
import { OrphanStore } from "@/stores/orphan";
import { CountriesStore } from '@/stores/countries';

export default {
    name: 'CreateOrphan',
    props: ['isDisabled', 'first_disable', 'name_disable', 'last_name_disable', 'ngo_id_disable'],
    data() {
        return {
            data: {
                civility_options: [

                ],
                treatment_options: [

                ],
                selected_treatment: null,
                selected_country: null,
                selected_civility: null,
                selected_family: null,
                enable_add: false,
                treatment_type: 1,
                country: '',
                timeout: undefined,
                ngo_id: null,
                name: '',
                last_name: '',
                dob: undefined,
                phone: undefined,
                family: undefined,
            }
        };
    },

    watch: {
        isDisabled: {
            deep: true,
        }
    },
    async created() {
        this.name = null;
        this.last_name = null;
        this.phone = null;
        this.dob = null;
        this.treatment_type = 1;
        this.selected_family = null;
        this.selected_country = 'SY';
        this.treatment_options = [
            { label: 'Undefined', value: null },
            { label: "no_treatment", value: 1 },
            { label: 'Medical', value: 2 },
            { label: "Finished", value: 3 },
        ];
        this.civility_options = [
            { label: 'orphan_filter_civility_male', value: '0' },
            { label: 'orphan_filter_civility_female', value: '1' },
            { label: 'orphan_filter_civility_undefined', value: '-2' }];
        this.selected_civility = -2;
        this.loadingStore.setLoading(true);
        await this.orphanStore.getFamilies();
        this.loadingStore.setLoading(false);
        this.enable_add = false;
        this.ngo_id = "";
    },
    methods: {
        close() {
            this.$emit('close');
        },

        async addOrphan() {
            this.loadingStore.setLoading(true);
            let data = {
                ngo_id: this.ngo_id,
                name: this.name,
                last_name: this.last_name,
                location: this.selected_country,
                phone: this.phone,
                family_id: this.selected_family,
                dob: this.dob,
                treatment_type: this.treatment_type,
                civility: this.selected_civility
            };
            let response = await this.orphanStore.addOrphan(data);
            if (response.status) {
                this.close();
                this.$emit('status', true);
            }
            // Call api
            this.loadingStore.setLoading(false);
            let message = response.status ? this.t('orphan_added_alert') : this.t('orphan_not_added_alert');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        },
        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.check_is_exist, 150);
        },

        pastedetected(eventdata) {
            this.search = "";
            this.ngo_id = eventdata.clipboardData.getData("Text");
            this.searching();
        },

        async check_button() {
            this.$emit('check', this.ngo_id, this.name, this.last_name);
        },

        async check_is_exist() {
            this.$emit('search', this.ngo_id);
            this.$emit('check', this.ngo_id, this.name, this.last_name);
        }

    },
    setup() {
        const orphanStore = OrphanStore();
        const notificationStore = NotificationStore();
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const { families } = storeToRefs(orphanStore);
        const countriesStore = CountriesStore();
        const { countries } = storeToRefs(countriesStore);
        const languageStore = LanguageStore();

        const { t } = useI18n({});
        return { t, is_loading, languageStore, loadingStore, countries, notificationStore, orphanStore, families };
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

label {
    float: left;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>
  