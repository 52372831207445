<template>
    <div class="change-password p-0">
        <div class="modal-header">
            <h4 class="modal-title">{{ t('enter_email') }}</h4>
        </div>
        <div class="modal-body" style="margin-top: 1%;"></div>
        <form @submit.prevent="submitForm">
            <div class="form-group d-flex flex-column align-items-start">
                <label for="email">{{ t('email') }} *</label>
                <input v-model="email" type="text" id="email" required class="form-control" />
            </div>
            <div class="text-center mt-2" v-if="disableButton">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="error-message">{{ forgotPasswordError }}</div>
            <div class="modal-footer">
                <button @click="handleForgetPassword" type="submit" style="color: white" class="btn btn-primary add-button"
                    :disabled="disableButton">
                    {{ t('send_otp') }}
                </button>
                <button type="button" style="color: white" class="btn btn-danger" @click="close" :disabled="disableButton">
                    {{ t('orphan_modal_cancel_button') }}

                </button>
            </div>

        </form>

    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { UserStore } from '@/stores/userStore';
import { NotificationStore } from '@/stores/notification';

export default {
    name: 'ForgotPasswordModal',

    data() {
        return {
            disableButton: false,
            email: "",


        };
    },

    async created() {
        this.clearModal()
    },
    methods: {
        clearModal() {
            this.email = ''
            this.forgotPasswordError = ""
        },
        close() {
            this.clearModal()
            this.$emit('close');

        },
        async handleForgetPassword() {

            let data = {
                "email": this.email,
            }

            this.disableButton = true
            this.forgotPasswordError = ""
            let response = await this.userStore.handleForgotPassword(data);
            if (response.status) {
                this.$emit('status', true);
                this.close();
            }
            this.disableButton = false
            //todo: handle response messages
            let message = response.status ? this.t('otp_success') : this.t('otp_fail');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        }

    },
    setup() {
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const languageStore = LanguageStore();
        const { t } = useI18n({});
        const notificationStore = NotificationStore()
        const userStore = UserStore();
        const { forgotPasswordError } = storeToRefs(userStore);
        return { t, is_loading, languageStore, loadingStore, forgotPasswordError, userStore, notificationStore };
    },
}
</script>
  
<style scoped>
.error-message {
    color: red;
    margin-bottom: 20px;
}
</style>