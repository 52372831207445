export default {
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "create_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Role"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "role_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Name"])},
  "role_not_created_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The role could not be created!"])},
  "role_created_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The role has been created successfully!"])},
  "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
  "select_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the permissions that this role will have access to:"])},
  "update_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Role"])},
  "role_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete this role?"])},
  "role_delete_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (", _interpolate(_named("id")), ") will be deleted, you won't be able to revert this action!"])},
  "assign_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Role"])},
  "role_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role has been changed successfully"])},
  "role_change_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The role could not be updated!"])},
  "role_updated_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The role has been updated successfully"])},
  "role_not_updated_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The role could not be updated"])},
  "entries_to_be_checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries to be checked:"])},
  "orphans_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphans Table"])},
  "donators_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donators Table"])},
  "public_donators_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Donators Table"])},
  "campaigns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns Table"])},
  "campaign_donations_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Campaign Donations Table"])},
  "campaign_types_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Types Table"])},
  "orphans_family_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Orphans Table"])},
  "enrolled_sponsorships_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorships Table"])},
  "sponsorships_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship Types Table"])},
  "missing_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing Donations:"])},
  "no_missing_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Missing Donations"])},
  "user_delete_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been deleted successfully"])},
  "index_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gets all the data from the table"])},
  "show_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gets one entry from the table"])},
  "search_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gets the data from the table according to search options"])},
  "create_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creates a new entry in the table"])},
  "destroy_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletes the entry from the table, this change cannot be reverted!"])},
  "update_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updates an entry in the table"])},
  "upload_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploads a csv file to the table"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
}