<template>
  <div class="d-flex justify-content-between align-items-center w-100 px-3  ">
    <div>

      <h4 class="m-0 ">{{ t(this.title) }}</h4>
    </div>
    <div class="d-flex flex-row">

      <div class="dropdown d-flex align-items-center ">

        <button class="btn dropdown-toggle  d-inline-flex align-items-center" type="button" id="dropdownUser1"
          data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
          <i class=" d-inline-flex align-items-center fs-5 bi bi-person px-1 user-icon"></i>{{
            this.user_info.name }}
        </button>
        <router-link :to="'/app/notifications/'">
          <button type="button" class="btn mr-3">
            <i class="bi bi-bell"></i>
            <!-- <span
              class="position-absolute top-0 start-40 bottom-40  badge rounded-pill badge-danger">
              {{ notifications.length }} -->
            <span class="visually-hidden">unread messages</span>
            <!-- </span> -->
          </button>
        </router-link>
        <ul class="dropdown-menu  text-small shadow">
          <li><a class="dropdown-item" href="#"> <router-link :to="'/app/profile/'" class="nav-link px-0">
                Profile</router-link></a></li>

          <li v-if="permissions.find((permission) => permission.name == 'auth.register')"><a class="dropdown-item"
              href="#">
              <router-link :to="'/app/admin-panel/'" class="nav-link px-0">
                Admin Panel</router-link></a></li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li><a class="dropdown-item" href="#" @click="signOut">Sign out</a></li>
        </ul>
      </div>

      <LanguageSwitcher />
    </div>

  </div>
  <hr class="mx-3 my-2" />
</template>
  
<script>
import { LanguageStore } from '@/stores/language';
import { UserStore } from '@/stores/userStore';
import { storeToRefs } from 'pinia';
import LanguageSwitcher from './LanguageSwitcher.vue';
import { TitleStore } from '@/stores/titleStore';
import { useI18n } from 'vue-i18n';

export default {
  name: "HeaderBar",
  components: {
    LanguageSwitcher

  },

  setup() {
    const userStore = UserStore();
    const titleStore = TitleStore()
    const { t } = useI18n({});
    const languageStore = LanguageStore();
    const { title } = storeToRefs(titleStore)
    const { user_info, notifications, permissions } = storeToRefs(userStore);
    return {
      languageStore,
      notifications,
      permissions,
      title,
      titleStore,
      user_info,
      userStore,
      t
    }
  },

  watch: {
    '$route': {
      handler() {
        this.titleStore.createTitle(this.$route.path);
      },
      immediate: true,
      deep: true
    },
    permissions: {
      deep: true
    },
  },

  data() {
    return {
      selectedLanguage: 'en',
      availableLanguages: {
        en: 'English',
        fr: 'French'
      },

      flagIcons: {
        en: require('../assets/flags/gb.svg'),
        fr: require('../assets/flags/fr.svg')
      }
    }
  },

  async created() {
    let language = await this.languageStore.getLangauge();
    this.selectLanguage(language);
    await this.userStore.getInfo();
    // await this.userStore.getNotifications()
  },

  methods: {
    selectLanguage(language) {
      this.selectedLanguage = language;
      this.changeLanguage();
    },
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage;
      this.languageStore.setLanguage(this.selectedLanguage);
    },
    getFlagIcon(language) {
      return this.flagIcons[language];
    },
    signOut() {
      this.userStore.logout()
    }
  },
}
</script>

<style scoped></style>

  