<template>
    <div class="dropdown dd">
        <button class="btn dropdown-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img :src="getFlagIcon(selectedLanguage)" :alt="selectedLanguage" class="flag-icon">
            <!-- {{ availableLanguages[selectedLanguage] }} -->
        </button>
        <div class="dropdown-menu" aria-labelledby="languageDropdown">
            <a class="dropdown-item" href="#" v-for="(language, key) in availableLanguages" :key="key"
                @click="selectLanguage(key)">
                <img :src="getFlagIcon(key)" :alt="key" class="flag-icon">
                {{ language }}
            </a>
        </div>
    </div>
</template>
    
<script>
import { LanguageStore } from '@/stores/language';

export default {
    name: "LanguageSwitcher",
    setup() {
        const languageStore = LanguageStore();
        return {
            languageStore
        }
    },
    data() {
        return {
            selectedLanguage: 'en',
            availableLanguages: {
                en: 'English',
                fr: 'French'
            },

            flagIcons: {
                en: require('../assets/flags/gb.svg'),
                fr: require('../assets/flags/fr.svg')
            }
        }
    },
    async created() {
        let language = await this.languageStore.getLangauge();
        this.selectLanguage(language);
    },

    methods: {
        selectLanguage(language) {
            this.selectedLanguage = language;
            this.changeLanguage();
        },
        changeLanguage() {
            this.$i18n.locale = this.selectedLanguage;
            this.languageStore.setLanguage(this.selectedLanguage);
        },
        getFlagIcon(language) {
            return this.flagIcons[language];
        }
    }
}
</script>
    
<style>
.flag-icon {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-right: 5px;
}
</style>
    
  