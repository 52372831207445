<template>
    <div class="notification-page">
        <ul class="list-group list-group-light m-3 w-25">
            <div class="list-group-item text-start mb-0 text-secondary">
                <h4 class="mb-0 pb-0">{{ t('entries_to_be_checked') }}</h4>
            </div>
            <li class="list-group-item d-flex justify-content-between align-items-center" v-for="entry in checkEntries"
                :key="entry.id">
                <router-link :to="entry.route" class="text-left">
                    {{ `${t(entry.name)}:` }}
                </router-link>
                <span :class="entry.count > 0 ? 'badge badge-pill badge-danger' : 'badge badge-pill badge-primary'"> {{
                    entry.count
                }}</span>
            </li>
        </ul>
        <!-- <ul class="list-group list-group-light m-3 mx-0 w-75">
            <div v-if="notifications.length > 0" class="list-group-item text-start mb-0 text-secondary">
                <h4 class="mb-0 pb-0">{{ t('missing_donations') }}</h4>
            </div>
            <div v-if="notifications.length === 0" class="list-group-item text-start mb-0 text-secondary">
                <h4 class="mb-0 pb-0">{{ t('no_missing_donations') }}</h4>
            </div>
            <div class="list-group-item text-start mb-0" v-for="notification in notifications" :key="notification.id">
                <p clas="m-0" style="margin-bottom: 0;">{{
                    `${t('missing_donation', {
                        name: notification.donator_name, sponsorship: notification.orphan_name
                    })}
                                    (${notification.sponsorship})`
                }}
                </p>
                <small class="text-muted">
                    {{
                        t('last_donation', { date: generateDate(notification.last_donation) }) }}

                </small>
            </div>
        </ul> -->
    </div>
</template>
  
<script>
import { UserStore } from '@/stores/userStore';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

export default {
    name: 'NotificationPage',
    components: {
    },
    setup() {
        const userStore = UserStore();
        const { user_info, notifications, checkEntries } = storeToRefs(userStore);
        const { t, locale } = useI18n({});
        return {
            user_info,
            userStore,
            t,
            locale,
            notifications,
            checkEntries
        }

    },
    watch: {
        checkEntries: {
            handler() {
                console.log(this.checkEntries)
            }
        }
    },

    async created() {
        // await this.userStore.getNotifications();
        await this.userStore.getToBeChecked();
    },
    methods: {
        generateDate(date, include_date = false) {
            let d = new Date(date);
            const options = { weekday: 'long', month: 'short', day: 'numeric', year: "numeric" };
            if (include_date) {
                delete options.weekday;
                options.year = 'numeric';
            }
            const formattedDate = d.toLocaleDateString(this.locale, options);
            return formattedDate;
        },
    }
};
</script>
  
<style scoped>
/* Add your styling for the notification page here */
.notification-page {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto auto;
}

.list-group-item:hover {
    z-index: 1;
}
</style>
  