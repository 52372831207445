<template>
    <div v-if="states">
        <div class="modal-header">
            <h5 class="modal-title">{{ t('orphan_family_update_button') }}</h5>
        </div>
        <div v-if="states != undefined" class="modal-body" style="margin-top: 1%;">
            <form>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('orphan_modal_ngo_id') }}</label>
                        <input v-model="states.ngo_id" @keyup="searching" @paste.once="pastedetected" type="email"
                            class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            :placeholder="t('orphan_modal_ngo_id_place_holder')" />
                        <h6 style="color: red" v-if="is_exist && states.ngo_id !== original_states.ngo_id" id="emailHelp"
                            class="form-text text-muted">
                            {{ t('orphan_ngo_id_exist') }}
                        </h6>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail3">{{ t('orphan_modal_name') }}*</label>
                        <input v-model="states.name" class="form-control" id="exampleInputEmail3"
                            aria-describedby="emailHelp" :placeholder="t('orphan_family_modal_name_place_holder')" />
                        <h6 style="color: red" v-if="states.name?.length == 0" id="emailHelp" class="form-text text-muted">
                            {{ t('orphan_name_field_can_not_empty') }}
                        </h6>
                    </div>
                </div>
                <div class="form-row">


                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail5">{{ t('orphan_modal_country') }}</label>
                        <select v-model="states.location" class="form-control" id="familyFilter">
                            <option value="">{{ t('select_country') }}</option>
                            <option v-for="country in countries" :value="country.alpha2Code" :key="country.alpha2Code">{{
                                languageStore.getLanguage == 'en' ? country.name : country.translations.fr }}
                            </option>
                        </select>
                    </div>

                </div>
            </form>
        </div>
        <div :class="canCheck ? 'w-100 d-flex flex-row justify-content-between' : 'w-100 d-flex flex-row justify-content-end'"
            v-if="states != undefined">
            <div class="form-check form-switch d-flex align-items-center" v-if="canCheck">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" :checked="states.checked"
                    @click="states.checked = !states.checked">
                <label class="p-0 flex-grow-1 m-0" for="flexSwitchCheckChecked">{{ t('checked') }}</label>
            </div>
            <div class="modal-footer">

                <button :disabled="JSON.stringify(original_states) === JSON.stringify(states) || is_exist"
                    @click="updateFamily" type="button" style="color: white" class="btn btn-primary add-button">
                    {{ t('orphan_family_update_button') }}
                </button>

                <button type="button" style="color: white" class="btn btn-danger" @click="close">
                    {{ t('orphan_modal_cancel_button') }}
                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { NotificationStore } from '@/stores/notification';
import { OrphanFamilyStore } from "@/stores/orphanFamily";
import { OrphanFamilyEditStates } from "@/stores/orphanFamilyEditStates";
import { CountriesStore } from '@/stores/countries';
import { UserStore } from '@/stores/userStore';
import { OrphanEditStates } from "@/stores/orphanEditStates";

export default {
    name: 'EditOrphanFamily',
    props: ['id'],
    data() {
        return {
            is_read: false,
            canCheck: false,
        };
    },

    watch: {
        states: {
            handler() {

            },
            deep: true,
        },
        family_id: {
            deep: true,
        },
        isDisabled: {
            deep: true,
        }
    },
    async created() {
        this.canCheck = this.userStore.checkIfHasPermission('check.entries.update')
        this.is_ready = true;
        if (this.states.location != null)
            this.states.location = this.states.location.toUpperCase();

        this.original_states = Object.assign({}, this.states);

    },
    methods: {

        close() {
            this.orphanFamilyEditStates.cleanStates();
            this.$emit('status', true);
            this.$emit('close');

        },


        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.check_is_exist, 100);
        },

        pastedetected(eventdata) {
            this.search = "";
            this.ngo_id = eventdata.clipboardData.getData("Text");
            this.searching();
        },
        async updateFamily() {
            this.loadingStore.setLoading(true);
            let response = await this.orphanFamilyStore.updateFamily(this.states);
            if (this.canCheck) {
                let checkResponse = await this.orphanEditStates.checkEntry('orphans_family', this.states.id, this.states.checked)
                if (checkResponse.status) {
                    // this.close();
                    // this.$emit('status', true);
                }
            }
            if (response.status) {
                this.close();
            }
            this.loadingStore.setLoading(false);
            let message = response.status ? this.t('orphan_updated_alert') : this.t('orphan_not_updated_alert');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        },


        async check_is_exist() {
            if (this.states.ngo_id !== this.original_states.ngo_id) {
                let is_exist = await this.orphanFamilyStore.searchFamily(this.states.ngo_id);
                this.is_exist = is_exist.status;
            }
            else
                this.is_exist = false;
        }
    },

    setup() {
        const orphanFamilyStore = OrphanFamilyStore();
        const orphanFamilyEditStates = OrphanFamilyEditStates();
        const { states, original_states, is_exist, family_id } = storeToRefs(orphanFamilyEditStates);
        const notificationStore = NotificationStore();
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const countriesStore = CountriesStore();
        const { countries } = storeToRefs(countriesStore);
        const languageStore = LanguageStore();
        const userStore = UserStore();
        const orphanEditStates = OrphanEditStates();
        const { t } = useI18n({});

        return {
            t, is_loading, languageStore, loadingStore, family_id, countries, notificationStore, orphanFamilyStore, orphanFamilyEditStates,
            states, original_states, is_exist, userStore, orphanEditStates
        };
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

label {
    float: left;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>
  