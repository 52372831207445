export default {
  "campaign_type_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de campagne ajouté avec succès"])},
  "campaign_type_cannot_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'ajouter le type de campagne"])},
  "campaign_type_modal_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un type de campagne"])},
  "campaign_type_modal_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le type de campagne"])},
  "campaign_type_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom donné existe déjà"])},
  "campaign_type_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ce type de campagne ?"])},
  "campaign_type_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de campagne supprimé avec succès"])},
  "campaign_type_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer le type de campagne"])},
  "campaign_type_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de campagne mis à jour"])},
  "campaign_type_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de campagne introuvable"])},
  "campaign_type_not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre à jour le type de campagne"])},
  "campaign_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la campagne"])},
  "campaign_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de campagne"])},
  "campaign_orphan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphelin enregistré"])},
  "please_add_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type de campagne n'existe pas, veuillez ajouter le type de campagne avant de continuer"])},
  "register_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire à"])},
  "register_orphan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphelin"])},
  "register_noone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])},
  "register_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille"])},
  "select_orphan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un orphelin"])},
  "create_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une campagne"])},
  "campaign_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne ajoutée avec succès"])},
  "campaign_cannot_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'ajouter la campagne"])},
  "campaign_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette campagne ?"])},
  "campaign_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne supprimée avec succès"])},
  "campaign_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer la campagne"])},
  "campaign_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne mise à jour"])},
  "public_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donateurs publics"])},
  "private_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donateurs privés"])},
  "all_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les donateurs"])},
  "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total des dons"])}
}