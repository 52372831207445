import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const EmailStore = defineStore("emailStore", {
  state: () => ({
    public_donators: [],
    private_donators: [],
  }),

  getters: {},

  actions: {
    cleanStates() {
      this.public_donators = [];
      this.private_donators = [];
    },

    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async getPublicDonators() {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      let url = "api/public/donators";
      return axios
        .get(url)
        .then((response) => {
          this.public_donators = response.data.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Donators Not Found!" };
        });
    },

    async getPrivateDonators() {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      let url = "api/donators";
      return axios
        .get(url)
        .then((response) => {
          this.private_donators = response.data.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Donators Not Found!" };
        });
    },

    async sendCampaigns(data) {
      const url = "api/send-campaigns";
      return axios
        .post(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
