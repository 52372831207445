import { defineStore } from "pinia";

export const LoadingStore = defineStore('loading', {
    state: () => ({
        is_loading: false,
    }),

    getters: {
        getLoading: (state) => state.is_loading,
    },

    actions: {
        async setLoading(loading) {
            this.is_loading = loading;
        },
    }
})

