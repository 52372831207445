export default {
  "auth_forget_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget Password?"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "login_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or password is wrong !"])},
  "session_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Expired"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
  "create_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create User"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Time Password"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "user_added_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User has been created successfully"])},
  "user_not_added_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured"])},
  "Regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
  "Editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
  "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "user_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete user?"])},
  "user_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will be deleted, you won't be able to revert!"])},
  "user_delete_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be deleted"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
  "profile_edit_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile has been edited successfully"])},
  "profile_edit_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile could not be edited, an error occured"])},
  "password_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been changed successfully"])},
  "password_change_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while changing the password"])},
  "change_password_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your password before logging in"])},
  "enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email address for your account"])},
  "send_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send OTP"])},
  "otp_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP sent to the email address provided"])},
  "otp_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP could not be sent!"])}
}