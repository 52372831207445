export default {
  "email_topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Subject"])},
  "email_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Body"])},
  "select_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Recipients"])},
  "selected_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Recipients"])},
  "no_recipients_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Recipients Selected"])},
  "send_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
  "email_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Email Language"])},
  "email_sent_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent successfully"])},
  "email_not_sent_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email could not be sent!"])},
  "all_regular_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Regular Donators"])},
  "all_vip_donators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All VIP Donators"])}
}