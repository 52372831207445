<template>
    <div class="form-group  my-2 autocomplete w-100 mx-2">
        <div class="input-container">
            <input v-model="searchText" @input="search" class="form-control" :placeholder='this.label' @focus="search" />
            <i @click="showResults = !showResults" class="bi bi-caret-down-fill p-2 " v-if="!showResults"></i>
            <i @click="showResults = !showResults" class="bi bi-caret-up-fill p-2 " v-if="showResults"></i>
        </div>
        <ul v-if="showResults" class="autocomplete-results">
            <li v-if="!donators_shown || donators_shown.length < 1" class="autocomplete-result ">
                {{ t('result_not_found') }}
            </li>
            <li v-if="donators_shown.length > 1"
                :class="{ 'autocomplete-result': true, 'selected': all_selected, 'font-weight-bold': true }"
                @click="all_selected = !all_selected">
                {{ t('select_all') }}
            </li>
            <li v-if="!is_public && donators_shown.length > 1"
                :class="{ 'autocomplete-result': true, 'selected': vip_selected, 'font-weight-bold': true }"
                @click="vip_selected = !vip_selected">
                {{ t('all_vip_donators') }}
            </li>
            <li v-if="!is_public && donators_shown.length > 1"
                :class="{ 'autocomplete-result': true, 'selected': regular_selected, 'font-weight-bold': true }"
                @click="regular_selected = !regular_selected">
                {{ t('all_regular_donators') }}
            </li>
            <li v-for="item in  donators_shown " :key="item.id" @click="toggleSelection(item)"
                :class="{ 'autocomplete-result': true, 'selected': item.selected }">
                {{ `${handleName(item.name, item.last_name)} (${item.email ? item.email : 'no email'} )` }}
            </li>
        </ul>
    </div>
</template>
  
<script>
import _ from "lodash";
import { useI18n } from "vue-i18n";
export default {
    name: "recipientSelect2",
    props: ['items', 'label', 'toggleSelection', 'is_public', 'toggleVipSelection', 'toggleRegularSelection', 'toggleSelectAll'],
    data() {
        return {
            donators_shown: undefined,
            searchText: this.selected_item ? this.handleName(this.selected_item.name, this.selected_item.last_name) : "",
            showResults: false,
            vip_selected: false,
            regular_selected: false,
            all_selected: false
        };
    },
    async created() {
        this.donators_shown = this.items
    },
    setup() {
        const { t } = useI18n({});
        return {
            t,
        };
    },
    watch: {
        vip_selected: {
            handler(newV) {
                this.toggleVipSelection(newV)
                this.search()
            }
        },
        all_selected: {
            handler(newV) {
                this.toggleSelectAll(newV, this.is_public);
                this.search()
            }
        },
        regular_selected: {
            handler(newV) {
                this.toggleRegularSelection(newV);
                this.search()
            }
        },
        filtered_donators: {
            deep: true
        },
        items: {
            deep: true,
            immediate: true
        }
    },
    methods: {
        // Throttle the search method to avoid sending too many requests
        search: _.debounce(function () {
            if (this.searchText) {
                this.searchDonators();
            } else {
                this.donators_shown = this.items
                this.showResults = true

            }
        }, 1000), // Throttle for 3 seconds

        // Fetch search results from an API (adjust the API endpoint)
        async searchDonators() {
            let lower_case_search_text = this.searchText.toLowerCase().split(" ")[0]
            let filtered_donators = this.items.filter((donator) => donator.name && donator.name.toLowerCase().includes(lower_case_search_text) || donator.last_name && donator.last_name.toLowerCase().includes(lower_case_search_text))
            this.donators_shown = filtered_donators
            this.showResults = true
        },

        async getDonators() {
            this.filtered_donators = this.items
            this.showResults = true
        },

        checkIfSelected(array, id) {
            array.some((item) => item.id == id)
        },
        handleName(name, last_name) {
            return (name || '') + (last_name ? ' ' + last_name : '');
        },
        // Handle item selection and update the selected_item prop
        selectItem() {
            this.searchText = ""
        },
    },
};
</script>

<style>
.autocomplete {
    position: relative;
}

.input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 1px;
    border-radius: 5px;
}

.input-container .form-control {
    flex: 1;
    border: none;
}

.close-button {
    cursor: pointer;
}

.autocomplete-results {
    padding: 5px;
    background-color: #f5f3f3;
    margin: 0;
    margin-top: 3px;
    border: 1px solid #eeeeee;
    height: auto;
    border-radius: 0 0 10px 10px;
    min-height: 1em;
    max-height: 20em;
    overflow: auto;
}

.selected {
    background-color: #5193f665;
}

.autocomplete-result {
    list-style: none;
    /* border-bottom: solid 1px #96BFFE; */
    text-align: left;
    border-radius: 5px;
    padding: 3px 5px;
    cursor: pointer;
}

.autocomplete-result:hover {
    background-color: #5193f6;
    color: white;
}
</style>
  