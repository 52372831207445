export default {
  "sponsorships_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "sponsorships_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "sponsorships_name_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom existe déjà"])},
  "sponsorships_amount_field_can_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant ne peut pas être vide ou égal à 0"])},
  "sponsorships_name_field_can_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom ne peut pas être vide"])},
  "sponsorships_name_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nom"])},
  "sponsorships_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "sponsorships_price_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le montant"])},
  "sponsorships_price_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant ne peut pas être vide !"])},
  "sponsorships_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
  "sponosrships_modal_add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un type de parrainage"])},
  "sponsorships_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "sponsorships_type_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de parrainage ajouté avec succès"])},
  "sponsorships_type_not_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'ajouter le type de parrainage"])},
  "sponsorships_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le type de parrainage ?"])},
  "sponsorships_type_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de parrainage supprimé avec succès"])},
  "sponsorships_type_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer le type de parrainage"])},
  "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données peuvent déjà être supprimées. Utilisez le bouton de rafraîchissement pour actualiser le tableau"])},
  "sponosrships_modal_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le parrainage"])},
  "sponsorships_type_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de parrainage mis à jour"])},
  "sponsorships_type_not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre à jour le type de parrainage"])},
  "sponsorships_type_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de parrainage introuvable"])},
  "select_donator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un donateur"])},
  "missing_donation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il manque un don à ", _interpolate(_named("name")), " pour ", _interpolate(_named("sponsorship")), "!"])},
  "last_donation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le dernier don a été effectué le ", _interpolate(_named("date"))])},
  "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])},
  "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])},
  "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
  "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])},
  "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
  "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])},
  "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])},
  "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])},
  "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembre"])},
  "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])},
  "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
  "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])},
  "don_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dons effectués par mois"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
}