import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";

axios.defaults.baseURL = base_url;

export const SponsorshipsTypeEditStates = defineStore('sponsorshipsTypeEditStates', {
    state: () => ({
        states: undefined,
        orginal_states: {

        },
        field_states: {
            id: undefined,
            amount_empty: false,
            is_exist: false
        }
    }),

    getters: {
    },

    actions: {
        cleanStates() {
            this.states = undefined;
            this.id = undefined;
            this.orginal_states = {};
            this.field_states = {
                id: undefined,
                amount_empty: false,
                is_exist: false
            };
        },

        setId(id) {
            this.field_states.id = id;
        },

        async getType(id) {
            const url = "api/sponsorships/" + id;
            return axios.get(url).then((response) => {
                this.states = response.data;
                this.states.currency = this.states.currency.id;

                return { status: true, data: this.states };
            }).catch((err) => {
                return { status: false, data: err.response };
            });
        },

        async userIsValid() {
            if (localStorage.getItem("ngo_access_token") == null)
                return false;
            return true;
        },
    }
})