import { defineStore } from "pinia";
import Swal from "sweetalert2";

export const NotificationStore = defineStore("notification", {
  state: () => ({}),

  actions: {
    popupMessage(
      title,
      text,
      status,
      position = "center",
      is_toast = true,
      then_function = undefined,
      catch_function = undefined
    ) {
      Swal.fire({
        toast: is_toast ? true : false,
        animation: false,
        position: position,
        icon: status ? "success" : "error",
        title: title,
        text: text,
        showConfirmButton: status ? false : true,
        confirmButtonColor: "rgb(0, 135, 220)",
        timer: status ? 3000 : 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      })
        .then((response) => {
          if (then_function != undefined) {
            if (status == true && response.isConfirmed) then_function();
            else if (status == false) then_function();
          }
        })
        .catch((exception) => {
          console.log("Exception handled : ", exception);
          if (catch_function != undefined) catch_function();
        });
    },

    popupDialog(
      accept_text,
      cancel_text,
      title,
      text,
      status,
      parameter = undefined,
      position = "center",
      then_function = undefined,
      catch_function = undefined
    ) {
      Swal.fire({
        position: position,
        icon: "question",
        title: title,
        text: text,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: accept_text,
        cancelButtonText: cancel_text,
        confirmButtonColor: "rgb(0, 135, 220)",
        cancelButtonColor: "rgb(255, 0, 0)",
      })
        .then((response) => {
          if (then_function != undefined) {
            if (status == true && response.isConfirmed)
              return then_function(parameter);
            else if (status == false) return then_function(parameter);
          }
        })
        .catch((exception) => {
          console.log("Exception handled : ", exception);
          if (catch_function != undefined) catch_function();
        });
    },
    async checkResponse(response_code) {
      if (response_code == 401) {
        if (localStorage.getItem("ngo_access_token") !== null) {
          localStorage.removeItem("ngo_access_token");
          window.location.replace("/login");
        }
      }
    },
  },
});
