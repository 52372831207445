<template>
    <div class="container mx-auto text-center d-flex flex-row justify-content-center align-items-center h-100">
        <BaseModal :open="changePasswordIsOpen" @close="changePasswordIsOpen = !changePasswordIsOpen">
            <ChangePasswordModal @close="changePasswordIsOpen = !changePasswordIsOpen" :is_login='true' :email="user.email">
            </ChangePasswordModal>
        </BaseModal>
        <BaseModal :open="forgotPasswordIsOpen" @close="forgotPasswordIsOpen = !forgotPasswordIsOpen">
            <ForgotPasswordModal @close="forgotPasswordIsOpen = !forgotPasswordIsOpen">
            </ForgotPasswordModal>
        </BaseModal>
        <div class="row m-6 no-gutters shadow-lg w-100">
            <div class="col-md-6 d-none d-md-block">

                <img src="../../assets/Logo.jpg" class="img-fluid" style="height: 100%; width:100%; padding:0px;" />
            </div>
            <div class="col-md-6 p-5">

                <div class="text-center">

                    <h3 class="pb-1" style="color: #B83E3C">NGOSG</h3>
                </div>
                <LanguageSwitcher style="float:right"></LanguageSwitcher>

                <div class="form-style">
                    <div class="form-group pb-3">
                        <input type="email" placeholder="name@example.com" v-model="user.email" class="form-control"
                            id="exampleInputEmail1" aria-describedby="emailHelp" @change="resetFeedback" />
                    </div>
                    <div class="form-group pb-3">
                        <input type="password" v-model="user.password" :placeholder="t('password')" class="form-control"
                            id="exampleInputPassword1" @change="resetFeedback" />
                    </div>
                    <span v-if="feedback !== ''" style="color: red"> {{ t('login_feedback') }}</span>

                    <div class="d-flex align-items-center justify-content-between">
                        <div><a href="#" @click="forgotPasswordIsOpen = true">{{ t('auth_forget_pass') }}</a></div>
                    </div>
                    <div class="pb-2">
                        <button type="submit" @click="login" class="btn btn-light w-100 font-weight-bold mt-2">
                            {{ t('login') }}
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
import { LoadingStore } from '@/stores/loading';
import { UserStore } from '@/stores/userStore';
import { useI18n } from 'vue-i18n';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import { storeToRefs } from 'pinia';
import BaseModal from '../BaseModal.vue';
import { NotificationStore } from '@/stores/notification';
import ChangePasswordModal from '../user/Modal/ChangePasswordModal'
import ForgotPasswordModal from './ForgotPasswordModal.vue';

export default {
    name: "AuthLogin",

    setup() {
        const loadingStore = LoadingStore();
        const userStore = UserStore();
        const notificationStore = NotificationStore();
        const { first_login } = storeToRefs(userStore);
        const { t } = useI18n({});

        return {
            loadingStore,
            userStore,
            notificationStore,
            t, first_login
        };
    },
    data: () => ({
        user: {
            email: "",
            password: "",
            show: true,
        },
        changePasswordIsOpen: false,
        forgotPasswordIsOpen: false,
        feedback: "",
    }),
    components: {
        LanguageSwitcher, BaseModal, ChangePasswordModal, ForgotPasswordModal

    },
    methods: {

        openModal() {
            this.changePasswordIsOpen = true
        },

        close() {
            this.changePasswordIsOpen = false
            this.user.password = ''
        },
        async login() {
            this.loadingStore.setLoading(true);
            let response = await this.userStore.login({
                email: this.user.email,
                password: this.user.password
            });
            this.loadingStore.setLoading(false);
            if (response.status == false)
                this.feedback = response.message;
            else if (response.data === 'first_login') {
                this.openModal()
                let message = this.t('change_password_message');
                this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
            }
            else window.location.replace('/app');


        },

        resetFeedback() {
            if (this.feedback !== "") {
                this.feedback = "";
            }
        },
    },

    async created() {
        this.loadingStore.setLoading(true);
        this.isvisible = await this.userStore.userIsValid();
        this.loadingStore.setLoading(false);

        if (this.isvisible)
            window.location.replace("/app");
    },
};
</script>
<style scoped>
body {
    background: #c9ccd1;
}

.form-style input {
    border: 0;
    height: 50px;
    border-radius: 0;
    border-bottom: 1px solid #ebebeb;
}

.form-style input:focus {
    border-bottom: 1px solid #007bff;
    box-shadow: none;
    outline: 0;
    background-color: #ebebeb;
}

.sideline {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ccc;
}

button {
    height: 50px;
}

.sideline:before,
.sideline:after {
    content: "";
    border-top: 1px solid #ebebeb;
    margin: 0 20px 0 0;
    flex: 1 0 20px;
}

.sideline:after {
    margin: 0 0 0 20px;
}

.login {
    margin-top: 15%;
}

@media (max-width: 991px) {
    .login {
        margin-top: 50%;
    }

    @media (min-width: 767px) {
        .login {
            margin-top: 30%;
        }
    }
}
</style>
  