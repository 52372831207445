import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const SponsorshipsStore = defineStore("sponsorshipsStore", {
  state: () => ({
    states: {
      name: "",
      amount: 0,
      currency: 0,
    },
    field_states: {
      is_exist: false,
      amount_empty: true,
    },
    page: {
      value: 0,
      count: 0,
    },

    sponsorship_types: undefined,
  }),

  getters: {},

  actions: {
    cleanStates() {
      this.states = {
        name: "",
        amount: 0,
        currency: 0,
      };
      this.field_states = {
        is_exist: false,
        amount_empty: true,
      };
    },

    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async updateSponsorships(data) {
      const url = "api/sponsorships/" + data.id;
      if (data.amount == "") data.amount = 0;
      return axios
        .put(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async addSponsorships(data) {
      const url = "api/sponsorships";
      if (data.amount == "") data.amount = 0;
      return axios
        .post(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async deleteSponsorships(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = "api/sponsorships/" + id;
      return axios
        .delete(url)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async search_fetch(key, like, page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/sponsorships/search", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            key: key,
            like: like,
          },
        })
        .then((response) => {
          this.sponsorship_types = response.data.data;
          this.page.count = response.data.number_of_page;
          this.page.value = this.page.count > 0 ? response.data.page_number : 0;
          this.page.number_of_data = response.data.number_of_data;
          this.page.per_page = response.data.per_page;

          return { status: true, data: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          this.sponsorship_types.data = [];
          this.page.count = 0;
          this.page.value = 0;
          return { status: false, message: "Not Found!" };
        });
    },

    async search(key, like, page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/sponsorships/search", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            key: key,
            like: like,
          },
        })
        .then((response) => {
          return { status: true, data: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          this.orphans.data = [];
          return { status: false, message: "Not Found!" };
        });
    },

    async getSponsorshipTypes(
      currency,
      page_number,
      per_page,
      sort_column,
      sort_by
    ) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/sponsorships", {
          params: {
            currency: currency == "null" ? null : currency,
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
          },
        })
        .then((response) => {
          this.sponsorship_types = response.data.data;
          this.page.count = response.data.number_of_page;
          this.page.value = this.page.count > 0 ? response.data.page_number : 0;
          this.page.number_of_data = response.data.number_of_data;
          this.page.per_page = response.data.per_page;

          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
