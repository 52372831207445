<template>
    <div>
        <div class="modal-header">
            <h5 class="modal-title">{{ t('sponosrships_modal_add_button') }}</h5>
        </div>
        <div class="modal-body" style="margin-top: 1%;">
            <form>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('sponsorships_name') }}*</label>
                        <input v-model="states.name" @keyup="searching" @paste.once="pastedetected" type="email"
                            class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            :placeholder="t('sponsorships_name_place_holder')" />
                        <h6 style="color: red" v-if="is_exist" id="emailHelp" class="form-text text-muted">
                            {{ t('sponsorships_name_exist') }}
                        </h6>
                        <h6 style="color: red" v-if="!is_exist && states.name.length == 0" id="emailHelp"
                            class="form-text text-muted">
                            {{ t('sponsorships_name_field_can_not_empty') }}
                        </h6>
                    </div>
                </div>
                <div class="form-row">

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail3">{{ t('sponsorships_price') }}*</label>
                        <input type="number" min="0" v-model="states.amount" @change="amountEmpty" @paste.once="amountEmpty"
                            @keyup="amountEmpty" class="form-control" id="exampleInputEmail3" aria-describedby="emailHelp"
                            :placeholder="t('sponsorships_price_place_holder')" />
                        <h6 style="color: red" v-if="!field_states.amount_empty" id="emailHelp"
                            class="form-text text-muted">
                            {{ t('sponsorships_amount_field_can_not_empty') }}
                        </h6>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail2">{{ t('sponsorships_currency') }}</label>
                        <select v-model="states.currency" class="form-control" id="familyFilter">
                            <option v-for="currency in currencies" :value="currency.id" :key="'currency' + currency.id">
                                {{ currency.name }} {{ '('
                                    + currency.symbol + ')' }}</option>
                        </select>
                    </div>


                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button :disabled="(states.name.length == 0 || field_states.is_exist) || !field_states.amount_empty"
                @click="add" type="button" style="color: white" class="btn btn-primary add-button">
                {{ t('sponosrships_modal_add_button') }}
            </button>

            <button type="button" style="color: white" class="btn btn-danger" @click="close">
                {{ t('orphan_modal_cancel_button') }}

            </button>
        </div>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { NotificationStore } from '@/stores/notification';
import { OrphanStore } from "@/stores/orphan";
import { CountriesStore } from '@/stores/countries';
import { CurrencyStore } from '@/stores/currency';
import { SponsorshipsStore } from '@/stores/sponsorshipsStore';

export default {
    name: 'CreateSponsorshipsTypes',

    data() {
        return {

        };
    },

    watch: {
        currencies: {
            deep: true
        },
        field_states: {
            deep: true,
        },
        isDisabled: {
            deep: true,
        }
    },
    async created() {
        await this.currencyStore.getCurrencies();
        if (this.currencies.length > 0)
            this.states.currency = this.currencies[0].id;

    },
    methods: {

        amountEmpty() {


        },
        close() {
            this.sponsorshipsStore.cleanStates();
            this.$emit('close');
        },

        async add() {
            this.loadingStore.setLoading(true);
            let response = await this.sponsorshipsStore.addSponsorships(this.states);
            if (response.status) {
                this.$emit('status', true);
                this.close();

            }
            // Call api
            this.loadingStore.setLoading(false);
            let message = response.status ? this.t('sponsorships_type_added') : this.t('sponsorships_type_not_added');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        },

        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.check_is_exist, 150);
        },

        pastedetected(eventdata) {
            this.search = "";
            this.states.name = eventdata.clipboardData.getData("Text");
            this.searching();
        },

        async check_button() {
            this.$emit('check', this.ngo_id, this.name, this.last_name);
        },

        async check_is_exist() {
            if (this.states.name.length != 0) {
                let response = await this.sponsorshipsStore.search(this.states.name, 0);
                if (response.status && response.data.data.length > 0)
                    this.field_states.is_exist = true;
                else
                    this.field_states.is_exist = false;

            }
            else {
                this.field_states.is_exist = false;
            }
        }

    },
    setup() {
        const orphanStore = OrphanStore();
        const sponsorshipsStore = SponsorshipsStore();
        const notificationStore = NotificationStore();
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const { families } = storeToRefs(orphanStore);
        const countriesStore = CountriesStore();
        const { countries } = storeToRefs(countriesStore);
        const languageStore = LanguageStore();
        const currencyStore = CurrencyStore();
        const { currencies } = storeToRefs(currencyStore);
        const { states } = storeToRefs(sponsorshipsStore);
        const { field_states } = storeToRefs(sponsorshipsStore);
        const { t } = useI18n({});
        return {
            t, is_loading, languageStore, sponsorshipsStore, currencyStore, currencies, loadingStore,
            countries, notificationStore, orphanStore, families, states, field_states
        };
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

label {
    float: left;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>
  