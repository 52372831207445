<template>
  <BaseModal :open="isOpen" @close="isOpen = !isOpen">
    <CreateOrphanFamily v-if="isOpen" @status="fetch" @close="isOpen = !isOpen">
    </CreateOrphanFamily>

  </BaseModal>
  <BaseModal :open="editIsOpen" @close="editIsOpen = !editIsOpen">
    <EditOrphanFamily v-if="editIsOpen" @status="fetch" @close="editIsOpen = !editIsOpen"></EditOrphanFamily>
  </BaseModal>

  <div class="m-3">

    <div class="row justify-content-between">
      <div class="col col-md-6 d-flex flex-row justify-content-between">
        <select v-model="selected_sort_by_options" class="form-control mx-1" id="familyFilter" @change="getData">
          <option v-for="option in sort_by_options" :value="option.value" :key="option.value">{{ t(option.label) }}
          </option>
        </select>
        <select v-model="selected_sort_column" class="form-control mx-1" id="familyFilter" @change="getData">
          <option v-for="option in sort_column_options" :value="option.value" :key="option.value">{{ t(option.label) }}
          </option>
        </select>
      </div>
      <div class="col col-md-2 d-flex flex-row justify-content-between">
        <div class="w-100 mx-1">
          <button class="edit-buttons btn btn-primary w-100" @click="openModal">{{ t('orphan_table_operation_add') }}
          </button>
        </div>
        <div class="w-100 mx-1">
          <button id="refreshTable" class="edit-buttons btn btn-primary w-100" @click="getData(event, true)">{{
            t('orphan_table_operation_refresh') }}</button>
        </div>
      </div>
    </div>
    <div class="row" style="float:left">

    </div>
    <div class="table-responsive">
      <br />
      <br />
      <h2 v-if="families != undefined && families.data.length == 0">Not Found</h2>

      <table v-if="families != undefined && families.data.length > 0" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th data-field="id" data-sortable="true">{{ t('orphan_table_column_ngoid') }}</th>
            <th data-field="name" data-sortable="true">{{ t('orphan_table_column_name') }}</th>
            <th data-field="location" data-sortable="true">{{ t('orphan_table_column_location') }}</th>
            <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_registration_date') }}</th>
            <th data-field="update_date" data-sortable="true">{{ t('orphan_table_column_update_date') }}</th>
            <th data-field="is_checked" data-sortable="true">{{ t('orphan_table_column_checked') }}</th>
            <th data-field="actions" data-sortable="true">{{ t('orphan_table_column_actions') }}</th>
          </tr>
        </thead>
        <tbody id="myTable" v-if="families != undefined">
          <tr v-for="family in families.data" :key="family.id" :class="family.checked ? 'table-primary' : 'table-danger'">
            <td>{{ family.ngo_id != null ? family.ngo_id : "--" }}</td>
            <td>{{ family.name }}</td>
            <td>{{ family.location ? family.location : '--' }}</td>
            <td>{{ generateDate(family.created_at) }}</td>
            <td>{{ generateDate(family.updated_at) }}</td>
            <td>{{ family.checked ? t('checked') : t('not_checked') }} </td>
            <td>
              <button @click="openModal(event, true, family)" class="btn edit-button btn-sm btn-outline-primary"><i
                  class="fa fa-pencil"></i> </button>
              <button class="btn btn-sm btn-outline-danger" @click="deleteOrphan(family)"><i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-sm-1">
        <select v-model="selected_per_page" id="pageSize" class="form-control d-inline-block w-auto"
          @change="perPageChange">
          <option v-for="option in per_page_options" :value="option.value" :key="option.value">{{ (option.label) }}
          </option>
        </select>
      </div>
      <div class="col-sm">
        <div class="float-right">
          <button class="btn btn-outline-secondary" :disabled="page.value == 1" @click="changePage(false)">
            <i class="fas fa-angle-left"></i> {{ t('orphan_prev_button') }}
          </button>
          <button class="btn btn-outline-secondary" :disabled="page.value + 1 > [page.count]" @click="changePage(true)">
            {{ t('orphan_next_button') }} <i class="fas fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="float-right mr-3">
      <span>{{ t('orphan_table_footer_page') }} {{ page.value }} </span>
      <span>{{ t('orphan_table_footer_total') }} {{ page.count }}</span>
    </div>
  </div>
</template>
  
<script>
import BaseModal from "../BaseModal.vue";
import CreateOrphanFamily from "./Modal/CreateOrphanFamily.vue";
import EditOrphanFamily from "./Modal/EditOrphanFamily.vue";
import { OrphanFamilyEditStates } from "@/stores/orphanFamilyEditStates";
import { LoadingStore } from '@/stores/loading';
import { OrphanFamilyStore } from "@/stores/orphanFamily";
import { storeToRefs } from 'pinia';
import { NotificationStore } from '@/stores/notification';
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import router from '@/routes/index.js'

export default {
  name: 'OrphanTable',
  components: {
    BaseModal,
    CreateOrphanFamily,
    EditOrphanFamily
  },

  setup() {
    const loadingStore = LoadingStore();
    const familyStore = OrphanFamilyStore();
    const orphanFamilyEditStates = OrphanFamilyEditStates();
    const notificationStore = NotificationStore();
    const { families } = storeToRefs(familyStore);
    const { page } = storeToRefs(familyStore);
    const { is_loading } = storeToRefs(loadingStore);
    const isOpen = ref(false);
    const editIsOpen = ref(false);
    const { t } = useI18n({});
    return {
      loadingStore,
      orphanFamilyEditStates,
      familyStore,
      notificationStore,
      families,
      page,
      is_loading,
      isOpen, t, editIsOpen
    };

  },

  watch: {
    country: {
      handler(newv, oldv) {
        alert(newv + " " + oldv);
      }
    },
    region: {
      handler(newv, oldv) {
        alert(newv + " " + oldv);
      }
    },

    families: {
      handler() {
        this.page.value = this.families.page_number;
        this.page.count = this.families.number_of_page;
      },
      deep: true
    },

  },
  data() {
    return {
      civility_options: [
        { label: 'orphan_filter_civility_all', value: null },
        { label: 'orphan_filter_civility_male', value: '0' },
        { label: 'orphan_filter_civility_female', value: '1' },
        { label: 'orphan_filter_civility_undefined', value: '-2' },
      ],
      selected_civility: null,
      sort_column_options: [
        { label: 'orphan_filter_created_at', value: 'created_at' },
        { label: 'orphan_filter_updated_at', value: 'updated_at' },
      ],
      selected_sort_column: 'created_at',

      sort_by_options: [
        { label: 'orphan_filter_order_by_desc', value: '3' },
        { label: 'orphan_filter_order_by_asc', value: '2' },
      ],
      per_page_options: [
        { label: '5', value: '5' },
        { label: '10', value: '10' },
        { label: '15', value: '15' },
      ],
      selected_per_page: 5,
      selected_family: null,
      selected_sort_by_options: '3',

      isModalOpen: false,
      civility: -1,
      country: '',
      region: '',
      isDisabled: false,
      first_disable: true,
      clean_state: false,
      name_disable: false,
      last_name_disable: false,
      ngo_id_disable: false,
      timeout: undefined,
      search: '',

    };
  },

  async created() {
    let params = this.parseURLParamaters();
    this.page.value = params.page;
    this.selected_per_page = params.per_page;
    this.selected_sort_column = params.sort_column;
    this.selected_sort_by_options = params.sort_by;
    await this.getData();
  },

  methods: {
    parseURLParamaters() {
      const url_params = new URLSearchParams(window.location.search);
      let params = {
        page: url_params.get('page') ? url_params.get('page') : 1,
        per_page: url_params.get('per_page') ? url_params.get('per_page') : 5,
        sort_column: url_params.get('sort_column') ? url_params.get('sort_column') : 'created_at',
        sort_by: url_params.get('sort_by') ? url_params.get('sort_by') : '3'
      };
      return params;
    },

    searching() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.SearchOrphan, 100);
    },

    SearchOrphan(page_number = 1) {
      if (this.search.length == 0)
        this.getData(true);
      else {
        this.loadingStore.setLoading(true);
        this.orphanStore.search(this.search, page_number, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
        this.loadingStore.setLoading(false);
      }
    },
    changePage(next) {
      this.page.value = next ? this.page.value + 1 : this.page.value - 1;

      this.getData();
    },
    fetch(status = false, is_update = false) {
      if (status) {
        let status = false;
        if (this.selected_sort_column == 'updated_at' && !is_update)
          status = true;
        this.getData(undefined, status);
      }
    },
    deleteOrphan(orphan) {
      this.notificationStore.popupDialog(this.t('orphan_modal_delete_button'),
        this.t('orphan_modal_cancel_button'), this.t('orphan_family_delete_title'), orphan.name + " (" + orphan.ngo_id + ") " + this.t('orphan_delete_text'), true, orphan, 'center', this.deleteCall, undefined);
    },

    async deleteCall(family) {
      this.loadingStore.setLoading(true);
      let response = await this.familyStore.deleteFamily(family.id);
      if (response.status) {
        if (this.families.number_of_data % this.families.per_page == 1 && this.page.value > 1)
          this.page.value -= 1;
        await this.getData();
        let delete_text = family.name + '(' + family.ngo_id + ') ' + this.t('orphan_delete_success');
        this.notificationStore.popupMessage(delete_text, "", true, "bottom", true);
      }
      else {

        let delete_text = family.name + '(' + family.ngo_id + ') ' + this.t('orphan_delete_error');
        this.notificationStore.popupMessage(delete_text, "", false, "center", false);
      }
      this.loadingStore.setLoading(false);
    },
    perPageChange() {
      this.page.value = 1;
      this.getData();
    },
    generateDate(date, include_date = false) {
      let d = new Date(date);
      const options = { weekday: 'short', month: 'short', day: 'numeric' };
      if (include_date) {
        delete options.weekday;
        options.year = 'numeric';
      }
      const formattedDate = d.toLocaleDateString('en-US', options);
      return formattedDate;
    },
    async getData(event, is_refresh = false) {
      const newURL = `${window.location.pathname}?page=${this.page.value}&per_page=${this.selected_per_page}&sort_column=${this.selected_sort_column}&sort_by=${this.selected_sort_by_options}`;
      router.push(newURL);
      this.loadingStore.setLoading(true);

      if (is_refresh)
        this.page.value = 1;

      await this.familyStore.getFamily(this.page.value, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
      this.loadingStore.setLoading(false);
    },

    check_selected_family_exist() {
      for (var member of this.families.data) {
        if (member.id == this.selected_family)
          return true;
      }
      return false;
    },
    async openModal(event, is_edit = false, data = undefined) {
      this.first_disable = true;
      this.ngo_id_disable = false;
      this.name_disable = false;
      this.last_name_disable = false;
      if (is_edit) {
        this.loadingStore.setLoading(true);
        let response = await this.orphanFamilyEditStates.getFamily(data.id);
        this.loadingStore.setLoading(false);

        if (!response.status) {
          let message = this.t('family_not_found');
          let text = this.t('please_refresh');
          this.notificationStore.popupMessage(message, text, false, 'center', false, this.close(), this.close());
        }

        this.editIsOpen = true;
      }
      else
        this.isOpen = true;
    },

    async check_fields(ngo_id, name, last_name) {
      if (ngo_id == undefined || ngo_id.length == 0)
        this.ngo_id_disable = true;
      else
        this.ngo_id_disable = false;
      if (name == undefined || name.length == 0)
        this.name_disable = true;
      else
        this.name_disable = false;
      if (last_name == undefined || last_name.length == 0)
        this.last_name_disable = true;
      else
        this.last_name_disable = false;
    },

  }

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 767px) {
  .right-element {
    margin-top: 10px;
  }

  .containers {
    width: 100%;
  }
}

.edit-button {
  margin-right: 2%;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1em auto;
  padding: 0.5em 3.5em 0.5em 1em;
}

.containers {
  width: 95%;
  margin-left: 2.5%;
}

label {
  float: left;
}
</style>
  