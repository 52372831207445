import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const RoleStore = defineStore("roleStore", {
  state: () => ({
    roles: undefined,
    createRoleError: "",
    page: {
      value: 0,
      count: 0,
    },
  }),

  getters: {
    allRoles: (state) => state.roles,
  },

  actions: {
    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async updateRole(data, id) {
      const url = "api/roles/" + id;
      return axios
        .put(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          if (err.response?.data?.error) {
            this.createRoleError = err.response.data.error;
          }
          return { status: false, data: err.response };
        });
    },

    async createRole(data) {
      const url = "api/roles";
      return axios
        .post(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          if (err.response?.data?.error) {
            this.createRoleError = err.response.data.error;
          }
          return { status: false, data: err.response };
        });
    },

    async deleteRole(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = "api/roles/" + id;
      return axios
        .delete(url)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async getRoles(page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/roles", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
          },
        })
        .then((response) => {
          this.page.count = response.data.number_of_page;
          this.page.value = this.page.count > 0 ? response.data.page_number : 0;
          this.roles = response.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async getRole(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }

      const url = "api/roles/" + id;
      return axios
        .get(url)
        .then((response) => {
          let data = response.data;
          return { status: true, data: data };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },
    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
