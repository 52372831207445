import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const DonationsStore = defineStore("donationsStore", {
  state: () => ({
    states: {
      enrollment_id: undefined,
    },
    page: {
      value: 0,
      count: 0,
    },
    donations: undefined,
    enrollments: undefined,
  }),

  getters: {},

  actions: {
    cleanStates() {
      this.states = {
        enrollment_id: undefined,
      };
      this.field_states = {
        is_exist: false,
        enable_force: false,
      };
      (this.donations = undefined), (this.enrollments = undefined);
    },

    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async destroy(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = "api/enrolled/sponsorship/donations/" + id;
      return axios
        .delete(url)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async get(page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }

      let url = "api/enrolled/sponsorship/donations?";
      if (this.states.enrollment_id != undefined)
        url += "&enrollment_id=" + this.states.enrollment_id;

      return axios
        .get(url, {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
          },
        })
        .then((response) => {
          this.donations = response.data.data;
          this.page.count = response.data.number_of_page;
          this.page.value = this.page.count > 0 ? response.data.page_number : 0;
          this.page.number_of_data = response.data.number_of_data;
          this.page.per_page = response.data.per_page;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    //For create sponsorships
    async getEnrollments() {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/enrolled/sponsorship")
        .then((response) => {
          this.enrollments = response.data.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
