export default {
  "enrolled_sponsorships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parrainages inscrits"])},
  "sponosrship_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix du parrainage"])},
  "enrolled_orphan_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'orphelin inscrit"])},
  "enrolled_orphan_ngo_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l'ONG de l'orphelin inscrit"])},
  "enrolled_donator_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donateur inscrit"])},
  "enrolled_sponsorship_active_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut actif du parrainage"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "deactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
  "delete_enrollment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer l'inscription"])},
  "delete_enrollment_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription au parrainage"])},
  "delete_enrollment_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à l'orphelin"])},
  "delete_enrollment_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par le donateur"])},
  "enrollment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription supprimée"])},
  "enrolment_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer l'inscription"])},
  "select_sponsorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un parrainage"])},
  "enable_force": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forcer le don"])},
  "enable_force_enrollment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forcer l'inscription"])},
  "select_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une famille"])},
  "add_enrollment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une inscription"])},
  "update_enrollment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'inscription"])},
  "enrollment_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription au parrainage mise à jour"])},
  "enrollment_not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription au parrainage non mise à jour"])},
  "select_activation_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le type d'activation"])},
  "enrollment_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription introuvable"])},
  "donated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don effectué avec succès"])},
  "donated_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'effectuer le don"])},
  "result_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat introuvable"])}
}