<template>
    <div class="m-3">
        <!-- <div class="row">
        <div class="col-md-6">
          <input @keyup="searching" @paste.once="pastedetected" v-model="search" class="form-control" id="myInput"
            type="text" placeholder="Search..">
        </div>
      </div> -->
        <div class="row justify-content-between">
            <div class="col col-md-8 d-flex flex-row justify-content-between">

                <select v-model="states.enrollment_id" class="form-control mx-1" id="familyFilter" @change="getData">
                    <option :value=undefined>{{ t('select_sponsorship') }}</option>
                    <option v-for="option in enrollments" :value="option.id" :key="option.value">{{ option.donator.name +
                        "-> " +
                        option.orphan.name + " (" + option.id + ")" }}
                    </option>
                </select>
                <select class="form-control mx-1" id="fiscal-year" v-model="selectedYear" @change="getData">
                    <option :value=null>Select fiscal year</option>
                    <option v-for="option in fiscalYears" :value="option" :key="'family' + option"> {{
                        option }} </option>
                </select>
            </div>
            <!-- <div class="col col-md-2 d-flex flex-row justify-content-end">
                <button id="refreshTable" class="edit-buttons btn btn-primary" @click="getData(event, true)">{{
                    t('orphan_table_operation_refresh') }}</button>
            </div> -->
        </div>
        <br />
        <DonationsGraph />
        <br />

        <!-- <div class="table-responsive">


            <h2 v-if="donations != undefined && donations.length == 0">Not Found</h2>
            <br />
            <table v-if="donations != undefined && donations.length > 0" class="table table-bordered table-striped">

                <thead>
                    <tr>
                        <th data-field="id" data-sortable="true">{{ t('sponsorships_id') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('enrolled_sponsorships') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('enrolled_orphan_ngo_id') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('enrolled_orphan_name') }}</th>
                        <th data-field="name" data-sortable="true">{{ t('enrolled_donator_name') }}</th>

                        <th width="12%" data-field="name" data-sortable="true">{{ t('amount') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{
                            t('orphan_table_column_registration_date') }}</th>

                    </tr>
                </thead>
                <tbody id="myTable" v-if="donations != undefined">
                    <tr v-for="donation in donations " :key="donation.id">
                        <td>{{ donation.id }}</td>
                        <td>{{ donation.enrolled_sponsorships.sponsorships.name }}</td>
                        <td>{{ donation.enrolled_sponsorships.orphan.ngo_id }}</td>
                        <td>{{ donation.enrolled_sponsorships.orphan.name }} {{ donation.is_family ? (t("family")) :
                            donation.enrolled_sponsorships.orphan.last_name
                        }} </td>
                        <td>{{ donation.enrolled_sponsorships.donator.name + " " +
                            donation.enrolled_sponsorships.donator.last_name }}
                            ({{ donation.enrolled_sponsorships.donator.id
                            }})</td>
                        <td>{{ donation.enrolled_sponsorships.sponsorships.currency.symbol + " " +
                            donation.enrolled_sponsorships.sponsorships.amount }}</td>

                        <td>{{ generateDate(donation.created_at, true) }}</td>

                    </tr>
                </tbody>
            </table>
        </div> -->

    </div>
</template>
    
<script>
import { LoadingStore } from '@/stores/loading';
import { storeToRefs } from 'pinia';
import { NotificationStore } from '@/stores/notification';
import { TaxReceiptStore } from '@/stores/taxReceiptStore';
import { DonationsStore } from "@/stores/DonationsStore";
import { StatsStore } from "@/stores/statsStore"
import DonationsGraph from "./DonationsGraph.vue"
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import router from '@/routes/index.js'

export default {
    name: 'DonationHistory',
    components: {
        DonationsGraph
    },

    setup() {
        const loadingStore = LoadingStore();
        const notificationStore = NotificationStore();
        const { is_loading } = storeToRefs(loadingStore);
        const donationsStore = DonationsStore();
        const taxReceiptStore = TaxReceiptStore();
        const statsStore = StatsStore();
        const { states, enrollments, donations, graphData } = storeToRefs(statsStore);
        const { fiscalYears, selectedYear } = storeToRefs(taxReceiptStore)
        const isOpen = ref(false);
        const { t } = useI18n({});
        return {
            loadingStore,
            notificationStore,
            donationsStore,
            enrollments,
            statsStore,
            donations,
            states,
            fiscalYears, selectedYear,
            graphData,
            taxReceiptStore,
            is_loading,
            isOpen, t
        };
    },

    watch: {


        donations: {
            deep: true
        },
        enrollments: {
            deep: true
        },
        currencies: {
            handler(newv) {
                console.log("NEW", newv);


            },
            deep: true
        },
        states: {
            handler(newv) {
                console.log("NEW", newv);


            },
            deep: true
        },
        donates: {
            deep: true,
        },

        donators: {
            deep: true
        },
        campaigns: {
            deep: true,
        }
    },
    data() {
        return {

            isModalOpen: false,
            editIsOpen: false,
            isDisabled: false,
            first_disable: true,
            clean_state: false,
            name_disable: false,
            last_name_disable: false,
            ngo_id_disable: false,
            timeout: undefined,
            search: '',

        };
    },

    async created() {
        let params = this.parseURLParamaters();

        this.selectedYear = params.year
        await this.getPreData();
        await this.taxReceiptStore.getFiscalYears();

        //   await this.getData();
    },


    methods: {
        sendMail() {
            alert("TODO : Send Mail");
            // TODO : you need to implement send mail notification on laravel backend and your store
        },
        parseURLParamaters() {
            const currentYear = new Date().getFullYear();
            const url_params = new URLSearchParams(window.location.search);
            let params = {
                page: url_params.get('page') ? url_params.get('page') : 1,
                per_page: url_params.get('per_page') ? url_params.get('per_page') : 5,
                sort_column: url_params.get('sort_column') ? url_params.get('sort_column') : 'created_at',
                sort_by: url_params.get('sort_by') ? url_params.get('sort_by') : '3',
                enrollment_id: url_params.get('enrollment_id') ? url_params.get('enrollment_id') : undefined,
                year: url_params.get('year') ? url_params.get('year') : currentYear,

            };
            return params;
        },

        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.search_fetch, 150);
        },


        fetch(status = false, is_update = false) {
            if (status) {
                let status = false;
                if (this.selected_sort_by_options == 3 && !is_update)
                    status = true;
                this.getData(undefined, status);

            }
        },

        async search_fetch(page_number = 1) {
            if (page_number == 0)
                page_number = 1;
            if (this.search.length == 0) {
                this.getData(undefined, true);
            }
            else {
                this.loadingStore.setLoading(true);
                await this.donationsStore.search_fetch(this.search, 1, page_number, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
                this.loadingStore.setLoading(false);
            }
        },
        async openModal(event, is_edit = false, data = undefined) {
            if (is_edit) {
                this.loadingStore.setLoading(true);
                let response = await this.donationsStore.getEnrollment(data.id);

                if (!response.status) {
                    let message = this.t('enrollment_not_found');
                    let text = this.t('refresh_data');
                    this.notificationStore.popupMessage(message, text, false, 'center', false, this.close(), this.close());
                }
                else {
                    this.editIsOpen = true;

                }
                this.loadingStore.setLoading(false);

            }
            else
                this.isOpen = true;
        },

        close() {
            this.editIsOpen = false;
            this.isOpen = false;
            this.donationsStore.cleanStates();
        },



        async getPreData() {
            this.loadingStore.setLoading(true);
            await this.statsStore.getEnrollments();
            await this.getData();
            this.loadingStore.setLoading(false);
        },

        async getData() {
            const newURL = `${window.location.pathname}?year=${this.selectedYear}&enrollment_id=${this.states.enrollment_id}`;
            router.push(newURL);
            this.loadingStore.setLoading(true);
            await this.statsStore.get(this.selectedYear)
            this.loadingStore.setLoading(false);
            console.log(this.graphData)
        },


        generateDate(date, include_date = true) {
            let d = new Date(date);
            const options = { weekday: 'short', month: 'short', day: 'numeric' };
            if (include_date) {
                delete options.weekday;
                options.year = 'numeric';
            }
            const formattedDate = d.toLocaleDateString('en-US', options);
            return formattedDate;
        },
    },

}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 767px) {
    .right-element {
        margin-top: 10px;
    }

    .containers {
        width: 100%;
    }
}

.edit-button {
    margin-right: 2%;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}

td>button {
    margin-left: 2%;
}

button:disabled {
    background: #cccccc;
    color: black;
    border-color: black;
}

.containers {
    width: 95%;
    margin-left: 2.5%;
}

label {
    float: left;
}
</style>
    