export default {
  "sponsorships_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "sponsorships_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "sponsorships_name_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name already exist"])},
  "sponsorships_amount_field_can_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount can not be empty or equal to 0"])},
  "sponsorships_name_field_can_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name can not be empty"])},
  "sponsorships_name_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Name"])},
  "sponsorships_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "sponsorships_price_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Amount"])},
  "sponsorships_price_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount can not be empty !"])},
  "sponsorships_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "sponosrships_modal_add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Sponsorships Type"])},
  "sponsorships_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "sponsorships_type_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship type successfully added"])},
  "sponsorships_type_not_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship type could not added"])},
  "sponsorships_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete Sponsorship Type ?"])},
  "sponsorships_type_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship type successfully deleted"])},
  "sponsorships_type_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship type can not deleted"])},
  "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data can be already deleted. Use refresh button to refresh table"])},
  "sponosrships_modal_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Sponsorship"])},
  "sponsorships_type_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorships Type updated"])},
  "sponsorships_type_not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorships Type not updated"])},
  "sponsorships_type_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorships Type not found"])},
  "missing_donation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " is missing a donation for ", _interpolate(_named("sponsorship")), "!"])},
  "last_donation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The last donation was made on ", _interpolate(_named("date"))])},
  "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
  "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
  "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
  "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
  "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
  "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
  "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "don_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations made by month"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in"])}
}