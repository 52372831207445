export default {
  "orphan_modal_add_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouvel orphelin"])},
  "orphan_modal_ngo_id_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez l'ID de l'ONG"])},
  "orphan_modal_update_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'orphelin"])},
  "orphan_modal_delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'orphelin"])},
  "orphan_modal_ngo_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l'ONG"])},
  "orphan_modal_civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilité"])},
  "orphan_modal_civility_option_undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non défini"])},
  "orphan_modal_civility_option_mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
  "orphan_modal_civility_option_ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
  "orphan_modal_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "orphan_modal_name_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le nom de l'orphelin"])},
  "orphan_modal_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "orphan_modal_lastname_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le nom de famille"])},
  "orphan_modal_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "orphan_modal_country_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un pays"])},
  "orphan_modal_dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "orphan_modal_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "orphan_modal_phone_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le numéro de téléphone"])},
  "orphan_modal_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille"])},
  "orphan_modal_family_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sélectionné"])},
  "orphan_modal_add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un orphelin"])},
  "orphan_modal_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'orphelin"])},
  "orphan_modal_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "orphan_modal_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
}