<template>
    <div class="change-password p-0">
        <div class="modal-header">
            <h4 class="modal-title">{{ t('assign_role') }}</h4>
        </div>

        <form @submit.prevent="submitForm" class="mt-2 d-flex flex-row">
            <div class=" d-flex flex-column align-items-start w-50 justify-content-evenly mt-2">
                <label v-if="selected_user">Name: {{ selected_user.name }} </label>
                <label v-if="selected_user">Email: {{ selected_user.email }} </label>
            </div>
            <div class="form-group d-flex flex-column align-items-start w-50 ml-2">
                <label>{{ t('role') }} </label>
                <select v-model="selected_role" class="form-control mx-1" id="familyFilter" v-if="roles?.data">
                    <option v-for="role in roles.data" :value="role.id" :key="role.id">{{ role.name
                    }}
                    </option>
                </select>
            </div>
        </form>
        <div class="error-message">{{ updateRoleError }}</div>
        <div class="modal-footer">
            <button @click="updateRole" type="submit" style="color: white" class="btn btn-primary add-button">
                {{ t('assign_role') }}
            </button>

            <button type="button" style="color: white" class="btn btn-danger" @click="close">
                {{ t('orphan_modal_cancel_button') }}

            </button>
        </div>

    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { UserStore } from '@/stores/userStore';
import { NotificationStore } from '@/stores/notification';
import { RoleStore } from '@/stores/roles';

export default {
    name: 'EditRoleModal',
    props: ['selected_user'],
    data() {
        return {
            selected_role: undefined,
            oldPassword: "",
            password: '',
            confirmPassword: '',

        };
    },

    watch: {
        selected_user: {
            handler(newVal) {
                // Update selected_role when selected_user changes
                this.selected_role = newVal && newVal.role ? newVal.role.id : undefined;
            },
            deep: true,
            immediate: true // This will trigger the handler immediately when the component is created
        },
        selected_role: {
            deep: true
        }
    },

    async created() {
        await this.roleStore.getRoles();
        this.selected_role = this.selected_user?.role?.id
        this.clearModal()
    },
    methods: {
        clearModal() {
            this.selected_role = this.selected_user && this.selected_user.role ? this.selected_user.role.id : undefined;

        },
        close() {
            this.clearModal()
            this.$emit('close');

        },
        async updateRole() {
            let response = await this.userStore.updateRole(this.selected_user.id, this.selected_role);
            if (response.status) {
                this.$emit('status', true);
                this.close();
            }
            let message = response.status ? this.t('role_change_success') : this.t('role_change_fail');
            this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
        },

        onChange(role) {
            this.selected_role = role
        }

    },
    setup() {
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const languageStore = LanguageStore();
        const { t } = useI18n({});
        const notificationStore = NotificationStore()
        const userStore = UserStore();
        const roleStore = RoleStore();
        const { user_info, updateRoleError } = storeToRefs(userStore);
        const { roles } = storeToRefs(roleStore);
        return { t, is_loading, languageStore, loadingStore, user_info, updateRoleError, userStore, notificationStore, roleStore, roles };
    },
}
</script>
  
<style scoped>
.error-message {
    color: red;
    margin-bottom: 20px;
}
</style>