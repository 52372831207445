export default {
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bonjour c'est un essai"])},
  "header_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "header_admin_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau d'administration"])},
  "header_orphans_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphelins > Famille"])},
  "header_orphans_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphelins > Dons"])},
  "header_sponsorship_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parrainages > Types"])},
  "header_campaign_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagnes > Types"])},
  "header_campaign_donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dons de campagne"])},
  "header_donators_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donateurs > Public"])},
  "header_donators_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donateurs > Privé"])},
  "header_tax_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donateurs > Reçu fiscal"])}
}