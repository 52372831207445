export default {
  "orphan_filter_family_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All family"])},
  "orphan_filter_civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
  "orphan_filter_civility_male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr"])},
  "orphan_filter_civility_female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms"])},
  "orphan_filter_civility_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "orphan_filter_civility_undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
  "orphan_filter_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
  "orphan_filter_updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
  "orphan_filter_order_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort By"])},
  "orphan_filter_order_by_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desc"])},
  "orphan_filter_order_by_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asc"])},
  "orphan_table_operation_refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
  "orphan_table_operation_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "orphan_table_column_ngoid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngo ID"])},
  "orphan_table_column_civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
  "orphan_table_column_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "orphan_table_column_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "orphan_table_column_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "orphan_table_column_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "orphan_table_column_dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Of Birth"])},
  "orphan_table_column_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "orphan_table_footer_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
  "orphan_table_footer_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "orphan_modal_add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add orphan"])},
  "orphan_modal_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update orphan"])},
  "orphan_modal_cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "orphan_modal_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "orphan_family_not_found_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Family Not Found"])},
  "orphan_not_added_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphan Already exist or can not added"])},
  "orphan_added_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphan Added Sucessfully"])},
  "orphan_updated_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphan Updated Sucessfully"])},
  "orphan_not_updated_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orphan is not updated"])},
  "orphan_table_column_registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Date"])},
  "orphan_table_column_update_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Date"])},
  "orphan_table_column_checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked Status"])},
  "orphan_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete orphan ?"])},
  "orphan_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will be deleted, you won't be able to revert"])},
  "orphan_delete_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted successfully"])},
  "orphan_delete_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not deleted"])},
  "orphan_next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "orphan_prev_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prev"])},
  "orphan_name_field_can_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name field can not be empty."])},
  "orphan_last_name_field_can_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name field can not be empty."])},
  "orphan_ngo_id_field_can_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngo ID field can not be empty."])},
  "orphan_ngo_id_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given Ngo ID already exist."])},
  "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Country"])},
  "orphan_family_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete orphan family ?"])},
  "orphan_family_add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Family"])},
  "orphan_family_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Family"])},
  "orphan_family_modal_name_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter family name"])},
  "select_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Currency"])},
  "treatment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment Type"])},
  "no_treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No treatment"])},
  "Medical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical"])},
  "Finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
  "Undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
  "orphan_modal_treatmnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment Type"])},
  "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked"])},
  "not_checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Checked"])}
}