<template>
    <BaseModal :open="isOpen" @close="isOpen = !isOpen">
        <CreateUserModal @close="isOpen = !isOpen" :is_login='false'></CreateUserModal>
    </BaseModal>
    <BaseModal :open="roleEditOpen" @close="roleEditOpen = !roleEditOpen">
        <EditRoleModal @close="roleEditOpen = !roleEditOpen" :selected_user='selectedUser'></EditRoleModal>
    </BaseModal>
    <div class=" m-3 py-2">
        <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-row w-75">

                <div class="mx-2">
                    <select v-model="selected_sort_by_options" class="form-control" id="familyFilter" @change="getData">
                        <option v-for="option in sort_by_options" :value="option.value" :key="option.value">{{
                            t(option.label)
                        }}
                        </option>
                    </select>
                </div>

                <div class="mx-2">
                    <select v-model="selected_sort_column" class="form-control" id="familyFilter" @change="getData">
                        <option v-for="option in sort_column_options" :value="option.value" :key="option.value">{{
                            t(option.label) }}
                        </option>
                    </select>
                </div>
            </div>

            <div class=" mx-1 w-25">
                <button class="edit-buttons btn btn-primary w-100" @click="openModal">{{
                    t('create_user')
                }}
                </button>
            </div>

            <br />



        </div>
        <br />
        <div class="row">
        </div>
        <div class="table-responsive">
            <br />
            <h2 v-if="users != undefined && users.data.length == 0">Not Found</h2>
            <table v-if="users != undefined && users.data.length > 0" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th data-field="id" data-sortable="true">Id</th>
                        <th data-field="name" data-sortable="true">{{ t('orphan_table_column_name') }}</th>

                        <th data-field="registration_date" data-sortable="true">{{
                            t('orphan_table_column_registration_date') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_update_date') }}
                        </th>
                        <th data-field="registration_date" data-sortable="true">Email</th>
                        <th data-field="registration_date" data-sortable="true">{{ t('role') }}</th>
                        <th data-field="registration_date" data-sortable="true">{{ t('orphan_table_column_actions') }}</th>

                    </tr>
                </thead>
                <tbody id="myTable" v-if="users != undefined">
                    <tr v-for="user in users.data" :key="user.id">
                        <td>{{ user.id }}</td>
                        <td>{{ user.name }}</td>
                        <td>{{ generateDate(user.created_at) }}</td>
                        <td>{{ generateDate(user.updated_at) }}</td>
                        <td>
                            {{ user.email }}

                        </td>
                        <td>{{ user.role ? user.role.name : "N/A" }}</td>
                        <td>
                            <button @click="openRoleEditModal(user)" class="btn edit-button btn-sm btn-outline-primary"><i
                                    class="fa fa-pencil"></i> </button>
                            <button class="btn btn-sm btn-outline-danger mx-1" @click="deleteUser(user)"><i
                                    class="fa fa-trash"></i>
                            </button>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-sm-1">
                <select v-model="selected_per_page" id="pageSize" class="form-control d-inline-block w-auto"
                    @change="perPageChange">
                    <option v-for="option in per_page_options" :value="option.value" :key="option.value">{{ (option.label)
                    }}
                    </option>
                </select>
            </div>
            <div class="col-sm">
                <div class="float-right">
                    <button class="btn btn-outline-secondary" :disabled="page == 1" @click="changePage(false)">
                        <i class="fas fa-angle-left"></i> {{ t('orphan_prev_button') }}
                    </button>
                    <button class="btn btn-outline-secondary" :disabled="page + 1 > page_count" @click="changePage(true)">
                        {{ t('orphan_next_button') }} <i class="fas fa-angle-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="float-right mr-3">
            <span>{{ t('orphan_table_footer_page') }} {{ page }} </span>
            <span>{{ t('orphan_table_footer_total') }} {{ page_count }}</span>
        </div>
    </div>
</template>
    
<script>

import { LoadingStore } from '@/stores/loading';
import { OrphanStore } from "@/stores/orphan";
import { OrphanEditStates } from "@/stores/orphanEditStates";
import { storeToRefs } from 'pinia';
import { NotificationStore } from '@/stores/notification';
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import { UserStore } from '@/stores/userStore';
import BaseModal from '../BaseModal.vue';
import CreateUserModal from './Modal/CreateUserModal.vue';
import EditRoleModal from './Modal/EditRoleModal.vue';

export default {
    name: 'UsersCard',
    components: {
        BaseModal, CreateUserModal, EditRoleModal
    },

    setup() {
        const loadingStore = LoadingStore();
        const orphanStore = OrphanStore();
        const userStore = UserStore()
        const orphanEditStates = OrphanEditStates();
        const { users } = storeToRefs(userStore);
        const { states } = storeToRefs(orphanEditStates);
        const notificationStore = NotificationStore();
        const { is_loading } = storeToRefs(loadingStore);
        const isOpen = ref(false);
        const { t } = useI18n({});
        return {
            loadingStore,
            orphanStore,
            userStore,
            users,
            orphanEditStates,
            states,
            notificationStore,
            is_loading,
            isOpen, t
        };
    },

    watch: {
        users: {
            handler() {
                this.page = this.users.current_page;
                this.page_count = this.users.last_page;
            },
            deep: true
        },
        isOpen: {
            handler() {
                if (!this.isOpen) {
                    this.getData()
                    this.selectedUser = undefined
                }
            }
        },
        roleEditOpen: {
            handler() {
                if (!this.roleEditOpen) {
                    this.getData()
                    this.selectedUser = undefined
                }
            }
        }
    },
    data() {
        return {
            page: 0,
            page_count: 0,
            sort_column_options: [
                { label: 'orphan_filter_created_at', value: 'created_at' },
                { label: 'orphan_filter_updated_at', value: 'updated_at' },
            ],
            selected_sort_column: 'created_at',
            sort_by_options: [
                { label: 'orphan_filter_order_by_desc', value: '3' },
                { label: 'orphan_filter_order_by_asc', value: '2' },
            ],
            per_page_options: [
                { label: '5', value: '5' },
                { label: '10', value: '10' },
                { label: '15', value: '15' },
            ],
            selected_per_page: 5,
            selected_sort_by_options: '3',
            editIsOpen: false,
            roleEditOpen: false,
            timeout: undefined,
            search: '',
            selectedUser: undefined,

        };
    },

    async created() {
        let params = this.parseURLParamaters();
        this.page = params.page;
        this.selectedUser = undefined
        this.selected_per_page = params.per_page;
        this.selected_sort_column = params.sort_column;
        this.selected_sort_by_options = params.sort_by;
        await this.getData();
    },

    methods: {
        parseURLParamaters() {
            const url_params = new URLSearchParams(window.location.search);
            let params = {
                page: url_params.get('page') ? url_params.get('page') : 1,
                per_page: url_params.get('per_page') ? url_params.get('per_page') : 5,
                sort_column: url_params.get('sort_column') ? url_params.get('sort_column') : 'created_at',
                sort_by: url_params.get('sort_by') ? url_params.get('sort_by') : '3',
            }
            return params;
        },

        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.SearchUser, 100);
        },

        SearchUser(page_number = 1) {

            this.loadingStore.setLoading(true);
            this.userStore.getUsers(page_number, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options)
            //TODO: implement search for users 
            this.loadingStore.setLoading(false);

        },
        changePage(next) {
            this.page = next ? this.page + 1 : this.page - 1;
            if (this.search.length > 0)
                this.SearchUser(this.page);
            else
                this.getData();
        },

        deleteUser(user) {
            this.notificationStore.popupDialog(this.t('orphan_modal_delete_button'), this.t('orphan_modal_cancel_button'), this.t('user_delete_title'), user.name + " (" + user.id + ") " + this.t('user_delete_text'), true, user, 'center', this.deleteCall, undefined);
        },

        async deleteCall(user) {
            this.loadingStore.setLoading(true);
            let response = await this.userStore.deleteUser(user.id);
            if (response.status) {
                if (this.users.number_of_data % this.users.per_page == 1 && this.page > 1)
                    this.page -= 1;
                await this.getData();
                let delete_text = user.name + '(' + user.id + ') ' + this.t('user_delete_success');
                this.notificationStore.popupMessage(delete_text, "", true, "bottom", true);
            }
            else {
                let error_message = undefined;
                if (response.data?.data?.error) {
                    error_message = response.data.data.error
                }
                if (response.data?.status == 403) {
                    error_message = "Unauthorised"
                }
                // Call api
                let delete_text = user.name + '(' + user.id + ') ' + `${this.t('user_delete_error')}.  ${error_message}`;
                this.notificationStore.popupMessage(delete_text, "", false, "center", false);
            }
            this.loadingStore.setLoading(false);
        },
        perPageChange() {
            this.page = 1;
            this.getData();
        },

        generateDate(date, include_date = false) {
            let d = new Date(date);
            const options = { weekday: 'short', month: 'short', day: 'numeric' };
            if (include_date) {
                delete options.weekday;
                options.year = 'numeric';
            }
            const formattedDate = d.toLocaleDateString('en-US', options);
            return formattedDate;
        },
        async getData() {
            this.loadingStore.setLoading(true);
            await this.userStore.getUsers(this.page, this.selected_per_page, this.selected_sort_column, this.selected_sort_by_options);
            this.loadingStore.setLoading(false);

        },


        openModal() {
            this.isOpen = true
        },
        openRoleEditModal(user) {
            this.roleEditOpen = true
            this.selectedUser = user
        },
        closeModal(is_edit = false) {
            if (is_edit)
                this.editIsOpen = false;
            else
                this.isOpen = false;
        },




    }

}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 767px) {}

/* .edit-button {
    margin-right: 2%;
} */

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>
    