<template>
  <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0  h-100" id="navbar">
    <div class="d-flex flex-column align-items-center align-items-sm-start px-4 pt-2 text-black min-vh-100">
      <div>
        <a href="/" class="d-flex align-items-center pb-1 mb-md-0 me-md-auto text-black text-decoration-none">
          <span style="text-align:left;" class="fs-3 d-none d-sm-inline pt-2 font-weight-bold logo">NGOSG</span>
        </a>
      </div>
      <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
        <li>
          <a href="#submenu1" data-bs-toggle="collapse" class="nav-link px-0 py-1 align-middle" id="collapseOne">
            <i class="fs-4 bi-speedometer2"></i> <span class="ms-1 d-none d-sinline">{{ t('navbar_dashboard') }}</span>

            <i class="bi bi-chevron-down ml-2"></i>
          </a>
          <ul class="collapse show nav flex-column ms-1" id="submenu1">

            <li>
              <router-link :to="'/app/donation/history'" class="nav-link px-0"> <span class="bi-bar-chart-fill"
                  style="margin-right:10px;">
                </span><span class="d-none d-sm-inline">{{ t('navbar_donation_history') }}</span></router-link>
            </li>
            <li class="">
              <router-link :to="'/app/emails'" class="nav-link px-0">
                <span class="bi-envelope" style="margin-right:10px;">
                </span><span class="d-none d-sm-inline">{{ t('navbar_emails') }}</span>
              </router-link>

            </li>
          </ul>
        </li>
        <li>
          <a href="#submenu2" data-bs-toggle="collapse" class="nav-link  py-1 px-0 align-middle">
            <i class="fs-4 bi-table"></i> <span class="ms-1 d-none d-sm-inline">{{ t('navbar_orphans') }}</span>
            <i class="bi bi-chevron-down ml-2"></i>
          </a>

          <ul class="collapse show nav flex-column ms-1" id="submenu2">
            <li>
              <router-link :to="'/app/orphans'" class="nav-link px-0"> <span class="bi-person" style="margin-right:10px;">
                </span><span class="d-none d-sm-inline">{{ t('navbar_orphans_') }}</span></router-link>
            </li>
            <li>
              <router-link :to="'/app/orphans/family'" class="nav-link px-0"> <span class="bi-people"
                  style="margin-right:10px;"> </span><span class="d-none d-sm-inline">{{ t('navbar_orphans_family')
                  }}</span></router-link>
            </li>
            <li>
              <router-link :to="'/app/orphans/donations'" class="nav-link px-0"> <span class="bi-cash-stack"
                  style="margin-right:10px;"></span><span class="d-none d-sm-inline ">{{ t('navbar_orphans_donations')
                  }}</span> </router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#submenu3" data-bs-toggle="collapse" class="nav-link px-0  py-1 align-middle">
            <i class="fs-4 bi-table"></i> <span class="ms-1 d-none d-sm-inline">{{ t('navbar_sponsorships') }}</span>
            <i class="bi bi-chevron-down ml-2"></i>
          </a>
          <ul class="collapse show nav flex-column ms-1" id="submenu3">
            <li>
              <router-link :to="'/app/sponsorships/'" class="nav-link px-0">
                <span class="material-symbols-outlined">
                  handshake
                </span><span class="d-none d-sm-inline">{{ t('navbar_sponsorships_') }}</span></router-link>
            </li>
            <li>
              <router-link :to="'/app/sponsorships/types'" class="nav-link px-0">
                <span class="material-symbols-outlined">
                  filter_alt
                </span><span class="d-none d-sm-inline">{{ t('navbar_sponsorships_type') }}</span></router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#submenu4" data-bs-toggle="collapse" class="nav-link px-0  py-1 align-middle">
            <i class="fs-4 bi-table"></i> <span class="ms-1 d-none d-sm-inline">{{ t('navbar_campaigns') }}</span>
            <i class="bi bi-chevron-down ml-2"></i>
          </a>
          <ul class="collapse show nav flex-column ms-1" id="submenu4">
            <li>
              <router-link :to="'/app/campaigns'" class="nav-link px-0">
                <span class="material-symbols-outlined">
                  campaign
                </span><span class="d-none d-sm-inline">{{ t('navbar_campaigns') }}</span></router-link>

            </li>
            <li>
              <router-link :to="'/app/campaigns/types'" class="nav-link px-0">
                <span class="material-symbols-outlined">
                  filter_alt
                </span><span class="d-none d-sm-inline">{{ t('navbar_campaigns_type') }}</span></router-link>
            </li>
            <li>
              <router-link :to="'/app/campaigns/donations'" class="nav-link px-0">
                <span class="bi-cash-stack" style="margin-right:10px;"></span><span class="d-none d-sm-inline ">{{
                  t('navbar_campaigns_donations') }}</span>
              </router-link>
            </li>
          </ul>
        </li>

        <li>
          <a href="#submenu5" data-bs-toggle="collapse" class="nav-link px-0  py-1 align-middle">
            <i class="fs-4 bi-table"></i> <span class="ms-1 d-none d-sm-inline">{{ t('navbar_donators') }}</span>
            <i class="bi bi-chevron-down ml-2"></i></a>
          <ul class="collapse show nav flex-column ms-1" id="submenu5">
            <li>
              <router-link :to="'/app/donators/public'" class="nav-link px-0">
                <span class="material-symbols-outlined">
                  public
                </span><span class="d-none d-sm-inline">{{ t('navbar_donators_public') }}</span></router-link>
            </li>
            <li>
              <router-link :to="'/app/donators/private'" class="nav-link px-0">
                <span class="material-symbols-outlined">
                  lock
                </span><span class="d-none d-sm-inline">{{ t('navbar_donators_private') }}</span></router-link>
            </li>

            <li>
              <router-link :to="'/app/donators/tax-receipt'" class="nav-link px-0">
                <span class="material-symbols-outlined">
                  payments
                </span> <span class="d-none d-sm-inline">{{ t('navgar_donators_donates') }}</span></router-link>
            </li>
          </ul>
        </li>

      </ul>
      <hr>
      <!-- <div class="dropdown pb-4">
        <div class="d-flex align-items-center text-black text-decoration-none dropdown-toggle" id="dropdownUser1"
          data-bs-toggle="dropdown" aria-expanded="false">
          <span class="d-none d-sm-inline mx-1 bi bi-person">{{ this.user_info.name }}</span>
        </div>
        <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
          <li><a class="dropdown-item" href="#">New User</a></li>
          <li><a class="dropdown-item" href="#">Settings</a></li>
          <li><a class="dropdown-item" href="#">Profile</a></li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li><a class="dropdown-item" href="#">Sign out</a></li>
        </ul>
      </div> -->
    </div>
  </div>
</template>


<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import { useI18n } from 'vue-i18n';


export default {
  name: "NavBar",
  setup() {



    const { t } = useI18n({});
    return {
      t,

    };
  },


};


</script>
<style scoped>
.logo {
  color: #D6474B
}

#navbar {
  height: 100%;
  border-right: 1px solid #8a8a8a52;
  background-color: rgba(236, 235, 235, 0.405);
}

li {
  text-align: left;
}
</style>
  