<template>
    <div class="vue-modal" v-show="open" @click.self="$emit('close')">
        <div class="vue-modal-inner">

            <!-- <button type="button" class="close-btn" aria-label="Close" @click="$emit('close')">
                <span aria-hidden="true">&times;</span>

            </button> -->
            <div class="vue-modal-content">
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "BaseModal",
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleKeyUp(event) {
            if (event.keyCode === 27 && this.open) {
                this.$emit('close');
            }
        }
    },

    mounted() {
        document.addEventListener("keyup", this.handleKeyUp);
    },
    beforeUnmount() {
        document.removeEventListener("keyup", this.handleKeyUp);
    }

};
</script>
  
<style scoped>
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.close-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    cursor: pointer;
}

.close-btn:hover {
    color: gray;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.vue-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.vue-modal-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1200px;
    width: 900px;
    max-height: calc(100% - 0rem);
    overflow-y: auto;
    background-color: #fff;
    border-radius: 0.3rem;
    padding: 1rem;
}

.vue-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.3rem;
    padding: 1rem;
}
</style>