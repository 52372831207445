export default {
  "donators_table_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "donators_table_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "donators_table_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "donators_table_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "donators_table_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "donators_table_civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
  "donators_table_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donators Type"])},
  "donators_table_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "donators_table_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["County"])},
  "donators_table_county_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter county"])},
  "donators_table_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
  "donators_table_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "donator_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Donators"])},
  "donator_regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
  "donator_vip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vip"])},
  "donators_table_phone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number 2"])},
  "donators_table_email2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email 2"])},
  "donators_email_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
  "donators_email_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donator is already exist"])},
  "donators_email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email field can not be empty"])},
  "donators_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name"])},
  "donators_name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name field can not be empty"])},
  "donators_lastname_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter last name"])},
  "donators_lastname_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name field can not be empty"])},
  "donators_phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter phone number"])},
  "donators_address_county_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter County"])},
  "donators_address_place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Address"])},
  "donators_adress_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter zipcode"])},
  "donators_modal_add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Donator"])},
  "donators_modal_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Donator"])},
  "donators_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete donator ?"])},
  "donators_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donators added successfully"])},
  "donators_not_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not add donators"])},
  "select_donator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Donator"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "donate_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the donation"])},
  "donate_delete_text_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation by "])},
  "donate_delete_text_donation_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" to campaign "])},
  "donate_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donation successfully deleted"])},
  "donate_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate can not deleted"])},
  "update_donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Donations"])},
  "donation_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation updated"])},
  "donation_not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation not updated"])},
  "donation_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation not found"])},
  "import_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import CSV"])},
  "csv_has_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csv is not correct format"])},
  "csv_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csv uploaded"])},
  "donator_type_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Donator Type"])},
  "create-tax-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Tax-Receipt"])},
  "generate-pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create PDF"])},
  "enter_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Total Donation"])},
  "select_fiscal_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select fiscal year"])},
  "total_donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Donated"])}
}