import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const StatsStore = defineStore("statsStore", {
  state: () => ({
    states: {
      enrollment_id: undefined,
      year: undefined,
    },
    graphData: undefined,
    donations: undefined,
    enrollments: undefined,
  }),

  getters: {},

  actions: {
    cleanStates() {
      this.states = {
        enrollment_id: undefined,
        year: undefined,
      };
      this.field_states = {
        is_exist: false,
        enable_force: false,
      };
      (this.donations = undefined), (this.enrollments = undefined);
    },

    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    createData(data, selectedYear) {
      let reconstructedData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (selectedYear && this.states.enrollment_id && data.length > 0) {
        data.forEach((donation) => {
          let date = new Date(donation.created_at);
          let month = date.getMonth();
          reconstructedData[month] += donation.amount;
        });
        this.graphData = {
          data: reconstructedData,
          sign: data[0].enrolled_sponsorships.sponsorships.currency.symbol,
        };
      } else {
        this.graphData = {
          data: reconstructedData,
          sign: undefined,
        };
      }
    },

    async get(selectedYear) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }

      let url = "api/enrolled/sponsorship/donations?";
      if (this.states.enrollment_id != undefined)
        url += "&enrollment_id=" + this.states.enrollment_id;
      if (selectedYear != undefined) url += "&year=" + selectedYear;

      return axios
        .get(url)
        .then((response) => {
          this.donations = response.data.data;
          this.createData(response.data.data, selectedYear);
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response?.status);
          return { status: false, message: "Not Found!" };
        });
    },

    //For create sponsorships
    async getEnrollments() {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/enrolled/sponsorship")
        .then((response) => {
          this.enrollments = response.data.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
