<template>
    <div>
        <div class="modal-header">
            <h5 class="modal-title">{{ t('create_donation') }}</h5>
        </div>
        <h6 style="color: red" v-if="!is_loading && campaigns == undefined">
            {{ t('please_add_campaign') }}
        </h6>
        <div class="modal-body" style="margin-top: 1%;"
            v-if="!is_loading && (campaigns != undefined && campaigns.length > 0)">
            <form>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('donation_amount') }}*</label>
                        <input type="number" step="0.5" @keyup="amountChanged" min=0 v-model="states.amount"
                            class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            :placeholder="t('enter_amount')" />
                        <h6 style="color: red" v-if="!states.amount > 0" id="emailHelp" class="form-text text-muted">
                            {{ t('amount_error') }}
                        </h6>
                    </div>

                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">{{ t('campaign_name') }}*</label>

                        <select class="form-control" id="campaignType" v-model="states.campaign_id">
                            <option v-for="option in campaigns" :value="option.id" :key="'family' + option.id"> {{
                                option.name }} {{ '('
        + option.id + ')' }}</option>
                        </select>
                    </div>

                    <div class="form-group col-md-6">
                        <div>
                            <h6>{{ t('register_title') }}*</h6>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" @change="updateSelection"
                                    v-model="states.selected_donator_type" type="radio" name="inlineRadioOptions"
                                    id="inlineRadio2" value=2>
                                <label class="form-check-label" for="inlineRadio2">{{ t('public_donators') }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" @change="updateSelection"
                                    v-model="states.selected_donator_type" type="radio" name="inlineRadioOptions"
                                    id="inlineRadio3" value=0>
                                <label class="form-check-label" for="inlineRadio3">{{ t('private_donators') }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label v-if="donators != undefined" for="family">{{ t('navbar_donators') }}*</label>
                        <h6 style="color: red"
                            v-if="states.selected_donator_type == 1 && (donators == undefined || donators.length == 0)">
                            {{ t('Private donators can no t be empty') }}
                        </h6>

                        <h6 style="color: red"
                            v-if="states.selected_donator_type == 0 && (donators == undefined || donators.length == 0)">
                            {{ t('Public donators can not be empty') }}
                        </h6>
                        <donatorSelect2 :changeItemId="changeDonatorId" :items="donators" :selected_item="selected_donator">
                        </donatorSelect2>

                    </div>

                </div>
            </form>
        </div>
        <div
            :class="canCheck ? 'w-100 d-flex flex-row justify-content-between' : 'w-100 d-flex flex-row justify-content-end'">
            <div class="form-check form-switch d-flex align-items-center" v-if="canCheck">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" :checked="states.checked"
                    @click="states.checked = !states.checked">
                <label class="p-0 flex-grow-1 m-0" for="flexSwitchCheckChecked">{{ t('checked') }}</label>
            </div>
            <div class="modal-footer">
                <button :disabled="(states.amount == 0 || states.amount == undefined || states.amount == null)" @click="add"
                    type="button" style="color: white" class="btn btn-primary add-button">
                    {{ selected_donation ? t('update_campaign') : t('create_campaign') }}
                </button>

                <button type="button" style="color: white" class="btn btn-danger" @click="close">
                    {{ t('orphan_modal_cancel_button') }}

                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
import { LanguageStore } from "@/stores/language";
import { storeToRefs } from 'pinia';
import { LoadingStore } from '@/stores/loading';
import { NotificationStore } from '@/stores/notification';
import { CampaignsDonateStore } from "@/stores/campaignsDonateStore";
import donatorSelect2 from '@/components/donatorSelect2.vue';
import DonatorSelect2 from '@/components/donatorSelect2.vue';
import { UserStore } from '@/stores/userStore';
import { OrphanEditStates } from '@/stores/orphanEditStates';

export default {
    name: 'CreateDonation',
    props: ['selected_donation'],
    components: { donatorSelect2, DonatorSelect2 },
    data() {
        return {
            canCheck: false,
            selected_donator: this.selected_donation ? { name: this.selected_donation.donator_name, last_name: this.selected_donation.donator_lastname } : undefined

        };
    },

    watch: {
        donators: {
            deep: true
        },
        selected_donation: {

            deep: true,
            immediate: true
        },

        field_states: {

            deep: true,
        },
        isDisabled: {
            deep: true,
        },
        campaigns: {
            deep: true
        }
    },
    async created() {
        this.loadingStore.setLoading(true);
        if (this.selected_donation) {
            await this.campaignsDonateStore.getCampaigns();
            await this.campaignsDonateStore.getDonators(this.states.donator_type);
            this.states.selected_donator_type = this.states.donator_type;
            this.canCheck = this.userStore.checkIfHasPermission('check.entries.update')
        }
        else {
            this.getPreData()
        }
        this.loadingStore.setLoading(false);

    },
    methods: {
        async getPreData() {
            this.campaignsDonateStore.cleanStates();
            await this.campaignsDonateStore.getCampaigns();
            await this.campaignsDonateStore.getDonators(this.states.selected_donator_type);
            if (this.donators.length > 0 && this.campaigns.length > 0) {
                this.states.donator_id = this.donators[0].id;
                this.states.campaign_id = this.campaigns[0]?.id;
            }
        },
        amountChanged() {
            if (this.states.amount < 0)
                this.states.amount = -1 * this.states.amount;
        },
        async updateSelection() {
            this.loadingStore.setLoading(true);
            await this.campaignsDonateStore.getDonators(this.states.selected_donator_type == 2);
            this.states.donator_id = undefined;
            this.selected_donator = undefined
            this.loadingStore.setLoading(false);
        },

        close() {
            this.campaignsDonateStore.cleanStates();
            this.$emit('close');
        },

        async add() {
            this.loadingStore.setLoading(true);
            if (this.selected_donation) {
                this.states.donator_type = parseInt(this.states.selected_donator_type);
                let response = await this.campaignsDonateStore.update(this.states.id, this.states);
                if (response.status) {
                    if (this.canCheck) {
                        await this.orphanEditStates.checkEntry('campaign_donations', this.states.id, this.states.checked)
                    }
                    this.$emit('status', true);
                    this.close();

                }
                // Call api
                let message = response.status ? this.t('donation_updated') : this.t('donation_not_updated');
                this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);
            }
            else {
                let type = parseInt(this.states.selected_donator_type) == 0 ? 0 : 2;

                let response = await this.campaignsDonateStore.add({
                    amount: this.states.amount,
                    campaign_id: this.states.campaign_id,
                    donator_type: type,
                    donator_id: this.states.donator_id
                });
                if (response.status) {
                    this.$emit('status', true);
                    this.close();

                }
                let message = response.status ? this.t('campaign_type_added') : this.t('campaign_type_cannot_added');
                this.notificationStore.popupMessage(message, "", response.status, response.status ? 'bottom' : "center", response.status);

            }
            // Call api
            this.loadingStore.setLoading(false);

            //todo update 
        },

        searching() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.check_is_exist, 75);
        },

        pastedetected(eventdata) {
            this.search = "";
            this.states.name = eventdata.clipboardData.getData("Text");
            this.searching();
        },

        changeDonatorId(donatorId) {
            this.states.donator_id = donatorId
        },

        async check_button() {
            this.$emit('check', this.ngo_id, this.name, this.last_name);
        },

        async check_is_exist() {
            if (this.states.name.length != 0) {
                let response = await this.campaignTypeStore.search(this.states.name, 0);
                if (response.status && response.data.data.length > 0)
                    this.field_states.is_exist = true;
                else
                    this.field_states.is_exist = false;

            }
            else {
                this.field_states.is_exist = false;
            }
        }

    },
    setup() {
        const notificationStore = NotificationStore();
        const loadingStore = LoadingStore();
        const { is_loading } = storeToRefs(loadingStore);
        const languageStore = LanguageStore();
        const orphanEditStates = OrphanEditStates();
        const userStore = UserStore();
        const campaignsDonateStore = CampaignsDonateStore();
        const { states, donators, campaigns } = storeToRefs(campaignsDonateStore);
        const { t } = useI18n({});
        return {
            t, is_loading, languageStore, donators, loadingStore, userStore,
            notificationStore, states, campaigns, campaignsDonateStore, orphanEditStates
        };
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

label {
    float: left;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto;
    padding: 0.5em 3.5em 0.5em 1em;
}
</style>