import axios from "axios";
import { defineStore } from "pinia";
import { base_url } from "./baseUrl";
import { checkResponse } from "@/utils/checkResponse";

axios.defaults.baseURL = base_url;

export const OrphanFamilyStore = defineStore("orphanFamilyStore", {
  state: () => ({
    states: {
      ngo_id: "",
      ngo_id_exist: false,
      name: "",
      location: "SY",
      phone: "",
    },
    page: {
      value: 0,
      count: 0,
    },

    families: undefined,
  }),

  getters: {},

  actions: {
    cleanStates() {
      this.states = {
        ngo_id: "",
        ngo_id_exist: false,
        name: "",
        location: "SY",
        phone: "",
      };
    },

    async userIsValid() {
      if (localStorage.getItem("ngo_access_token") == null) return false;
      return true;
    },

    async updateFamily(data) {
      const url = "api/family/" + data.id;
      return axios
        .put(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async addFamily(data) {
      const url = "api/family";
      return axios
        .post(url, data)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async deleteFamily(id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      const url = "api/family/" + id;
      return axios
        .delete(url)
        .then((response) => {
          return { status: true, data: response };
        })
        .catch((err) => {
          return { status: false, data: err.response };
        });
    },

    async search(key, page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/family/search", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
            key: key,
          },
        })
        .then((response) => {
          this.orphans = response.data;
          return { status: true, data: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          this.orphans.data = [];
          return { status: false, message: "Not Found!" };
        });
    },

    async searchFamily(ngo_id) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/family/search", {
          params: {
            key: ngo_id,
            search_column: "ngo_id",
          },
        })
        .then((response) => {
          return { status: true, data: response.data };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async getFamily(page_number, per_page, sort_column, sort_by) {
      let access = await this.checkAccess();
      if (!access.status == -1) {
        return access;
      }
      return axios
        .get("api/family", {
          params: {
            page: page_number,
            per_page: per_page,
            sort_by: sort_by,
            sort_column: sort_column,
          },
        })
        .then((response) => {
          this.families = response.data;
          return { status: true };
        })
        .catch((exception) => {
          checkResponse(exception.response.status);
          return { status: false, message: "Not Found!" };
        });
    },

    async checkAccess() {
      if (axios.defaults.headers.common["Authorization"] == null) {
        if (localStorage.getItem("ngo_access_token") == null) {
          window.location.replace("/login");
          return { status: -1, message: "Must login to continue" };
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("ngo_access_token");
      return { status: true, message: "True" };
    },
  },
});
